import React, {Fragment} from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';
import ModalTitle from '../layout/modal/ModalTitle';
import Button from '../layout/button/Button';
import PreloadContent from '../layout/preload/PreloadContent';

import NotificacaoService from '../../service/NotificacaoService';

import AtivoEnum from '../../helper/enumerador/AtivoEnum';

class ModalNotification extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            preload: true
        }
    }            

    componentDidMount() {        
        if(this.props.item.visualizado === AtivoEnum.SIM) {
            this.setState({preload: false});
            
            return ;            
        }
        
        NotificacaoService.setVisualizado(            
            this.props.item.id
        ).then(() => {
            this.props.afterLoad();
            this.setState({preload: false});
        }).catch((erro) => {
            this.props.onErro(this.trataErro(erro));
            this.setState({preload: false});
        })
    }

    showComponent() {
        if(this.state.preload)
            return ;

        return (
            <Fragment>  
                {this.props.item.body}

                {
                    this.props.item.click_action &&
                    <Modal.Footer>                                                    
                        <Button
                            type="button"                        
                            className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"  
                            onClick={() => this.props.onApply(this.state.selecionados)}                              
                        >                    
                            VER OFERTA
                        </Button>                         
                    </Modal.Footer>                    
                }
            </Fragment>
        )
    }

    render() {
        return (
            <Modal 
                show={this.props.show}
                className={this.props.className}
                style={{ zIndex: 9999, overflow: "auto" }}
                onHide={() => this.props.fechar()}
            >
                <ModalTitle
                    icon="down"
                    header={this.props.item.title}
                    fechar={() => this.props.fechar()}
                />                

                <Modal.Body >
                    <div 
                        className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12"
                        style={{
                            minHeight: '100px',
                            marginTop: '10px',                                
                            marginBottom: '10px'
                        }}
                    >
                        <PreloadContent
                            exibir={this.state.preload}
                        />

                        {this.showComponent()}
                    </div>
                </Modal.Body>                
            </Modal>            
        )
    }
}

export default ModalNotification;