import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Table from '../../layout/table/Table';

import AutenticarService from '../../../service/AutenticarService';
import EmpresaFreteService from '../../../service/EmpresaFreteService';

import TipoCalculoFreteEnum from '../../../helper/enumerador/TipoCalculoFreteEnum';
import TableTypeEnum from '../../../helper/enumerador/TableTypeEnum';

import { buscaConfiguracao } from '../../../helper/Validar';

class TaxaEntregaContent extends AbstractComponent {
    constructor(props) {
        super(props);                
    
        this.state = {                                                               
            head : [
                {nome: 'Cidade', campo: 'cidade'},
                {nome: 'Compras até', campo: 'valor_compra', type: TableTypeEnum.MOEDA},
                {nome: 'Taxa de Entrega', campo: 'valor_entrega', type: TableTypeEnum.MOEDA},
                {
                    nome: () => { 
                        return (
                            <i className="fa fa-check" aria-hidden="true"></i>
                        )
                    }, 
                    campo: 'check', 
                    type: TableTypeEnum.CHECK,                    
                }
            ],  
                                
            body: []
        }
    }        

    className(item, itemHead) {                                               
        if(item['check'] === false) 
            return '';
            
        return 'backgroud-destaque';
    }

    componentDidMount() {
        this.props.showPreload(true);                        

        EmpresaFreteService.listar(            
            AutenticarService.getIdCarrinho()           
        ).then(response => {              
            this.setState({body: response.data});             

            this.props.showPreload(false);
        }).catch(erro => {            
            this.props.showErro(this.trataErro(erro));
            this.props.showPreload(false);
        });
    }

    componentDidUpdate() {  
        if(this.props.empresa.configuracao.length === 0)
            return ;                        

        let tipoFrete = TipoCalculoFreteEnum.get(buscaConfiguracao(this.props.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade'));
            
        let head = this.state.head;            

        head = [
            {nome: tipoFrete.name, campo: tipoFrete.field},
            {nome: 'Compras a partir de', campo: 'valor_compra', type: TableTypeEnum.MOEDA},
            {nome: 'Taxa de Entrega', campo: 'valor_entrega', type: TableTypeEnum.MOEDA},
            {
                nome: () => { 
                    return (
                        <i className="fa fa-check" aria-hidden="true"></i>
                    )
                }, 
                campo: 'check', 
                type: TableTypeEnum.CHECK,                    
            }
        ];

        this.state.head = head;
    }
    
    render() {
        return (
            <Fragment>
                <Table                    
                    ref={ref => this.table = ref}
                    head={this.state.head}
                    body={this.state.body}                    
                    cadastro={false}   
                    onClassName={(item, itemHead) => this.className(item, itemHead)}
                />                                      
            </Fragment>
        )
    }
}

export default TaxaEntregaContent;