import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import ModalAlerta from '../modal/ModalAlerta';
import Preload from '../preload/Preload';
import InputSelect from '../input/InputSelect';

import EmpresaFreteService from '../../../service/EmpresaFreteService';

class InputSelectPontoInteresse extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            listas: [],

            erro: '',

            preload: false
        }

        this.id = this.props.id || this.props.nome;
    }        

    onChangeSelect(input) {
        if(this.props.onChange !== undefined) {
            this.props.onChange(input);
        }
    }    

    onBlur(blur) {
        if (this.props.onBlur !== undefined) {
            this.props.onBlur(blur);
        }
    }        

    buscaPontoInteresse() {        
        this.setState({preload: true});
        
        EmpresaFreteService.listarPontoDeInteresse(            
            this.props.tipoEndereco
        ).then(resposta => {
            const lista = [{valor: 0, texto: 'Selecione'}];

            resposta.data.map(item => {
                lista.push({
                    valor: item.bairro, 
                    texto: item.bairro, 
                    selected: (item.bairro === this.props.editarId)
                });

                return item;
            });

            this.setState({
                listas: lista,
                preload: false
            });
        }).catch(erro => {
            this.setState({
                erro: this.trataErro(erro),
                preload: false
            });
        })
    }

    componentDidMount() {            
        this.buscaPontoInteresse();
    }

    componentDidUpdate(prevProps) {        
        if(prevProps.tipoEndereco !== this.props.tipoEndereco)         
            this.buscaPontoInteresse();
    }

    render() {
        return (
            <Fragment>
                <Preload exibir={this.state.preload} />

                <ModalAlerta
                    show={this.state.erro !== ''}
                    texto={this.state.erro}

                    fechar={() => this.setState({erro: ''})}
                />

                <InputSelect 
                    md={this.props.md} 
                    xs={this.props.xs} 
                    sm={this.props.sm} 
                    lg={this.props.lg} 
                    classNameInput={this.props.classNameInput}                        
                    nome={this.props.nome}                                                         
                    id={this.props.nome} 

                    options={this.state.listas}                            
                    value={this.props.value}                             
                    defaultValue={this.props.defaultValue}
                    erro={this.props.erro}

                    onBlur={blur => this.onBlur(blur)}
                    onChange={e => this.onChangeSelect(e)}
                    onKeyPress={e => (this.props.onKeyPress !== undefined) ? this.props.onKeyPress(e) : ''}
                    disabled={this.props.disabled}
                >                            
                    {this.props.children}
                </InputSelect>
            </Fragment>            
        )
    }
}

export default InputSelectPontoInteresse;