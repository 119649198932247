import React from 'react';
import AbstractComponent from '../../AbstractComponent';

class Title extends AbstractComponent {
    render() {
        return (
            <header id="menuHeader" className="nav-header nav-header--tertiary nav-header--with-padding">
                <button 
                    type="button" 
                    className="btn-icon btn-icon--primary 
                                btn-icon--size-m 
                                btn-icon--transparent 
                                nav-header__left-button"
                    onClick={() => this.props.fechar()}>
                    
                    <span className="icon-arrow">
                        <i className="header-icon-font fa fa-arrow-down" style={{fontSize: '16px'}}/>                                    
                    </span>
                </button>
                
                <div className="mobile __title nav-header__title">
                    <h1>{this.props.title}</h1>
                </div>
            </header>
        );
    }
}

export default Title;