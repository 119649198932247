import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import MyModal from './MyModal';

class ModalSucesso extends AbstractComponent {    
    header() {                
        return (this.props.header === undefined) ? "Sucesso" : this.props.header;
    }    
    
    fechar() {
        if(this.props.fechar !== undefined) {
            this.props.fechar();
        }
    } 

    render() {
        return (
            <MyModal styleHeader="bg-success"
                     styleIcon="fa fa-info-circle"
                     styleText="text-success"
                     className="modal_alert "                     
                     classNameButton="btnSuccess"
                     styleButton="btn btn-success"                     
                     show={this.props.show}                      
                     header={this.header()}
                     texto={this.props.texto}
                     subTexto={this.props.subTexto}                     
                     buttonTxt="Ok" 
                     fechar={e => this.fechar()} />            
        )
    }
}

export default ModalSucesso;