import React from "react";
import AbstractComponent from "../../AbstractComponent";
import Config from "../../../Config";
import { Tabs, Tab, Table } from "react-bootstrap";
import axios from "axios";
import CupomService from "../../../service/CupomService";
import config from "../../../Config";

import "./Cupons.css";
import CupomUnitario from "./CupomUnitario";
import ResumoDiario from "./ResumoDiario";

class Cupons extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      cupom: [],
      resumo: [],
      saldo: [],
      resgate: [],
      totalcuponsgerados: [],
      cuponsPreload: true,
    };
  }

  ValidaSaldo(saldo) {
    if (isNaN(parseFloat(saldo))) {
      return "R$0,00";
    } else {
      return parseFloat(saldo).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    }
  }

  componentDidMount() {
    CupomService.listar(localStorage.getItem("documentoCliente")).then(
      (response) => {
        let cupom = this.state.cupom;
        let resumo = this.state.resumo;
        let saldo = this.state.saldo;
        let resgate = this.state.resgate;
        let totalcuponsgerados = this.state.totalcuponsgerados;
        cupom = response.data.cupom;
        resumo = response.data.resumo;
        saldo = response.data.saldo;
        resgate = response.data.resgate;
        totalcuponsgerados = response.data.totalcuponsgerados;
        this.setState({ cuponsPreload: false });
        this.setState({ cupom: cupom });
        this.setState({ resumo: resumo });
        this.setState({ saldo: saldo });
        this.setState({ resgate: resgate });
        this.setState({ totalcuponsgerados: totalcuponsgerados });
      }
    );
  }

  render() {
    return (
      <div>
        {this.state.cuponsPreload ? 
        <>
          <div style={{ padding: "230px 15px", textAlign: "justify" }}>
            <div
              className="col-md-12
                            col-sm-12
                            col-lg-12
                            col-xs-12
                            text-center
                            align-middle"
            >
              <img
                src={config.urlImgBase + "preload_botao.gif"}
                alt="Loading..."
                width="35px"
                height="35px"
              />
            </div>
          </div>
          </>
        :
        <>
        <div
          className="cupons"
          style={{ padding: "0px 15px", textAlign: "justify" }}
        ></div>
        <div className="containersaldos">
          {this.state.saldo.map((item, i) => (
            <div className="saldo" key={i}>
              <h1 className="Saldodisponivel">
                Saldo disponível: {"  "}
                <b className="saldoTotal">
                  {this.ValidaSaldo(item.saldo_total)}
                </b>
              </h1>
            </div>
          ))}

          {this.state.totalcuponsgerados.map((item, i) => (
            <div className="gerados" key={i}>
              <h1 className="">
                Total de cashback acumulado: &ensp;
                <b className="geradoTotal">{this.ValidaSaldo(item.total)}</b>
              </h1>
            </div>
          ))}
        </div>
   
        {this.state.cupom.length !== 0 &&
            <Tabs
          defaultActiveKey="cupom"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="cupom" title="Cupons" className="listaCupom">
            <div>
              {this.state.cupom == 0 && <h1></h1>}
              <Table striped bordered hover>
                <tbody>
                  <CupomUnitario
                    cupom={this.state.cupom}
                    resgate={this.state.resgate}
                  />
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab
            eventKey="resumo"
            title="Resumo diario"
            style={{ marginTop: "1.5vw" }}
          >
            <div>
              <Table striped bordered hover>
                <thead className="tituloTabela">
                  <tr>
                    <th>
                      <h3>Data</h3>
                    </th>
                    <th>
                      <h3>total</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ResumoDiario resumo={this.state.resumo} />
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
        }
      </>
      }
      </div>
    );
  }
}

export default Cupons;
