import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import PedidoDetalheModal from './PedidoDetalheModal';
import PedidoEmpty from './PedidoEmpty';
import Button from '../layout/button/Button';

import AutenticarService from '../../service/AutenticarService';
import PedidoService from '../../service/PedidoService';

import AlignEnum from '../../helper/enumerador/AlignEnum';
import StatusPedidoEnum from '../../helper/enumerador/StatusPedidoEnum';
import TableTypeEnum from '../../helper/enumerador/TableTypeEnum';

import { formatarDia, formatarMes, moeda } from '../../helper/Formatar';

class PedidoContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,

            listaPedido: [],

            pedido: {
                pedido_item: [],
                endereco_entrega: {},
                totalizador: {}
            },

            head : [
                {nome: 'Qtd', campo: 'quantidade', type: TableTypeEnum.NUMBER, scale: 3, isInteger: true, align: AlignEnum.RIGHT, width: '16%'},
                {nome: 'UN', campo: 'unidade', type: TableTypeEnum.TEXT, width: '8%'},
                {nome: 'Descrição', campo: 'descricao', type: TableTypeEnum.TEXT, width: '52%'},
                {nome: 'Total', campo: 'total', type: TableTypeEnum.MOEDA, align: AlignEnum.RIGHT, width: '24%'}
            ],  
                                
            body: []
        }
    }

    componentDidMount() {
        this.props.showPreload(true);

        PedidoService.getPedidosByCpf(            
            AutenticarService.getDocumentoCliente()
        ).then(response => {
            this.setState({ 
                listaPedido: response.data
            });
            this.props.showPreload(false);
        }).catch(erro => {
            this.props.showPreload(false);
            this.props.showErro(this.trataErro(erro));
        })
    }     

    showComponent() {        
        if(this.state.listaPedido.length === 0)            
            return (
                <PedidoEmpty />
            );            

        return (     
            <Fragment>
                <div className="row lista-main"> 
                    {
                        this.state.listaPedido.map((pedido, i) => {
                        return (
                            <div 
                                key={i}
                                className="col-md-4 
                                           col-sm-4 
                                           col-lg-4 
                                           col-xs-12
                                           lista-container"
                            >
                                <div 
                                    className="col-md-3 
                                               col-sm-3 
                                               col-lg-3 
                                               col-xs-3"
                                    style={{
                                        padding: '2px',
                                        height: '100%'
                                    }}
                                >
                                    <div className="data-pedido"
                                        style={{ height: "100%", width: "100%" }} >
                                        <label className="h3 text-dark">{formatarDia(pedido.data)}</label>
                                        <label className="h4">{formatarMes(pedido.data)}</label>
                                    </div>
                                </div>
                                
                                <div 
                                    className="col-md-9 
                                               col-sm-9 
                                               col-lg-9 
                                               col-xs-9"                                    
                                >
                                    <p className="h4 text-body">
                                        <b>Pedido:</b> 
                                        &nbsp;
                                        #{pedido.id}
            
                                        {/* <Button
                                            height="10%"
                                            width="37%"
                                            justifyContent='center'                                                                                        
                                            className={"btnStatus " + StatusPedidoEnum.get(pedido.status).className}
                                        >
                                            {StatusPedidoEnum.get(pedido.status).text}
                                        </Button> */}

                                    </p>
                                    
                                    <p className="h4 text-body">
                                        <b>Total:</b> 
                                        &nbsp;
                                        {moeda(pedido.totalizador.total)}
                                    </p>

                                    <p className="h5 text-body">
                                        Destinatário: 
                                        &nbsp;
                                        {pedido.destinatario}
                                    </p>
                                    
                                    <div className="pb-3">
                                        <Button
                                            type="button"
                                            className="btn"
                                            width={'98%'}
                                            color={true}
                                            onClick={() => this.setState({ modal: true, pedido: pedido, body: pedido.pedido_item })}
                                            justifyContent='center'>
                                            Detalhes
                                        </Button>
                                    </div>                                    
                                </div>                                
                            </div>
                        )
                    })} 

                    <div style={{height: '49px'}}>

                    </div>
                </div>
            </Fragment>
        );
    }
    
    render() {
        return (
            <Fragment>
                <PedidoDetalheModal
                    show={this.state.modal}                    
                    pedido={this.state.pedido} 
                    head={this.state.head}
                    body={this.state.body}

                    fechar={() => this.setState({ modal: false })}
                />                

                { this.showComponent() }
            </Fragment>            
        )
    }
}

export default PedidoContent;