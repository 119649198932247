import React from "react";
import AbstractComponent from "../AbstractComponent";
import Form from "../layout/form/Form";
import Button from "../layout/button/Button";
import DadosPessoais from "../cliente/content/DadosPessoaisContent";
import PerfilMenu from "./PerfilMenu";
import Config from "../../Config";

import AutenticarService from "../../service/AutenticarService";
import ClienteService from "../../service/ClienteService";

import TipoRedeSocialEnum from "../../helper/enumerador/TipoRedeSocialEnum";

import { formatarData } from "../../helper/Formatar";
import Swal from "sweetalert2";
import UsuarioService from "../../service/UsuarioService";
// import withReactContent from 'sweetalert2-react-content';
class PerfilContent extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      onLoad: true,

      form: {
        id: 0,
        cpf: "",
        nome: "",
        telefone: "",
        dataNascimento: "",
        foto_perfil: "",
        id_facebook: "",
        foto_facebook: "",
        id_google: "",
        foto_google: "",
        id_apple: "",
        foto_apple: "",
        foto: "",
      },

      erroForm: {
        id: 0,
        cpf: "",
        nome: "",
        telefone: "",
        dataNascimento: "",
      },

      btnSalvarDisabled: true,
    };
  }

  onChange(e) {
    const form = this.state.form;
    form[e.target.name] = e.target.value;
    this.setState({ form: form, btnSalvarDisabled: false });
  }

  onErroData(e) {
    let erro = this.state.erroForm;

    erro.dataNascimento = e;

    this.setState({ erroForm: erro });
  }

  onSubmit(e) {
    var form = new FormData(document.getElementById("form"));

    this.setState({ preload: true });

    ClienteService.gravarDadosPessoais(form)
      .then(() => {
        this.props.showSucesso("Dados atualizado com sucesso");

        this.setState({ preload: false, btnSalvarDisabled: true });
      })
      .catch((error) => {
        this.setState({ preload: false });

        this.props.showErro(this.trataErro(error));
      });
  }

  showSucessoFacebook(idFacebook, fotoFacebook, sucesso) {
    let form = this.state.form;

    form.id_facebook = idFacebook;
    form.foto_facebook = fotoFacebook;
    form.foto = fotoFacebook;

    this.setState({ form: form });
    this.props.showSucesso(sucesso);
  }

  showSucessoGoogle(idGoogle, fotoGoogle, sucesso) {
    let form = this.state.form;

    form.id_google = idGoogle;
    form.foto_google = fotoGoogle;
    form.foto = fotoGoogle;

    this.setState({ form: form });
    this.props.showSucesso(sucesso);
  }

  showSucessoApple(idApple, fotoApple, sucesso) {
    let form = this.state.form;

    form.id_apple = idApple;
    form.foto_apple = fotoApple;
    form.foto = fotoApple;

    this.setState({ form: form });
    this.props.showSucesso(sucesso);
  }

  showSucessoCredentials(sucesso) {
    let form = this.state.form;

    form.foto_perfil = "";
    form.foto = undefined;

    var el = document.getElementById("preview");

    if (el !== null) el.src = Config.urlImgBase + "login.png";

    document.getElementById("imagem").value = "";

    this.setState({ form: form });
    this.props.showSucesso(sucesso);
  }

  excluirConta(idUsuario) {
    Swal.fire({
      title: "Tem certeza que deseja excluir sua conta?",
      text: "Todos os seus dados serão deletados permanentemente!",
      showCancelButton: true,
      icon: "error",
      confirmButtonColor: "#000000",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Deletar!",
      cancelButtonText: "Cancelar",
      width: "80%",
      customClass: "swal-wide",
    }).then((result) => {
      if (result.isConfirmed) {
        UsuarioService.deletarUsuario(idUsuario)
          .then((response) => {
            Swal.fire({
              title: "Conta deletada com sucesso!",
              icon: "success",
              timer: 2500,
              showConfirmButton: false,
              width: "80%",
              customClass: "swal-wide",
            });
            this.logout();
          })
          .catch(() => {
            Swal.fire({
              title: "Ocorreu um erro durante a deleção da conta!",
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
              width: "80%",
              customClass: "swal-wide",
            });
          });
      }
    });
  }

  componentDidMount() {
    this.props.showPreload(true);

    ClienteService.getByCpf(AutenticarService.getDocumentoCliente())
      .then((response) => {
        let form = this.state.form;

        form.id = response.data.id;
        form.cpf = response.data.cpf;
        form.nome = response.data.nome;
        form.dataNascimento = formatarData(response.data.data_nascimento);
        form.telefone = response.data.telefone;
        form.foto_perfil = response.data.foto_perfil;
        form.id_facebook = response.data.id_facebook;
        form.foto_facebook = response.data.foto_facebook;
        form.id_google = response.data.id_google;
        form.foto_google = response.data.foto_google;
        form.id_apple = response.data.id_apple;
        form.foto_apple = response.data.foto_apple;

        const tipoLogin = AutenticarService.getTipoLogin();
        let foto = "";

        if (tipoLogin === TipoRedeSocialEnum.FACEBOOK.enumName) {
          foto = response.data.foto_facebook;
        } else if (tipoLogin === TipoRedeSocialEnum.GOOGLE.enumName) {
          foto = response.data.foto_google;
        } else if (tipoLogin === TipoRedeSocialEnum.APPLE.enumName) {
          foto = response.data.foto_apple;
        } else {
          if (response.data.foto_perfil !== "") {
            foto = Config.urlImgCliente + response.data.foto_perfil;
          }
        }

        form.foto = foto;

        this.setState({
          form: form,
          onLoad: false,
          btnSalvarDisabled: true,
        });

        this.props.showPreload(false);

      })
      .catch((erro) => {
        this.setState({ preload: false, onLoad: false });
        this.props.showErro(this.trataErro(erro));
        this.props.showPreload(false);
      });
  }

  logout() {
    UsuarioService.logout()
      .then(() => {
        AutenticarService.logout();

        this.setState({ preload: false });

        this.homePage2();
      })
      .catch(() => {
        AutenticarService.logout();

        this.setState({ preload: false });

        this.homePage2();
      });
  }

  render() {
    return (
      <>
        <Form id="form" onSubmit={(e) => this.onSubmit(e)} className="form">
          <input type="hidden" name="id" value={this.state.form.id} />
          <input type="hidden" name="nome" value={this.state.form.nome} />
          <input type="hidden" name="cpf" value={this.state.form.cpf} />
          <input
            type="hidden"
            name="data_nascimento"
            value={this.state.form.dataNascimento}
          />
          <input
            type="hidden"
            name="telefone"
            value={this.state.form.telefone}
          />

          {!this.state.onLoad && (
            <PerfilMenu
              form={this.state.form}
              empresa={this.props.empresa}
              onLoad={() => this.setState({ btnSalvarDisabled: false })}
              showErro={(erro) => this.props.showErro(erro)}
              showSucessoFacebook={(idFacebook, fotoFacebook, sucesso) =>
                this.showSucessoFacebook(idFacebook, fotoFacebook, sucesso)
              }
              showSucessoGoogle={(idGoogle, fotoGoogle, sucesso) =>
                this.showSucessoGoogle(idGoogle, fotoGoogle, sucesso)
              }
              showSucessoApple={(idApple, fotoApple, sucesso) =>
                this.showSucessoApple(idApple, fotoApple, sucesso)
              }
              showSucessoCredentials={(sucesso) =>
                this.showSucessoCredentials(sucesso)
              }
            />
          )}

          <DadosPessoais
            form={this.state.form}
            erroForm={this.state.erroForm}
            onChange={(e) => this.onChange(e)}
            onErroData={(e) => this.onErroData(e)}
          />

          <div className="row">
            <div
              className="col-md-12
                                col-sm-12
                                col-lg-12
                                col-xs-12"
            >
              <Button
                type="submit"
                background={1}
                color={true}
                justifyContent="center"
                disabled={this.state.preload || this.state.btnSalvarDisabled}
                preload={this.state.preload}
                className="product-action__counter__button"
              >
                SALVAR
              </Button>
            </div>

            {/* <div
              className="col-md-12
                                col-sm-12
                                col-lg-12
                                col-xs-12"
            >
              <Button
                type="button"
                onClick={() => this.excluirConta(this.state.form.id)}
                background={1}
                color={true}
                justifyContent="center"
                preload={this.state.preload}
                className="excludeButton mt-3"
              >
                EXCLUIR CONTA
              </Button>
            </div> */}
          </div>
        </Form>
      </>
    );
  }
}

export default PerfilContent;
