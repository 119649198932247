
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class EmpresaService {
    static async validaEmpresa() {
        return Requisicao.get(Config.urlBaseApi + 'empresa/validaEmpresa');
    }       
}

export default EmpresaService;