import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../../AbstractComponent';
import Config from '../../../../Config';

class LogoTipo extends AbstractComponent {
    render() {
        return(
            <Col
                md={this.props.md}
                xs={this.props.xs}
                sm={this.props.sm}
                lg={this.props.lg}
                onClick={() => this.props.route(this.homePage())}
                style={{
                     cursor: 'pointer'
                }}
            >
                <a
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={() => this.props.route(this.homePage2())}
                >
                    <img
                        className="logoTipo-desktop"
                        src={Config.urlImgBase + "logo/logo.png"}
                        alt="Logo Tipo"
                        onClick={() => this.props.route(this.homePage2())}
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </a>
            </Col>
        )
    }
}

LogoTipo.defaultProps = {
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12
}

export default LogoTipo;