import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

class ButtonEscolher extends AbstractComponent {
    showPreload() {
        if(!this.props.showPreload)
            return ;

        return (                            
            <div className="col-md-12                                 
                            col-sm-12                                                
                            col-lg-12                                                
                            col-xs-12
                            text-center">
                <img src={Config.urlImgBase + "/preload_botao.gif"} style={{width: '35px', height: '35px', marginTop: '-8px'}} alt="Loading..."/> <br/>
            </div>            
        );
    }

    showSubTitle() {
        if(!this.props.showSubtitulo)
            return ;
        
        let subtitulo = this.props.subtitulo;

        if(Array.isArray(this.props.subtitulo)) {
            subtitulo = '';

            this.props.subtitulo.map((item) => {
                subtitulo += (subtitulo === '' ? '' : ' / ') + item;
            });            
        }

        return (                                                                                                                                                         
            <div 
                className="col-md-12                                 
                        col-sm-12                                                
                        col-lg-12                                                
                        col-xs-12
                        text-subtitulo" 
            >
                {subtitulo}
            </div>              
        )
    }

    showButton() {
        if(!this.props.showButton)
            return ;

        return (              
            <Fragment>
                
                <div className="col-md-8                                 
                                col-sm-8                                                
                                col-lg-8                                                
                                col-xs-8">
                    <span>
                        {
                            this.props.imagem &&                        
                            <img 
                                id={this.props.id}                    
                                src={Config.urlImgBase + this.props.imagem}
                                alt="Pagamento na Entrega"                    
                            />
                        }   
                    
                        { this.props.texto }
                    </span>
                </div>

                <div className="col-md-4                                 
                                col-sm-4                                                
                                col-lg-4                                                
                                col-xs-4
                                text-right">
                    <span className={"opcao-entrega-info__action " + this.props.classNameEscolher}>
                        {this.props.textoSelecionar}
                    </span>
                </div>            
            </Fragment>
        );
    }

    render() {
        return (            
            <button 
                type={this.props.type}
                className={"button-escolher " + this.props.className}
                onClick={() => this.props.onClick()}                     
            >              
                { this.showButton() }

                { this.showSubTitle() }                

                { this.showPreload() }                
            </button>            
        );
    }
}

ButtonEscolher.defaultProps = {
    type: "button",
    className: '',
    showButton: true,
    showSubtitulo: false,    
    showPreload: false,
    classNameEscolher: ''
}

export default ButtonEscolher;