import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import Preload from '../layout/preload/Preload';
import Hamburger from '../layout/hamburger/Hamburger';
import ImagemChoosen from '../layout/image/ImageChoosen';
import ModalQuestion from '../layout/modal/ModalQuestion';
import Config from '../../Config';

import PerfilMenuEnum from '../../helper/enumerador/PerfilMenuEnum';
import TipoRedeSocialEnum from '../../helper/enumerador/TipoRedeSocialEnum';

class PerfilMenu extends  AbstractComponent {      
    constructor(props) {
        super(props);

        this.state = {
            preload: false,

            showComponent: false,

            modalQuestion: false,

            menu: [],
            
            item: undefined,

            index: -1,
            
            apple: false,
        }        
    }        
    
    
    macScanner() {
        if (navigator.userAgent.indexOf("Mac") != -1) {
            this.setState({apple: true});
        }
        else if (navigator.userAgent.indexOf("like Mac") != -1) {
            this.setState({apple: true});
        } else if (
          (navigator.userAgent.indexOf("Opera") ||
            navigator.userAgent.indexOf("OPR")) != -1
        ) {
        } else if (navigator.userAgent.indexOf("Edg") != -1) {
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            this.setState({apple: true});
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
        } else if (
          navigator.userAgent.indexOf("MSIE") != -1 ||
          !!document.documentMode == true
        ) {
        } else {
        }
      }

    showSucesso(idRedeSocial, fotoRedeSocial, tipo) {        
        let menus = this.state.menu;
        let menu = menus[this.state.index];        

        this.removeComponent(menu.nome);
        
        menu.visible = false;

        if(menu.descricao.indexOf('Vincular') > -1) {               
            menu.descricao = menu.descricao.replace('Vincular', 'Desvincular');            
            menu.visible = true;
        } else if(menu.descricao.indexOf('Desvincular') > -1) {
            menu.descricao = menu.descricao.replace('Desvincular', 'Vincular');            
            menu.visible = true;
        }          

        menus.splice(this.state.index, 1, menu);
        
        this.setState({menus: menus});        

        if(tipo.toUpperCase() === TipoRedeSocialEnum.FACEBOOK.enumName) {
            this.props.showSucessoFacebook(idRedeSocial, fotoRedeSocial, 'Conta do Facebook vinculada com sucesso');

            return ;
        }        

        if(tipo.toUpperCase() === TipoRedeSocialEnum.GOOGLE.enumName) {
            this.props.showSucessoGoogle(idRedeSocial, fotoRedeSocial, 'Conta do Google vinculada com sucesso');

            return ;
        }             

        if(tipo.toUpperCase() === TipoRedeSocialEnum.APPLE.enumName) {
            this.props.showSucessoApple(idRedeSocial, fotoRedeSocial, 'Conta da Apple vinculada com sucesso');

            return ;
        }             

        this.props.showSucessoCredentials('Foto desvinculada com sucesso');
    }        

    showPerfilMenu() {                
        if(!this.state.showComponent)
            return ;
        
        let menus = this.state.menu;
        let menu = menus[this.state.index];

        if(menu.descricao.indexOf('Vincular') > -1) {
            return (
                <Fragment>
                    { 
                        PerfilMenuEnum.get(this.state.item.nome).component(
                            this,
                            this.props.form.cpf                        
                        ) 
                    }
                </Fragment>
            )
        }            
                
        this.state.modalQuestion = true;        
        this.state.showComponent  = false;     
        this.forceUpdate();           
    }    

    onLoad() {
        let menus = this.state.menu;
        let menu = menus[2];                
        
        menu.visible = true;        
        
        menus.splice(this.state.index, 1, menu);
        
        this.setState({
            menus: menus, 
            item: menu, 
            index: 2
        });

        this.props.onLoad();
    }

    desvincular() {
        this.setState({
            preload: true, 
            modalQuestion: false
        });

        PerfilMenuEnum.get(this.state.item.nome).desvincular(
            this.props.form.cpf
        ).then(() => {            
            this.setState({preload: false});
            this.showSucesso(0, '', this.state.item.nome);                      
        }).catch((erro) => {
            this.props.showErro(this.trataErro(erro));
            this.setState({preload: false});
        })
    }    

    componentDidMount() {
        var menu = this.state.menu;              
        
        menu.push(this.addMenu('', (!this.props.form.id_facebook ? 'Vincular' : 'Desvincular') +  ' Facebook', 'facebook', '', ''));
        menu.push(this.addMenu('', (!this.props.form.id_google ? 'Vincular' : 'Desvincular') +  ' Google', 'google', '', ''));
        menu.push(this.addMenu('', (!this.props.form.id_apple ? 'Vincular' : 'Desvincular') +  ' Apple', 'apple', '', ''));
        menu.push(this.addMenu('', 'Remover foto', 'foto', '', '', this.props.form.foto_perfil !== undefined));

        this.setState({menu: menu});
        this.macScanner();        
    }

    render () {
        return (
            <div className="col-md-12
            col-xs-12
            col-sm-12
            col-lg-12" 
            style={{height: '152px', padding: '0px', marginTop: '10px'}}
            >                
                <Preload exibir={this.state.preload} />                                

                <ModalQuestion
                    show={this.state.modalQuestion}
                    texto="Tem certeza de que deseja desvincular?"
                    btnSimClick={() => this.desvincular()}
                    btnNaoClick={() => this.setState({modalQuestion: false})}
                    fechar={() => this.setState({modalQuestion: false})}
                />

                {this.showPerfilMenu() }

                <div 
                    className="col-md-2
                               col-xs-2
                               col-sm-2
                               col-lg-2"
                    style={{height: '100%'}}
                >
                </div>

                <ImagemChoosen
                    md={8} 
                    xs={8} 
                    sm={8} 
                    lg={8}
                    width={'9em'}
                    heightImage={'9em'}
                    height={'9em'}
                    borderRadius={'70px'}                    
                    semImagem={Config.urlImgBase + "login.png"}
                    imagem={this.props.form.foto}
                    canLoad={((!this.props.form.id_facebook)&&(!this.props.form.id_google)&&(!this.props.form.id_apple))}

                    onLoad={() => this.onLoad()}
                />

                {this.state.apple == false &&

                    <Hamburger
                    md={2} 
                    xs={2} 
                    sm={2} 
                    lg={2}
                    width={25}
                    menu={this.state.menu}
                    
                    onClick={(item, i) => this.setState({showComponent: true, item: item, index: i})}
                    />
                }

            </div>
        )
    }
}

export default PerfilMenu;