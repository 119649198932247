import React, { Fragment } from 'react';
import AbstractComponent from '../../../AbstractComponent';
import { moeda } from '../../../../helper/Formatar';
import Config from '../../../../Config';

import './Oferta.css';

class Oferta extends AbstractComponent {
    getValor(item) {
        if (item.promocao > 0.00)
            return (
                <div>
                    <div className="text-desconto">
                        De: {moeda(item.venda)}
                    </div>

                    <div className="text-preco">
                        POR: {moeda(item.promocao)}
                    </div>
                </div>
            )

        return (
            <div className="text-preco">
                {moeda(item.venda)}
            </div>
        );
    }

    imprimirPorcentagem(value) {
        const desconto = value.toString();

        if (desconto.length < 2)
            return (
                <span style={{ marginLeft: '5px' }}>
                    {'-' + desconto + '%'}
                </span>
            );

        return (
            <span>
                {'-' + desconto + '%'}
            </span>
        );
    }

    getPrecoApp(item) {
        if (item.valorFolheto === 0.00)
            return;

        return (
            <div
                className="promocaoApp"
                style={{
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    marginBottom: '-5px',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                    paddingLeft: '10px'
                }}
            >
                <div className="media-left media-middle">
                    <div className="circulo">
                        <div
                            className="circulo-pequeno"
                            style={{
                                padding: '12px 5px 12px 5px'
                            }}>

                            {this.imprimirPorcentagem(this.props.calculaDesconto(item))}
                        </div>
                    </div>
                </div>

                <div
                    className="media-body"
                    style={{
                        marginLeft: '10px'
                    }}>
                    <div
                        style={{
                            color: '#115893',
                            fontWeight: 'bold',
                            fontSize: '18px'
                        }}
                    >
                        ATIVE E PAGUE {moeda(item.valorFolheto)}
                    </div>

                    {/* <div
                        style={{
                            color: '#115893',
                            fontSize: '10px'
                        }}
                    >
                        PREÇO KG
                    </div> */}
                </div>
            </div>
        );
    }

    render() {
        return (
            <Fragment>
                <div
                    className="media-left media-middle"
                    style={{
                        padding: '5px'
                    }}
                >
                    <img className="media-object"
                        alt="64x64"
                        src={Config.urlImgBase + "1.jpg"}
                        data-holder-rendered="true"
                        style={{ width: '64px', height: '64px' }} />
                </div>

                <div
                    className="media-body"
                    style={{
                        height: '143px'
                    }}>
                    <div className="media-heading">
                        {this.props.item.descricao} VitrineLista
                    </div>

                    {this.getValor(this.props.item)}
                </div>

                <div
                    className="media-body"
                    style={{
                        height: '143px',
                        width: '20px',
                        padding: '0px'
                    }}>
                    <div
                        className="media-heading"
                        style={{ height: '69%' }}
                    >
                        <button className="btn"
                            type="button"
                            style={{ color: '#e6e4e6', width: '30px', backgroundColor: '#ffffff' }}
                            onClick={() => this.setState({ modalOpcao: true })}>
                            <i
                                className="fas fa-ellipsis-v"
                                style={{
                                    marginLeft: '-1px'
                                }} />
                        </button>
                    </div>

                    {/* <div className="text-center">
                        <button className="btn"
                                type="button"
                                style={{ color: '#000000', width: '10px', backgroundColor: '#ffffff' }}
                                onClick={() => this.setState({modalOpcao: true})}>
                            <i
                                className="far fa-heart"
                                style={{
                                    fontSize: '22px',
                                    marginLeft: '-10px'
                                }}/>
                        </button>
                    </div> */}
                </div>

                {this.getPrecoApp(this.props.item)}
            </Fragment>
        )
    }
}

export default Oferta;