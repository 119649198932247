import React from 'react';
import {Col} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import AbstractComponent from '../../AbstractComponent';
import Table from '../table/Table';
import Config from '../../../Config';

class InfiniteScrollTable extends AbstractComponent {          
    style() {
        if(this.props.style === undefined)
            return {padding: '0px'}

        return this.props.style;
    }

    render() {
        return (
            <Col
                md={this.props.md} 
                xs={this.props.xs} 
                sm={this.props.sm} 
                lg={this.props.lg}
                style={this.style()}
                className={this.props.className}                
            >
                <InfiniteScroll
                    dataLength={this.props.body.length}
                    next={this.props.fetchMoreData}
                    hasMore={true}     
                    height={this.props.scroll}
                >
                    <Table                    
                        head={this.props.head}
                        body={this.props.body}                                

                        ref={ref => this.table = ref}

                        cadastro={this.props.cadastro}      
                        editar={this.props.editar}                        
                        buttonCallback={this.props.buttonCallback}
                        clickCallback={this.props.clickCallback}                                                                                       

                        onCallback={(item) => this.props.onCallback(item)}
                        onCheck={(item) => this.props.onCheck(item)}
                    />  

                    {
                        this.props.preloadFetch &&
                        <div className="col-md-12                                 
                                        col-sm-12                                                
                                        col-lg-12                                                
                                        col-xs-12                                
                                        text-center" >
                            <img src={Config.urlImgBase + "preload_botao.gif"} alt="Loading..." width='35px' height='35px'/> 
                        </div>
                    }
                </InfiniteScroll> 
            </Col>           
        )
    }
}

InfiniteScrollTable.defaultProps = {
    md: 12,  
    xs: 12, 
    sm: 12, 
    lg: 12,
    className: ''
};

export default InfiniteScrollTable;