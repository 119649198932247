import AbstractComponent from '../../AbstractComponent';

class AbstractFilter extends AbstractComponent {   
    getCategoria(value, propsFiltros, stateFiltros, returnInt = false) {
        var filtro = [];        

        if(propsFiltros.length > 0)
            filtro = propsFiltros;

        if(stateFiltros.length > 0)
            filtro = stateFiltros;

        if(filtro.length === 0) {
            if(returnInt)
                return 0;
                
            return '';
        }                                                       
        for(let index = 0; index < filtro.length; index++) { 
            if(filtro[index].campo.toLowerCase() === value.toLowerCase()) {                
                return filtro[index].valor;                
            }
        }   
        
        return [];
    }  

    filtroIndex(filtro, filtroType) {
        if(filtro.length === 0)
            return -1;        

        for(let index = 0; index < filtro.length; index++) {                        
            if(filtro[index].campo === filtroType)
                return index;
        }

        return -1;
    }
      
    preparaFiltro(value, filtro, filtroType) {        
        const index = this.filtroIndex(filtro, filtroType);

        if(index > -1) 
            filtro.splice(index, 1);        
                
        if(value.length === 0)
            return [];
            
        filtro.push({campo: filtroType, valor: value});
        
        return filtro;
    }    
}

export default AbstractFilter;