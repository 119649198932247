import React, { Fragment } from "react";
import AbstractComponent from "../AbstractComponent";
import OpcaoFreteModal from "./OpcaoFreteModal";
import ButtonEscolher from "../layout/button/ButtonEscolher";
import { moeda } from "../../helper/Formatar";

class OpcaoFrete extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            modalOpcaoFrete: false,
        };
    }   

    getSubtitulo() {    
        if (!!!this.props.carrinho.entrega.frete.servico) {
            return 
        }

        return `Frete ${this.props.carrinho.entrega.frete.servico} no valor de ${moeda(this.props.carrinho.totalizador.taxa_entrega)}`;
    }

    render() { 
        return (
            <Fragment>
                {
                    (this.state.modalOpcaoFrete || this.props.chooseOpcaoFrete) &&
                        <OpcaoFreteModal
                            stylle={{backggroundColor: "red"}}            
                            className={"modal-detail"}
                            show={true}      
                            fretes={this.props.fretes}      
                            onUpdateCart={(cart) => this.props.onUpdateCart(cart)}
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
                                this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)
                            }
                            fechar={() => this.setState({ modalOpcaoFrete: false })}
                        />
                }
                
                <ButtonEscolher
                    type="button"
                    showButton={true}
                    texto="Opção de Frete"
                    textoSelecionar={
                        !!!this.props.carrinho.entrega.frete.servico ? "Escolher" : "Trocar"
                    }
                    subtitulo={this.getSubtitulo()}
                    showSubtitulo={true}
                    onClick={() => this.setState({ modalOpcaoFrete: true })}
                />
            </Fragment>        
        );
    }
}

export default OpcaoFrete;
