var shoppingBagEnum = {
    CART: {
        enumName: 'CART',        
        name: function() {            
            return "Carrinho";
        },        
        description: function() {
            return "Meu carrinho";
        },
        add: function() {
            return "ao Carrinho";
        },
        shoppingBagEmpty: function() {
            return "Seu carrinho está vazio";
        },
        icon: function() {
            return "pe-7s-cart"
        }
    },
    BAG: {
        enumName: 'BAG',        
        name: function() {            
            return "Sacola";
        },
        description: function() {
            return "Minha sacola";
        },
        add: function() {
            return "a Sacola";
        },
        shoppingBagEmpty: function() {
            return "Sua sacola está vazia";
        },
        icon: function() {
            return "pe-7s-shopbag";
        }
    },
    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return shoppingBagEnum.CART;

        var array = Object.values(shoppingBagEnum);
        var shoppingBagFound = {};

        array.forEach(element => {            
            if(element.enumName === value) {
                shoppingBagFound = element;      
                return ;          
            }            
        });   
        
        return shoppingBagFound;
    }
}

export default shoppingBagEnum;