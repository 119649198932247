import React, { Fragment } from 'react';
import AbstractComponent from '../../../AbstractComponent';
import InputSearchProduto from '../../../produto/InputSearchProduto';
import Config from '../../../../Config';

class NavContentMobile extends AbstractComponent {   
    constructor(props) {
        super(props);

        this.state = {
            search: false
        }
    }

    onClick() {        
        this.setState({search: true});

        this.foco('buscaProdutoMobile');
    }       

    onChange(e) {                
        var input = document.getElementById("buscaProduto");

        if(input === undefined)
            return ;                        

        input.value = e.target.value;                
    }

    onClearItems() {        
        var input = document.getElementById("buscaProdutoMobile");

        if(input === undefined)
            return ; 

        input.value = '';        
    }

    search() {                
        var input = document.getElementById("buscaProduto");

        if(input === undefined)
            return ;            

        var input1 = document.getElementById("buscaProdutoMobile");

        if(input1 === undefined)
            return ;                        

        input.value = input1.value;

        this.props.search();
    }    

    onItemClick(value) {        
        var input = document.getElementById("buscaProduto");

        if(input === undefined)
            return ; 

        var input1 = document.getElementById("buscaProdutoMobile");

        if(input1 === undefined)
            return ; 
        
        input1.value = value;
        
        input.value = input1.value;                
    }

    render() {
        return(   
            <div className="mobile __title">
                <div className="row" style={{height: '100%'}}>         
                    {
                        !this.state.search &&                    
                        <Fragment>                                                                                              
                            <div className="col-md-10 col-xs-10 col-sm-10 col-lg-10 text-center">
                                <div className="col-md-2 col-xs-2 col-sm-2 col-lg-2 text-center">                                      
                                    <img 
                                        className="logoTipo-mobile"                                                               
                                        src={Config.urlImgBase + "logo/logo.png"}
                                        alt="Logo Tipo"                                                
                                    />                                                                    
                                </div>

                                <div className="col-md-10 col-xs-10 col-sm-10 col-lg-10 text-center">                                                        
                                    <h1>{this.props.titulo}</h1>                                   
                                </div>                            
                            </div>
                            
                            {
                                !this.props.escondeLupa &&
                                <div 
                                    className="col-md-2 col-xs-2 col-sm-2 col-lg-2"
                                    style={{
                                        padding: '15px 0px 0px 10px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => this.onClick()}
                                > 
                                    <span 
                                        className="icon-search icon-search--search search-input__icon-search"
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => this.onClick()}
                                    >
                                        <i 
                                            className="icon-search-font pe-7s-search icon-search-mobile" 
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.onClick()}
                                        />
                                    </span> 
                                </div>   
                            }
                        </Fragment>
                    }

                    {
                        this.state.search &&                        
                        <InputSearchProduto 
                            md={12} 
                            xs={12} 
                            sm={12} 
                            lg={12} 
                            className={"responsive-header__search " + (this.state.search ? '' : 'display-none')}
                            style={{
                                height: '101%',
                                borderRadius: '0'
                            }}
                            id="buscaProdutoMobile"  
                            name="buscaProdutoMobile"  
                            arrowLeft={true}
                            vitrine={this.props.vitrine}                              
                            search={() => this.search()}
                            
                            onItemClick={(value) => this.onItemClick(value)}
                            onClearItems={() => this.onClearItems()}
                            onCloseButtonClick={() => this.setState({search: false})}
                            onChange={(e) => this.onChange(e)}                        
                        />                                                                                                    
                    }

                </div>                            
            </div>         
        )
    }
}

export default NavContentMobile;