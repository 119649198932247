import React from 'react';

function show(props) {    
    const value = (props.show);                
    
    if((value)&&(props.timeOut > 0)) {
        setTimeout(() => props.onClose(), props.timeOut * 1000);
    } 

    return value;
}

function Toast(props) {            
    return (
        <>
            {
                show(props) &&
                <div 
                    className="toast show"
                    style={{
                        position: 'absolute',
                        top: 20,
                        right: 5,
                        minWidth: 350,
                        maxWidth: 350,
                        zIndex: 9999
                    }}
                >
                    <div className="toast-header">                        
                        <div 
                            className="col-md-8
                                        col-sm-8
                                        col-lg-8
                                        col-xs-8"
                            style={{padding: '0px'}}
                        >
                            <strong>
                                {props.notification.title}
                            </strong>
                        </div>

                        <div 
                            className="col-md-4
                                        col-sm-4
                                        col-lg-4
                                        col-xs-4
                                        text-right"
                            style={{padding: '0px'}}
                        >
                            <small>
                                agora mesmo
                            </small>  
                        </div>
                    </div>

                    <div className="toast-body">
                        {props.notification.body} 
                    </div>
                </div>                                                          
            }
        </>
    )    
}

export default Toast;