import React from 'react';
import AbstractComponent from '../AbstractComponent';
import ModalQuestion from '../layout/modal/ModalQuestion';
import Input from '../layout/input/Input';
import Image from '../layout/image/Image';
import Config from '../../Config';

import SendMailService from '../../service/SendMailService';

import { validarEmail } from '../../helper/Validar';
import { route } from '../../helper/Route';
import { getParam } from '../../helper/Url';

class RecuperarSenhaContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                idEmpresa: 0,
                email: '',
                documentoCliente: '',                
            },

            erroForm: {
                email: ''
            },            

            modalQuestion: false,
            
            btnName: 'ENVIAR LINK'
        }
    }    

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({ form: form });
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.exibeModal(e);
        }
    }

    limpaVariavelErroForm() {
        let erro = this.state.erroForm;

        erro.email = "";

        this.setState({ erroForm: erro });
    }    

    valida() {
        this.limpaVariavelErroForm();

        let form = this.state.form;
        let erroForm = this.state.erroForm;
        let erro = false;

        if (!form.email) {
            erroForm.email = "E-mail não informado";
            erro = true;                        
        }

        if(!validarEmail(form.email)) {
            erroForm.email = "E-mail inválido";
            erro = true;            
        }

        if(erro) {
            this.setState({ erro: erroForm });

            return false;
        }

        return true;
    }

    exibeModal() {
        if(!this.valida())
            return ;
        
        this.setState({ modalQuestion: true });
    }    

    enviarEmail() {
        this.setState({
            modalQuestion: false,
            btnName: 'AGUARDE...'
        });

        this.props.onErro('');

        SendMailService.sendMail(this.state.form).then(() => {
            this.props.onSucesso('Solicitação de recuperação de senha enviada com sucesso. Em no máximo 5 minutos você receberá um e-mail para recuperar sua senha.');

            this.setState({                
                btnName: 'ENVIAR LINK'
            });
        }).catch((erro) => {
            this.props.onErro(this.trataErro(erro));

            this.setState({                
                btnName: 'ENVIAR LINK'
            });
        });
    }          

    componentDidMount() {
        let documentoCliente = getParam('documentoCliente');

        if(!documentoCliente) {
            alert('Essa rota não pode ser acessada');

            this.homePage2();

            return ;
        }

        let form = this.state.form;
        
        form.documentoCliente = documentoCliente;

        this.setState({ form: form });
    }

    render() {
        return (            
            <div className="form">

                <ModalQuestion
                    show={this.state.modalQuestion}
                    btnSimClick={e => this.enviarEmail(e)}
                    btnNaoClick={() => { this.setState({ modalQuestion: false }); this.foco('email'); }}
                    fechar={() => { this.setState({ modalQuestion: false }); this.foco('email'); }}
                    texto={"Iremos enviar um link de recuperação da senha para o seu e-mail de cadastro. Você confirma o email " + this.state.form.email + "?"}
                />

                <div className="logo text-center" >
                    <div className="row justify-content-center align-self-center">
                        <Image
                            md={12}
                            xs={12}
                            sm={12}
                            lg={12}  
                            semImagem={Config.urlImgBase + "logo/logo.png"}                                                  
                            width='auto'
                            heightImage='6em'  
                            height='7em'
                            borderRadius="50px"   
                            semImagemBorderRadius="0px"                   
                            alt="Logo"
                        />                         
                    </div>
                </div>
                
                <div className="row">
                    <div className='col-md-12 text-center'>
                        <h1 className="h1">
                            <strong>
                                O SEU CADASTRO NÃO ESTÁ
                                <br />
                                AUTENTICADO
                            </strong>
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className='col-md-1 text-center'>
                    </div>

                    <div className='col-md-10 text-center'>
                        <h4>
                            Vamos enviar um link de autenticação 
                            no seu E-mail de cadastro.
                        </h4>
                    </div>

                    <div className='col-md-1 text-center'>
                    </div>
                </div>

                <div className="row">
                    <Input
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        id="email"
                        nome="email"
                        inputRef={e => this.email = e}
                        value={this.state.form.email}
                        erro={this.state.erroForm.email}
                        defaultFoco={true}
                        onChange={e => this.onChange(e)}
                        onKeyPress={e => this.onKeyPress(e)}>
                        E-mail
                    </Input>
                </div>

                <div className="row">
                    <div className='col-md-12 text-center'>
                        <button 
                            id="btnEnviarLink"
                            type="button"
                            className="btn btn-control btn-warning btn-lg"
                            onClick={e => this.exibeModal(e)}
                            disabled={this.state.btnName !== 'ENVIAR LINK'}
                        >
                            <span className="text-size">{this.state.btnName} </span>
                        </button>
                    </div>
                </div>

                <div className="row" style={{ marginBottom: '3px' }}>
                    <button 
                        type="button"
                        id="btnNaoLembroEmail"
                        className="btn btn-control btn-link btn-lg"
                        onClick={() => this.props.route(route('', 'Esqueci Meu Email', 'recuperar-senha/esqueci-meu-email'))}
                    >
                        <span className="text-size">Não lembro meu e-mail</span>
                    </button>
                </div>                
            </div> 
        )
    }
}

export default RecuperarSenhaContent;