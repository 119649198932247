import React from 'react';
import { getAuth, signInWithPopup, OAuthProvider, signInWithRedirect } from "firebase/auth";
import config from '../../../Config';

class ButtonApple extends React.Component {
    async login() {
        const provider = new OAuthProvider('apple.com');
        provider.setCustomParameters({            
            locale: 'pt_BR',
            clientId: config.apiClientIdApple,
            redirectURI: config.apiRedirectURIApple
        });

        provider.addScope('email');
        provider.addScope('name');

        const auth = getAuth();

        await signInWithRedirect(auth, provider).then((result) => {            
            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            //const accessToken = credential.accessToken;
            //const idToken = credential.idToken;

            this.props.onSuccess({
                email: result.user.email,
                name: result.user.displayName,
                imageUrl: result.user.photoURL,
                idToken: result.user.uid
            });                       
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The credential that was used.
            const credential = OAuthProvider.credentialFromError(error);

            // ...
        });
    }    

    componentDidMount() {
        if (this.props.autoLoad) {
            this.login();
        }
    }

    render() {
        return (                                      
            <span style={{transition: 'opacity 0.5s ease 0s'}}>
                <button 
                    type="button" 
                    className="kep-login-facebook medium" 
                    style={{
                        borderRadius: '35px',
                        width: '45px',
                        height: '45px',
                        padding: '13px 21px',
                        backgroundColor: '#000000'
                    }}
                    onClick={() => this.login()}
                >                                                                        
                    <i 
                        className="fa fa-apple" 
                        style={{
                            marginLeft: '-9px',
                            fontSize: '24px',
                            marginTop: '-3px'
                        }}
                    />
                </button>                                            
            </span>            
        )
    }
}

export default ButtonApple;
