import React from 'react';
import { Grid } from '@material-ui/core';
import AbstractComponent from '../AbstractComponent';
import CheckoutResumoDetalhe from './CheckoutResumoDetalhe';

import Config from "../../Config"

class CheckoutResume extends AbstractComponent {
    constructor(props) {
        super(props);        
    }

    showFrete() {
        if(this.props.carrinho.entrega.id === 2 && !!!this.props.carrinho.entrega.frete.servico)
            return (
                <div className="area-cart__delivery">
                    Selecione uma <strong>opção de frete</strong> para o sistema calcular o frete
                </div>
            );

        return (
            <CheckoutResumoDetalhe
                fontWeight="normal"
                descricao="Frete"
                valor={this.props.carrinho.totalizador.taxa_entrega}
            />
        )
    }

    render() {
        return (            
            <div style={{ height: '236px', padding: '5px' }}>
                <h4 style={{ marginBottom: '3rem'}}>
                    <img src={Config.urlImgBase + "pedido.svg"} alt="Pedido" style={{width: '25'}}/>
                    &nbsp;
                    &nbsp;
                    <strong>Resumo do pedido</strong>
                </h4>

                <Grid container>
                    <CheckoutResumoDetalhe
                        fontWeight="normal"
                        descricao="Total pedido"
                        valor={this.props.carrinho.totalizador.sub_total}
                    />                                

                    {this.showFrete()}

                    <CheckoutResumoDetalhe
                        borderTop="#dcdcdc solid 1px"
                        marginTop='10px'
                        fontWeight="bold"
                        descricao="Total"
                        valor={this.props.carrinho.totalizador.total}
                    />                    
                </Grid>           
            </div>
        )
    }
}

export default CheckoutResume;
