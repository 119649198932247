import React, { Fragment } from "react";
import AbstractComponent from "../../AbstractComponent";

import "./Menu.css";
import EmpresaService from "../../../service/EmpresaService";
import AutenticarService from "../../../service/AutenticarService";
import ClienteService from "../../../service/ClienteService";

class Menu extends AbstractComponent {
  criaSubmenu(menu) {
    return (
      <Fragment></Fragment>
      // <div className={this.state.active === "configuracao" ? "" : "display-none"}>
      //     <a
      //         className={"btn-navigation btn-navigation-mobile bg-white"}
      //         style={{marginBottom: '10px'}}
      //         onClick={() => this.setState({active: "principal"})}
      //     >
      //         <div
      //             className="col-md-2
      //                         col-sm-2
      //                         col-lg-2
      //                         col-xs-2
      //                         text-center"
      //             style={{
      //                 paddingLeft: '0px',
      //                 paddingRight: '0px'
      //             }}
      //         >
      //             <span className="btn-navigation__icon text-center">
      //                 <span className="icon-search icon-search--order">
      //                     <i class="fa fa-arrow-left" />
      //                 </span>
      //             </span>
      //         </div>

      //         <div
      //             className="col-md-8
      //                         col-sm-8
      //                         col-lg-8
      //                         col-xs-8
      //                         btn-navigation__label"
      //             style={{paddingLeft: '0px'}}
      //         >
      //             Voltar
      //         </div>
      //     </a>

      //     {
      //         menu.map((item, i) => {
      //         return (
      //             item.submenu !== undefined &&
      //             <div id={item.nome} key={i}>
      //                 { this.criaMenu(item.submenu, item.nome) }
      //             </div>
      //         )})
      //     }
      // </div>
    );
  }

  render() {
    return (
      <div className="menu">
        <div className="user-menu-items">
          {this.props.header}

          {this.props.menu.map((item, i) => {
            return (
              <a
                key={i}
                data-target={"#" + item.nome}
                className={"btn-navigation btn-navigation-mobile bg-white"}
                onClick={() => this.props.onClick(item)}
                style={item.visible === false ? { display: "none" } : {}}
              >
                <div
                  className="col-md-2                                 
                                    col-sm-2                                                
                                    col-lg-2                                                
                                    col-xs-2
                                    text-center"
                  style={{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <span className="btn-navigation__icon text-center">
                    <span className="icon-search icon-search--order">
                      <i className={"icon-submenu-font " + item.icone} />
                    </span>
                  </span>
                </div>

                <div
                  className="col-md-8                                 
                                            col-sm-8                                                
                                            col-lg-8                                                
                                            col-xs-8
                                            btn-navigation__label"
                  style={{ paddingLeft: "0px" }}
                >
                  {item.descricao}
                </div>

                {item.submenu !== undefined && (
                  <div
                    className="col-md-2                                 
                                                    col-sm-2                                                
                                                    col-lg-2                                                
                                                    col-xs-2
                                                    btn-navigation__label
                                                    text-right"
                  >
                    <i className="fas fa-caret-right"></i>
                  </div>
                )}
              </a>
            );
          })}

          {this.props.extraMenu}

          {this.props.footer}
        </div>
      </div>
    );
  }
}

export default Menu;
