import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Image from '../layout/image/Image';
import Form from '../layout/form/Form';
import Config from '../../Config';

class EsqueciMeuEmailContent extends AbstractComponent {
    render() {
        return (            
            <Form onSubmit={(e) => this.onSubmit(e)} id="form" className="form">
                <div className="logo text-center" >
                    <div className="row justify-content-center align-self-center">
                        <Image
                            md={12}
                            xs={12}
                            sm={12}
                            lg={12}                        
                            imagem={Config.urlImgBase + "logo/logo.png"}
                            width='6em'
                            height='7em'
                            heightImage='6em'                        
                            alt="Logo"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className='col-md-12 text-center'>
                        <h1 className="h1">
                            <strong>
                                VOCÊ NÃO LEMBRA SEU
                                <br />
                                E-MAIL?
                            </strong>
                        </h1>
                    </div>
                </div>                

                <div className="row">
                    <div className='col-md-1 text-center'>
                    </div>

                    <div className='col-md-10 text-center'>
                        <h4>
                            Dirija-se ao balcão de atendimento 
                            <br/>
                            do estabelecimento para 
                            <br/>
                            recuperar sua senha.
                        </h4>
                    </div>

                    <div className='col-md-1 text-center'>
                    </div>
                </div>                

                <div className="row" />
                <div className="row" />
                <div className="row" />
                <div className="row" />

                <div className="row">
                    <div className='col-md-12 text-center'>
                        <button id="btnVoltarOfertas"
                            type="button"
                            className="btn btn-control btn-warning btn-lg"
                            onClick={e => this.homePage2(e)}>
                            <span className="text-size">VOLTAR PARA AS OFERTAS</span>
                        </button>
                    </div>
                </div>

                <div className="row" />
                <div className="row" />
                <div className="row" />
                <div className="row" />
                <div className="row" style={{ marginBottom: '54px' }} />                
            </Form>                
        )
    }
}

export default EsqueciMeuEmailContent;