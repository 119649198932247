import AbstractComponent from '../../AbstractComponent';

class AbstractMenu extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            showUserMenu: false
        }
    }

    onClick(item) {        
        this.setState({showUserMenu: false});
        this.props.route(item);
    }   

    onShowUserMenu() {
        this.setState({showUserMenu: !this.state.showUserMenu})
    }
}

export default AbstractMenu;