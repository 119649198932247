import { Grid } from "@material-ui/core";
import AbstractComponent from "../AbstractComponent";

class CheckoutContentPainel extends AbstractComponent {

    render() {
        return (
            <Grid item 
                sm={this.props.sm}
                md={this.props.sm}
                lg={this.props.sm}
                xs={12} 
                className={this.props.className}
                style={{ 
                    textAlign: 'left', 
                    mb: 1, 
                    backgroundColor: this.props.background
                }}
            >
                {
                    this.props.titulo &&                
                        <Grid item 
                            sm={12}
                            className="checkout-content-panel-title"
                            style={{  
                                border: '1px solid #dedede',
                                padding: '0 2rem 0 2rem',
                                fontWeight: 'bold',                                
                                marginBottom: '1rem',
                                height: '5rem',                                
                                backgroundColor: '#F9F9F9',
                                alignContent: 'center'
                            }}
                        >
                            { this.props.titulo }
                        </Grid>
                }

                <Grid item 
                    sm={12}
                    className="checkout-content-panel-children"                   
                    style={{  
                        border: '1px solid #dedede',
                        padding: '0 2rem 0 2rem',
                        fontWeight: 'bold',                                                       
                    }}
                >
                    { this.props.children }
                </Grid>
            </Grid>
        );
    }
}

export default CheckoutContentPainel;
