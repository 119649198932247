import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from './Input';

import {isNumero} from '../../../helper/Validar';

class InputNumber extends AbstractComponent {   
    constructor(props) {
        super(props);

        this.state = {
            keyValid: true,
            input: ''
        }
    }           

    onChange(input) {                 
        if(! this.state.keyValid) {        
            input.target.value = this.state.input;            
            this.setState({keyValid: true});

            return ;              
        } 

        this.setState({input: input.target.value});

        if (this.props.onChange !== undefined) {
          this.props.onChange(input);
        }
    }        

    onKeyPress(input) {                                     
        this.setState({keyValid: isNumero(input)});

        if(! this.state.keyValid)             
             return ;        

        if (this.props.onKeyPress !== undefined) {
          this.props.onKeyPress(input);
        }
    }                        
    
    render() {
        return (
            <Input
                md={this.props.md}
                xs={this.props.xs}
                sm={this.props.sm}
                lg={this.props.lg}                                    
                id={this.props.id}
                inputRef={this.props.inputRef}  
                nome={this.props.nome}                                                                       
                value={this.props.value}
                defaultValue={this.props.defaultValue}
                erro={this.props.erro}
                disabled={this.props.disabled} 
                maxLength={this.props.maxLength}

                onPaste={(e) => e.preventDefault()}
                onDrop={(e) => e.preventDefault()}
                onChange={e => this.onChange(e)}                                 
                onKeyPress={e => this.onKeyPress(e)}     
                onFocus={this.props.onFocus}                                      
            >
                {this.props.children}
            </Input>             
        )
    }
}

export default InputNumber;