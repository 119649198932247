import React from 'react';
import AbstractComponent from '../AbstractComponent';
import { anoAtual, formatarCnpj } from '../../helper/Formatar';

class Rodape extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            width: 0
        }
    }

    componentDidMount() {
      const main = document.querySelector('main');      
      const sideShoppingBag = document.getElementById('sideShoppingBag');
      const mainWidth = main.offsetWidth - sideShoppingBag.offsetWidth - 12;

      this.setState({ width: mainWidth });
    }

    render() {              
        return (                       
            <div className="my-footer" style={{ width: this.state.width }}> 
                <div style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                    <p>
                      Copyright © 2020 - {anoAtual()} {process.env.REACT_APP_DOMAIN}. TODOS OS DIREITOS RESERVADOS. {this.props.empresa.nome} - CNPJ: {formatarCnpj(this.props.empresa.cnpj)}
                    </p>

                    <p>
                      A inclusão no carrinho não garante o preço e/ou a disponibilidade do produto. Caso os produtos apresentem divergências de valores, o preço válido é o exibido na tela de pagamento. Vendas sujeitas a análise e disponibilidade de estoque.              
                    </p>
                </div>             
            </div>
        )
    }
}

export default Rodape;
