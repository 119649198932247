import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Config from '../../Config';

import { getUrlBase } from '../../helper/Url';

class NaoEncontrado extends AbstractComponent {    
    erro() {
        return this.props.erro === undefined || this.props.erro === '' ? 'Página não encontrada' : this.props.erro;
    }              

    render() {
        return(            
            <div className="sistema">
                <div className="col-md-12 logo text-center">                                
                    <img src={Config.urlImgBase + "logo/logo.png"} alt="Logo" style={{width: '252px'}}/>                                
                </div>

                <div className="col-md-12 conteudo text-center">
                    <h1 className="h1 text-naoencontrado-sistema">
                        <i className="fa fa-exclamation-triangle"/> {this.erro()}
                    </h1>

                    {
                        this.props.showButton &&
                        <a className="btn btn-primary btn-voltar" href={getUrlBase()} >Voltar para a página inicial</a>
                    }
                </div>                
            </div>                                        
        )
    }
}

NaoEncontrado.defaultProps = {
    showButton: true
}

export default NaoEncontrado;