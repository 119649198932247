import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import TipoCalculoEnum from '../../../helper/enumerador/TipoCalculoEnum';

class InputSpin extends AbstractComponent {    
    constructor(props) {
        super(props);

        this.state = {
            keyValid: false,
            input: '',
            lastKey: ''            
        }                   
    }         

    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }

    onMaisClick() {    
        let value = this.props.tipoCalculoType.getValue(this.props.value);            

        value += this.props.tipoCalculoType.getValue(this.props.incrementoValue);        
                
        if(value > this.props.maxValue)
            return ;        
                    
        if(this.props.onMaisClick !== null)
            this.props.onMaisClick(value);
    }

    onMenosClick() {        
        let value = this.props.tipoCalculoType.getValue(this.props.value);
                        
        value = this.props.tipoCalculoType.prepare(value - this.props.incrementoValue);        

        if((this.props.minValue !== undefined)&&(value < this.props.minValue))
            return ;

        if(this.props.onMenosClick !== null)
            this.props.onMenosClick(value);
    }        
    
    onChange(input) {         
        if(! this.state.keyValid) {                    
            input.target.value = this.state.input;            
            this.setState({keyValid: true});
    
            return ;              
        }                    

        if(input.target.value === ',') {
            input.target.value = '0,';
            
            this.setState({input: input.target.value});

            this.props.onChange(input);  
            
            return ;
        }

        if(this.state.lastKey === ',') {
            this.setState({input: input.target.value});

            this.props.onChange(input);  
            
            return ;
        }
        
        if(!input.target.value)
            input.target.value = '0';
        
        let value = this.props.tipoCalculoType.getValue(input.target.value);        

        if(value < this.props.minValue)
            value = this.props.tipoCalculoType.show(this.props.minValue);
        else if(value > this.props.maxValue)
            value = this.props.tipoCalculoType.show(this.props.maxValue);
            
        input.target.value = value;
        
        this.setState({input: input.target.value});

        if (this.props.onChange !== undefined) 
            this.props.onChange(input);        

        if (this.props.onErro !== undefined) 
            this.props.onErro('');
    }        

    onBlur() {
        let value = this.props.value;

        if(value !== '') 
            return ;

        value = this.props.minValue;

        this.props.onMenosClick(value);
    }    

    onKeyPress(input) {                                         
        this.setState({keyValid: this.props.tipoCalculoType.isNumber(input)});
        
        if(! this.state.keyValid)             
             return ;                            

        this.setState({lastKey: input.key});

        if (this.props.onKeyPress !== undefined) 
            this.props.onKeyPress(input);        
    }
    
    showMaxValue() {        
        let maxValueStr = this.props.maxValue;

        let texto = this.props.textoComplMaxValueSingular === undefined ? '' : this.props.textoComplMaxValueSingular;

        if(maxValueStr > 1)
            texto = this.props.textoComplMaxValuePlural === undefined ? '' : this.props.textoComplMaxValuePlural;

        maxValueStr = maxValueStr + ' ' + texto;

        return maxValueStr;
    }

    style() {
        return this.props.width === undefined   
            ? {}
            : {width: this.props.width};
    }

    className() {
        return this.props.className === undefined   
            ? ''            
            : this.props.className;
    }      

    render() {
        return (
            <Col md={this.props.md}
                 sm={this.props.sm}
                 lg={this.props.lg}
                 xs={this.props.xs}
                 style={this.props.style}>
                <div 
                    className={"number-counter " + this.className() }
                    style={this.style()}
                >                    
                    <button 
                        type="button"                     
                        className="btn-icon btn-icon--primary btn-icon--size-m btn-icon--transparent number-counter__btn-decrement"
                        disabled={this.props.tipoCalculoType.getValue(this.props.value) <= this.props.tipoCalculoType.getValue(this.props.minValue)}
                        onClick={() => this.onMenosClick()}>
                        <span className="icon-number icon-number--minus-sign">
                            <i className="fas fa-minus" />
                        </span>
                    </button>
                    
                    <div className="number-counter__value" aria-live="polite">   
                                                
                            <input                                 
                                className="text-center"
                                style={{                        
                                    border: 'none',
                                    outline: 'unset',
                                    width: '63%'
                                }}
                                
                                autoComplete="off"                           
                                type="input" 
                                name={this.props.nome} 
                                id={this.props.nome} 
                                value={this.props.value}
                                //defaultValue={this.props.defaultValue}                                
                                disabled={this.props.disabled} 
                                readOnly={this.props.tipoCalculoType === TipoCalculoEnum.PESO}                                

                                onChange={input => this.onChange(input)}
                                onBlur={() => this.onBlur()} 
                                onKeyPress={(input) => this.onKeyPress(input)}
                            />                                                                        
                    </div>
                    
                    <button 
                        type="button"                     
                        className="btn-icon btn-icon--primary btn-icon--size-m btn-icon--transparent"
                        //disabled={this.props.maxValue > 0 && this.props.maxValue === this.props.value}
                        disabled={ ((this.props.maxValue === 0) || (this.props.maxValue === 0.000) || (this.props.tipoCalculoType.getValue(this.props.maxValue) === this.props.tipoCalculoType.getValue(this.props.value))) }
                        onClick={() => this.onMaisClick()}>
                        <span className="icon-number icon-number--plus-sign">
                            <i className="fas fa-plus" />
                        </span>
                    </button>                    
                </div>

                {/* <div className="text-center">
                    <span>{this.showMaxValue()}</span>
                </div>  */}

                <div className="text-center">
                    <span>{this.props.unidade}</span>
                </div> 
            </Col>
        )
    }
}

InputSpin.defaultProps = {
    minValue: 0,
    maxValue: 0,
    style: [],
    md: 12,
    sm: 12,
    xs: 12,
    lg: 12,
    unidade: ''
}

export default InputSpin;