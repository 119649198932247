import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Form from '../layout/form/Form';
import Button from '../layout/button/Button';
import Config from '../../Config';
import InputPassword from '../layout/input/InputPassword';
import Image from '../layout/image/Image';

import AutenticarService from '../../service/AutenticarService';
import ClienteService from '../../service/ClienteService';
import UsuarioService from '../../service/UsuarioService';

class SenhaContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: false,

            form: {
                id: 0,
                nome: '',
                senha: '',
                novaSenha: '',
                confirmacaoNovaSenha: ''
            },

            erroForm: {   
                senha: '',                             
                novaSenha: '',
                confirmacaoNovaSenha: ''
            },

            salvar: {
                disabled: true,
                name: 'Salvar'
            },

            sucesso: '',

            btnSalvarDisabled: true,
        }
    }

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({ form: form, btnSalvarDisabled: false });
    }

    limpaVariavelErro() {
        let erro = this.state.erroForm;

        erro.senha = '';
        erro.novaSenha = '';
        erro.confirmacaoNovaSenha = '';                    

        this.setState({ erroForm: erro });
    } 

    valida() {
        this.limpaVariavelErro();

        const form = this.state.form;
        const erroForm = this.state.erroForm;
        var exibeErro = false;        

        if(!form.senha) {            
            erroForm.senha = "Informe o campo senha";
            exibeErro = true;            
        }

        if((form.senha)&&(form.senha.length < 6)) {
            erroForm.senha = "O campo Senha Atual deve ter no mínimo 6 caracteres";
            exibeErro = true;            
        }

        if(!form.novaSenha) {            
            erroForm.novaSenha = "Informe o campo Nova Senha";
            exibeErro = true;            
        }
        
        if((form.novaSenha)&&(form.novaSenha.length < 6)) {
            erroForm.novaSenha = "O campo Nova Senha deve ter no mínimo 6 caracteres";
            exibeErro = true;            
        }

        if(!form.confirmacaoNovaSenha) {            
            erroForm.confirmacaoNovaSenha = "Informe o campo Confirmação de Senha";
            exibeErro = true;            
        }

        if((form.novaSenha) && (form.confirmacaoNovaSenha)) {
            if (form.novaSenha !== form.confirmacaoNovaSenha) {
                erroForm.confirmacaoNovaSenha = "O campo Confirmação de Senha deve ser igual ao campo Nova Senha";
                exibeErro = true;            
            }   
        }
        
        if(exibeErro) {
            this.setState({erroForm: erroForm});

            return false;
        }

        return true;
    }

    gravar(e) {
        if(!this.valida()) {
            return ;
        }

        this.setState({ preload: true });        

        UsuarioService.mudarSenha(
            e.senha,
            e.novaSenha,
            e.confirmacaoNovaSenha,            
            AutenticarService.getDocumentoCliente()
        ).then(resposta => {
            this.props.showSucesso('Senha salva com sucesso!!!');

            this.setState({ preload: false, btnSalvarDisabled: true });
        }).catch(error => {
            this.setState({ preload: false });

            this.props.showErro(this.trataErro(error))
        });
    }

    componentDidMount() {
        this.props.showPreload(true);

        ClienteService.getByCpf(            
            AutenticarService.getDocumentoCliente()
        ).then(response => {
            let form = this.state.form;

            form.id = response.data.id;
            form.nome = response.data.nome;
            form.logo = response.data.foto_perfil ? Config.urlImgCliente + response.data.foto_perfil : response.data.foto_facebook;
            form.logo = response.data.foto_google ? response.data.foto_google : form.logo;
            this.setState({
                form: form,
                btnSalvarDisabled: true
            });

            this.props.showPreload(false);
        }).catch(erro => {
            this.props.showErro(this.trataErro(erro));
            this.props.showPreload(false);
        });
    }

    render() {
        return (
            <Form id="form" onSubmit={(e) => this.gravar(e)} className="form">
                {
                    !this.props.preload &&
                        <div className="logo text-center" style={{ height: '' }}>
                            <Image
                                md={12}
                                xs={12}
                                sm={12}
                                lg={12}
                                width='8em'
                                height='10em'                                
                                heightImage='8em'
                                borderRadius='50%'
                                alt="Foto"
                                semImagem={Config.urlImgBase + "login.png"}
                                imagem={(this.state.form.logo === '' || this.state.form.logo === undefined || this.state.form.logo.indexOf('undefined') > -1 ? undefined : this.state.form.logo)}
                            />
                        </div>
                }
                
                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        inputRef={e => this.senha = e}
                        id="senha"
                        nome="senha"
                        value={this.state.form.senha}
                        defaultValue={this.state.form.senha}
                        defaultFoco={true}
                        erro={this.state.erroForm.senha}
                        onChange={e => this.onChange(e)}>
                        SENHA ATUAL
                    </InputPassword>
                </div>

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        nome="novaSenha"
                        value={this.state.form.novaSenha}
                        defaultValue={this.state.form.novaSenha}
                        erro={this.state.erroForm.novaSenha}
                        onChange={e => this.onChange(e)}>
                        NOVA SENHA
                    </InputPassword>
                </div>

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        nome="confirmacaoNovaSenha"
                        value={this.state.form.confirmacaoNovaSenha}
                        defaultValue={this.state.form.confirmacaoNovaSenha}
                        erro={this.state.erroForm.confirmacaoNovaSenha}
                        onChange={e => this.onChange(e)}>
                        CONFIRMAÇÃO DE SENHA
                    </InputPassword>
                </div>                

                <div className="row">
                    <div className="col-md-12
                                col-sm-12
                                col-lg-12
                                col-xs-12">
                        <Button
                            type="submit"
                            background={1}
                            color={true}
                            justifyContent='center'
                            disabled={this.state.preload || this.state.btnSalvarDisabled}
                            preload={this.state.preload}
                            className="product-action__counter__button">
                            SALVAR
                        </Button>
                    </div>
                </div>
            </Form>
        )
    }
}

export default SenhaContent;