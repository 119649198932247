
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class ProdutoService {
    static async listar(documentoCliente, idCarrinho, pagina, limite, filtros) {
        return Requisicao.post(Config.urlBaseApi + 'produto', {            
            documentoCliente: documentoCliente,
            idCarrinho: idCarrinho,
            pagina: pagina,
            limite: limite,
            filtros: filtros
        });
    }

    static async buscarProdutoByUuid(uuidProduto) {
        return Requisicao.post(Config.urlBaseApi + 'produto/item/uuid', {            
            uuidProduto: uuidProduto
        });
    }

    static async autoComplete(searchValue) {
        return Requisicao.post(Config.urlBaseApi + 'produto/autocomplete', {            
            searchValue: searchValue
        });
    }    

    static async avisar(cpf, produto) {
        return Requisicao.post(Config.urlBaseApi + 'produto/avisar', {            
            cpf: cpf,
            produto: produto
        });
    }    
}

export default ProdutoService;