import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from './Input';

class InputExpiraCredCard extends AbstractComponent {              
    onChange(input) {                   
        var inputStr = input.target.value;
        
        inputStr = inputStr.replace(/\D/g,"");        
        inputStr = inputStr.replace(/(\d{2})(\d)/,"$1/$2");
        inputStr = inputStr.replace(/(\d{2})(\d)/,"$1/$2");        

        input.target.value = inputStr;

        if (this.props.onChange !== undefined) {
            this.props.onChange(input);
        }        
    }        
    
    render() {
        return (
            <Input
                md={this.props.md}
                xs={this.props.xs}
                sm={this.props.sm}
                lg={this.props.lg}                                    
                id={this.props.id}
                type="tel"
                nome={this.props.nome}                                                                       
                value={this.props.value}
                defaultValue={this.props.defaultValue}
                erro={this.props.erro}
                disabled={this.props.disabled}
                maxLength={5} 

                onChange={e => this.onChange(e)}  
                onFocus={this.props.onFocus}              
            >
                Validade
            </Input>             
        )
    }
}

export default InputExpiraCredCard;