import Config from "../Config";
import AutenticarService from "./AutenticarService";
import Requisicao from "./RequisicaoService";

class ClienteProdutoAviseMeService {
    static async avisar(documentoCliente, uuidProduto, titulo, mensagem) {                
        return Requisicao.post(Config.urlBaseApi + 'avise-me', {            
            documentoCliente: documentoCliente,
            uuidProduto: uuidProduto,
            userName: AutenticarService.getUserName(),
            titulo: titulo,
            mensagem: mensagem
        });
    }
}

export default ClienteProdutoAviseMeService;