import React, { Fragment } from 'react';
import AbstractComponent from '../../../AbstractComponent';
import ButtonEscolher from '../../../layout/button/ButtonEscolher';
import OpcaoPagamentoModal from './OpcaoPagamentoModal';

import { moeda } from '../../../../helper/Formatar';

class OpcaoPagamento extends AbstractComponent {  
    constructor(props) {
        super(props);

        this.state = {
            modalOpcaoPagamento: false            
        }
    }    

    onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento) {
        this.setState({            
            modalOpcaoPagamento: false
        });        
        
        this.props.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento);
    }       

    getSubtitulo() {
        if(this.props.pagamento?.id === 0) {
            return '';
        }

        if(this.props.pagamento?.valorTroco > 0) {
            return (
                <div className="text-button-opcao-pagamento">                                                        
                    {this.props.pagamento.descricao}                    

                    <br/>
                    
                    TROCO PARA R$ {moeda(this.props.pagamento.valorTroco)}                    
                </div>
            )
        }

        return this.props.pagamento?.descricao;
    }

    showButtonOpcaoPagamento() {                
        if((parseInt(this.props.carrinho?.entrega?.id, 10) === 0) || (this.props.carrinho?.entrega?.pergunta_forma_pagamento === 'Nao')) 
            return ;
            
        return (            
            <ButtonEscolher                                    
                type="button"                                    

                showButton={true}
                texto="Opção de Pagamento"
                textoSelecionar={this.props.pagamento?.id === 0 ? 'Escolher' : 'Trocar' }

                subtitulo={this.getSubtitulo()}
                showSubtitulo={true}                    

                onClick={() => this.setState({modalOpcaoPagamento: true})}
            />            
        );
    }

    fechar() {
        this.setState({modalOpcaoPagamento: false}); 
        
        if (this.props.fecharChooseOpcaoPagamento !== undefined) {
            this.props.fecharChooseOpcaoPagamento();
        }
    }

    render() {
        return(
            <Fragment>                
                {
                    this.props.modalidadePagamento && this.props.opcaoEntrega &&
                    <OpcaoPagamentoModal
                        className={"modal-detail"}                                      
                        
                        show={this.state.modalOpcaoPagamento || this.props.chooseOpcaoPagamento}
                        update={true}                    
                        
                        fechar={() => this.fechar()}
                                                    
                        modalidadePagamento={this.props.modalidadePagamento}
                        totalPedido={this.props.carrinho.totalizador.total}
                        entrega={this.props.carrinho.entrega}
                        opcaoEntrega={this.props.opcaoEntrega}
                        usaCheckout={this.props.usaCheckout}
                        
                        onUpdatePagamento={(id, descricao, troco, opcaoPagamento, permite_parcelamento) => this.onUpdatePagamento(id, descricao, troco, opcaoPagamento, permite_parcelamento)}
                    />
                }                                

                {this.showButtonOpcaoPagamento()}                                
            </Fragment> 
        )
    }
}

export default OpcaoPagamento;
