import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

class Button extends AbstractComponent {   
    background() {
        switch(this.props.background) {
            case 1: 
                return "btn btn--default btn--size-m ";
            case 2:
                return "btn btn--link btn--size-m " + (this.props.color ? '' : 'btn--gray');
            case 3:
                return 'btn--full-width btn--size-m';
            default:
                return 'btn__label';
        }
    } 

    background2() {
        switch(this.props.background) {
            case 1: 
                return 'btn__label';
            case 2:  
                return 'btn__label-light';
            case 3:
                return '';
            default:
                return 'btn__label';
        }        
    }

    onClick(e) {
        if(this.props.onClick !== undefined)
            this.props.onClick(e);
    }

    render() {
        return (                
            <button 
                type={this.props.type}
                className={this.props.className + ' ' + this.background() + ' ' + (this.props.preload ? 'btn--preload' : '')}                
                disabled={this.props.disabled}
                style={{
                    width: this.props.width,
                    height: this.props.height                   
                }}
                onClick={(e) => this.onClick(e)}
            >                
                <div 
                    className={this.background2() + ' ' + this.props.classNameTexto + ' ' + (this.props.preload ? 'display-none' : '')}
                    style={{
                        justifyContent: this.props.justifyContent,
                        display: this.props.display
                    }}
                >
                    <span>
                        {this.props.children}
                    </span>

                    <span>
                        &nbsp;
                        {this.props.label}
                    </span>
                </div>  

                <div 
                    className={"product-action__add-button " + (this.props.preload ? '' : 'display-none')} 
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'contents'
                    }}
                >
                    <img src={Config.urlImgBase + "preload_botao.gif"} style={{width: '35px', height: '35px', marginTop: '-8px'}} alt="Loading..."/> <br/>
                </div>
            </button>             
        )
    }
}

Button.defaultProps = {
    className: '',
    background: 1,
    color: true,    
    justifyContent: 'space-between',
    classNameTexto: '',
    label: ''
}

export default Button;