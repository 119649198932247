import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

class MyModal extends AbstractComponent {        
    fechar() {                
        if(this.props.fechar !== undefined) {
            this.props.fechar();
        }
    }    

    styleList(i) {
        if(i === 0)
            return {
                marginBottom: '25px',
                fontWeight: '700',
                listStyleType: 'none'
            };

        return {listStyle: 'disc'}
    }

    texto() {                
        if(Array.isArray(this.props.texto))
            return (
                <ul>
                    {this.props.texto.map((item, i) => {
                        return (
                            <li 
                                style={this.styleList(i)}
                                key={i}
                            >
                                {item}
                            </li>
                        )
                    })}
                </ul>
            );
        
        const texto = (this.props.texto === undefined) ? '' : this.props.texto;

        return (texto === undefined || texto === '' || texto ===  null) ? this.P_ERRO_PADRAO() : texto;                      
    } 
    
    subTexto() {            
        return this.props.subTexto === undefined ? '' : this.props.subTexto;                      
    }     

    styleHeader() {                
        return (this.props.styleHeader === undefined) ? "bg-white" : this.props.styleHeader;
    }

    styleIcon() {
        return (this.props.styleIcon === undefined) ? "" : this.props.styleIcon;
    }

    styleText() {
        return (this.props.styleText === undefined) ? "text-dark" : this.props.styleText;        
    }

    styleButton() {
        return (this.props.styleButton === undefined) ? "btn-primary" : this.props.styleButton;        
    }

    render() {
        return (
            <Modal show={this.props.show} 
                   className={this.props.className}
                   style={{zIndex: 9999}}
                   onHide={() => this.fechar()}>
                {
                    this.props.header !== "" &&
                    <Modal.Header className={this.styleHeader()}
                                  closeButton>
                        <h4>
                            <i className={this.styleIcon()}/> {this.props.header}
                        </h4>
                    </Modal.Header>
                }
                
                <Modal.Body 
                    className={this.styleText()} 
                    style={{
                        marginBottom: '15px'
                    }}>
                    <div 
                        className={this.styleText()} 
                        style={{
                            wordWrap: 'break-word', 
                            marginBottom: '15px'
                        }}>
                        {this.texto()}

                        <br/>
                        <br/>

                        {this.subTexto()}                        
                    </div>

                    <div 
                        className={"row text-center " + this.props.classNameButton} 
                        style={{
                            marginTop: '30px',
                            marginLeft: '-1px'
                        }}>
                        <div className="col-md-12 
                                        col-sm-12
                                        col-lg-12
                                        col-xs-12">
                            <Button 
                                    className={"btn " + this.styleButton()}  
                                    type="button" 
                                    onClick={() => {this.fechar()}}>
                                {this.props.buttonTxt}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

MyModal.defaultProps = {
    textoArray: []
}

export default MyModal;