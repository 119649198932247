import React, { Fragment } from "react";
import AbstractComponent from "../AbstractComponent";
import Alerta from "../layout/alert/Alerta";
import ModalSucesso from "../layout/modal/ModalSucesso";
import DadosPessoais from "./DadosPessoais";
import Endereco from "./Endereco";
import Credentials from "./Credentials";

import ClienteService from "../../service/ClienteService";
import ModalAlerta from "../layout/modal/ModalAlerta";

class Cadastro extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      showSuccess: false,

      erro: "",

      passo: 1,

      form: {
        id: 0,
        idEmpresa: 0,
        cpf: "",
        nome: "",
        telefone: "",
        dataNascimento: "",
        foto_perfil: "",
        foto_social: "",
        idRedeSocial: "",
        tipo_rede_social: undefined,

        tipo_endereco: "Bairro",
        cep: "",
        logradouro: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        ponto_de_interesse: "",
        ponto_de_interesse_manual: "Nao",
        ponto_de_interesse_descricao: "",
        codigo_municipio: "",

        email: "",
        senha: "",
        confirmar_senha: "",
      },

      erroForm: {
        nome: "",
        telefone: "",
        dataNascimento: "",
        cep: "",
        logradouro: "",
        endereco: "",
        numero: "",
        bairro: "",
        cidade: "",
        email: "",
        senha: "",
        ponto_de_interesse: "",
        ponto_de_interesse_descricao: "",
      },
    };

    if (this.props.form !== undefined) {
      let form = this.state.form;

      form.cpf = this.props.cpf;
      form.nome = this.props.form.nome;
      form.foto_social = this.props.form.foto_social;
      form.idRedeSocial = this.props.form.idRedeSocial;
      form.tipo_rede_social = this.props.form.tipo_rede_social;
      form.email = this.props.form.email;

      this.state.form = form;
    }
  }

  onCompleteDadosPessoais(changed) {
    let form = this.state.form;

    form.cpf = changed.cpf;
    form.nome = changed.nome;
    form.dataNascimento = changed.dataNascimento;
    form.telefone = changed.telefone;

    this.setState({
      form: form,
      passo: 2,
    });
  }

  gravar() {
    this.setState({ preload: true });

    var form = this.state.form;
    const aux = this.state.form.tipo_rede_social;

    form.tipo_rede_social = form.tipo_rede_social.enumName;

    ClienteService.cadastro(form)
      .then(() => {
        form.tipo_rede_social = aux;

        this.setState({
          form: form,
          showSuccess: true,
        });
      })
      .catch((error) => {
        this.setState({
          preload: false,
          erro: this.trataErro(error),
        });
      });
  }

  onCompleteEndereco(changed) {
    let form = this.state.form;

    form.tipo_endereco = changed.tipo_endereco;
    form.cep = changed.cep;
    form.logradouro = changed.logradouro;
    form.endereco = changed.endereco;
    form.numero = changed.numero;
    form.complemento = changed.complemento;
    form.bairro = changed.bairro;
    form.cidade = changed.cidade;
    form.uf = changed.uf;
    form.ponto_de_interesse = changed.ponto_de_interesse;
    form.ponto_de_interesse_manual = changed.ponto_de_interesse_manual;
    form.ponto_de_interesse_descricao = changed.ponto_de_interesse_descricao;
    form.codigo_municipio = changed.codigo_municipio;

    if (form.idRedeSocial === "") {
      this.setState({
        form: form,
        passo: 3,
      });

      return;
    }

    this.gravar();
  }



  logar() {
    this.setState({ showSuccess: false });
    this.props.logar(
      this.state.form.email,
      this.state.form.senha,
      this.state.form.cpf,
      this.state.form.idRedeSocial,
      this.state.form.tipo_rede_social
    );
  }


    
  


  render() {
    return (
      <Fragment>
        <Alerta exibir={this.state.erro !== ""} texto={this.state.erro} />


        <ModalSucesso
          show={this.state.showSuccess}
          header="Usuário cadastrado com sucesso!"
          texto="Agora você pode ativar descontos e receber as melhores ofertas exclusivas para você! :)"
          fechar={() => this.logar()}
        />

        <DadosPessoais
          show={this.state.passo === 1}
          cpf={this.props.cpf}
          form={this.state.form}
          btnName={
            !this.state.form.idRedeSocial
              ? "PRÓXIMO PASSO (2 DE 3)"
              : !this.state.preload
              ? "PRÓXIMO PASSO (2 DE 2)"
              : "AGUARDE..."
          }
          onComplete={(form) => {
            this.onCompleteDadosPessoais(form);
          }}
          onTitulo={(title) => this.props.onTitulo(title)}
        />

        <Endereco
          cidadesDisponiveis={this.props.cidadesDisponiveis}
          show={this.state.passo === 2}
          empresa={this.props.empresa}
          btnName={
            !this.state.form.idRedeSocial
              ? "PRÓXIMO PASSO (3 DE 3)"
              : "FINALIZAR"
          }
          image={!this.state.form.idRedeSocial}
          onComplete={(form) => {
            this.onCompleteEndereco(form)
          }}
          onTitulo={(title) => this.props.onTitulo(title)}
        />

        <Credentials
          show={this.state.passo === 3}
          form={this.state.form}
          onTitulo={(title) => this.props.onTitulo(title)}
          onLoginOk={(userName, token, documentoCliente, tipoLogin) =>
            this.props.onLoginOk(userName, token, documentoCliente, tipoLogin)
          }
          onRegisterOk={() => this.setState({ showSuccess: true })}
          onError={(error) => this.setState({ erro: error })}
        />
      </Fragment>
    );
  }
}

export default Cadastro;
