export function getParam(param) {
    //var url_string = window.location.href;
    var url = new URL(window.location.href);

    return url.searchParams.get(param);        
}

export function getUrl() {
    var url = window.location.protocol + '//' + window.location.hostname;        

    if(window.location.port !== '')
        url += ':' + window.location.port;        

    return url + '/';
}

export function getUrlBase() {                 
    const subpasta = process.env.REACT_APP_HAS_SUB_PASTA;      

    if(subpasta.toLowerCase() === 'true') {                        
        let pathName = window.location.pathname;
        let url = pathName.split("/");            

        if(url.length === 0)
            return '/';  

        const modoCompartilhada = process.env.REACT_APP_HAS_HOSPEDAGEM_COMPARTILHADA;

        if(modoCompartilhada.toLowerCase() === 'true') 
            return '/' + url[1] + '/' + url[2] + '/';

        return '/' + url[1] + '/';
    }

    return '/';
}