import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import AbstractComponent from "../AbstractComponent";
import ModalAlerta from "../layout/modal/ModalAlerta";
import ModalTitle from "../layout/modal/ModalTitle";
import ButtonFrete from "./ButtonFrete";
import CarrinhoService from '../../service/CarrinhoService';

import { moeda } from "../../helper/Formatar";

import "../layout/modal/Modal.css";
import AutenticarService from "../../service/AutenticarService";


class OpcaoFreteModal extends AbstractComponent {
    constructor(props) {
        super(props);
    
        this.state = {
            erro: '',
            titulo: 'OPÇÃO DE FRETE',
            preload: false,
        };
    }

    gravar(frete) {
        this.setState({
            preload: true,            
        });    

        let form = {};
        form.transportadora = frete.transportadora;
        form.servico = frete.servico;
        form.servico_codigo = frete.servico_codigo;
        form.valor = frete.valor;
        form.documento = AutenticarService.getDocumentoCliente();
        form.idCarrinho = AutenticarService.getIdCarrinho();

        CarrinhoService.gravarFrete(form)
        .then((response) => {
            this.setState({
                preload: false,
                active: -1,
            });

            this.props.onUpdateCart(response.data);
            this.props.fechar();
        })
        .catch((erro) => {
            this.setState({
                preload: false,                
                erro: this.trataErro(erro),
            });
        });
    }    

    render() {
        
        return (
            <Fragment>
                <ModalAlerta
                    show={this.state.erro !== ""}
                    texto={this.state.erro}
                    fechar={() => this.setState({ erro: "" })}
                />

                <Modal
                    show={this.props.show}
                    className={this.props.className}
                    style={{ zIndex: 9999 }}
                    onHide={() => this.props.fechar()}
                >
                    <ModalTitle
                        icon="down"
                        header={this.state.titulo}
                        fechar={() => this.props.fechar()}
                    />

                    <Modal.Body>
                        <div style={{ padding: "0px" }}>
                            {this.props.fretes.map((frete, index) => (                                
                                <ButtonFrete
                                    key={index}
                                    type="button"                                    
                                    texto={frete.servico}
                                    valor={`R$ ${moeda(frete.valor)}`}
                                    textoSelecionar="Escolher"        
                                    preload={this.state.preload}
                                    onClick={() => this.gravar(frete)}
                                />
                            ))}        
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default OpcaoFreteModal;
