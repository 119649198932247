import AutenticarService from '../../service/AutenticarService';
import ClienteProdutoFavoritoService from '../../service/ClienteProdutoFavoritoService';

var ClienteProdutoFavoritoEnum = {
    1: {
        enumName: 1,
        submit: async function (uuid) {
            return ClienteProdutoFavoritoService.like(                
                AutenticarService.getDocumentoCliente(),
                uuid                
            );
        }
    },
    0: {
        enumName: 0,
        submit: async function (uuid) {
            return ClienteProdutoFavoritoService.unlike(                
                AutenticarService.getDocumentoCliente(),
                uuid                
            );
        }
    },
    get(value) {
        if (value === null)
            return ClienteProdutoFavoritoEnum.TRUE;

        var array = Object.values(ClienteProdutoFavoritoEnum);
        var clienteProdutoFavoritoEnumFound = {};

        array.forEach(element => {
            if (element.enumName === value) {
                clienteProdutoFavoritoEnumFound = element;
                return;
            }
        });

        return clienteProdutoFavoritoEnumFound;
    }
}

export default ClienteProdutoFavoritoEnum;