import React from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import ModalTitle from '../modal/ModalTitle';

class ModalDetail extends AbstractComponent {     
    showChildrenTitle() {
        if(this.props.childrenTitle === undefined)
            return ;

        return (
            this.props.childrenTitle
        )
    }

    render() {
        return (            
            <Modal 
                className="modal-detail"
                show={this.props.show}
                onHide={() => this.props.fechar()}
                style={{ zIndex: 9999 }}>
                
                <ModalTitle
                    icon={this.props.icon}
                    header={this.props.header}
                    fechar={() => this.props.fechar()}
                >                                            
                    {this.showChildrenTitle()}
                </ModalTitle>

                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
            </Modal>            
        )
    }
}

export default ModalDetail;