import Config from "../Config";
import Requisicao from "./RequisicaoService";

class EnderecoService {
    static async gravar(dados) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/endereco', dados);
    }

    static async deletar(documentoCliente, id) {
        return Requisicao.delete(Config.urlBaseApi + 'cliente/endereco/' + documentoCliente + '/' + id);
    }

    static async alterarEndereco(dados) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/endereco', dados);
    }

    static async setIdClienteEndereco(id) {
        return Requisicao.post(Config.urlBaseApi + 'cliente/endereco/setEnderecoPadrao', { id: id });
    }

}

export default EnderecoService;