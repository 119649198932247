
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class FormasPagamentoService {    
    static async getParcelas(idEmpresa, idModalidade, totalCompra) {                                              
        return Requisicao.get(Config.urlBaseApi + 'formas-pagamento/' + idEmpresa + '/' + idModalidade + '/' + totalCompra);
    }     
}

export default FormasPagamentoService;