import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import Button from '../layout/button/Button';
import ModalQuestion from '../layout/modal/ModalQuestion';
import Vitrine from '../shopping/vitrine/Vitrine';
import FavoritoEmpty from './FavoritoEmpty';

import AutenticarService from '../../service/AutenticarService';
import ClienteProdutoFavoritoService from '../../service/ClienteProdutoFavoritoService';

import { atualizaVitrine } from '../../helper/Vitrine';
class FavoritoContent extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            modalQuestion: false,

            preload: false,

            vitrine: []
        }
    }   

    removerTodosOsFavoritos() {        
        this.setState({ 
            preload: true,
            modalQuestion: false 
        });

        ClienteProdutoFavoritoService.unlikeAll(            
            AutenticarService.getDocumentoCliente()
        ).then(() => {            
            this.setState({ 
                preload: false,
                vitrine: [] 
            });

            this.props.onUpdateBadgeFavorito(true);
        }).catch(() => {
            this.setState({ 
                preload: false
            });
        });
    }    

    onUpdateVitrine(index) {
        let vitrine = this.state.vitrine;
        
        vitrine.splice(index, 1);

        this.setState({ vitrine: vitrine });
    }

    onUpdateBadgeFavorito(tipo, index) {        
        this.onUpdateVitrine(index);
        this.props.onUpdateBadgeFavorito(false);
    }

    componentDidMount() {
        this.props.showPreload(true);

        ClienteProdutoFavoritoService.listar(            
            AutenticarService.getIdCarrinho(),
            AutenticarService.getDocumentoCliente()
        ).then(response => {          
            this.props.showPreload(false);

            this.setState({ vitrine: response.data });
        }).catch(() => {
            this.props.showPreload(false);
        })
    }    

    onUpdateCart(carrinho, indiceItem, indiceCarrinhoItem, quantidade) {
        this.setState({vitrine: atualizaVitrine(this.state.vitrine, carrinho, indiceItem, indiceCarrinhoItem, quantidade)});

        this.props.onUpdateCart(carrinho, indiceItem, indiceCarrinhoItem, quantidade)
    }

    showComponent() {
        if(this.state.vitrine.length === 0)
            return (
                <FavoritoEmpty                                                   
                    route={(item) => this.props.route(item)}
                />
            );            

        return (
            <Vitrine                       
                vitrine={this.state.vitrine}
                carrinho={this.props.carrinho}
                telaFavorito={true}
                
                route={(item) => this.props.route(item)}

                onUpdateVitrine={(index) => this.onUpdateVitrine(index)}    
                onUpdateBadgeFavorito={(tipo, index) => this.onUpdateBadgeFavorito(tipo, index)}    
                onUpdateCart={(carrinho, indiceItem, indiceCarrinhoItem, quantidade) => this.onUpdateCart(carrinho, indiceItem, indiceCarrinhoItem, quantidade)}                
            />
        )
    }

    render() {
        return ( 
            <Fragment>
                <ModalQuestion
                    show={this.state.modalQuestion}                    
                    texto="Tem certeza de que deseja apagar todos os seus favoritos?"
                    btnSimClick={() => this.removerTodosOsFavoritos()}
                    btnNaoClick={() => this.setState({ modalQuestion: false })}
                    fechar={() => this.setState({ modalQuestion: false })}
                />

                <div className="container" style={{ padding: "0px 10px", maxWidth: "unset", width: "100%"}}>
                    <div className="row" style={{ margin: "0px 10px"}}>
                        <Button
                            type="button"
                            className={"product-action__counter__button text-center " + (this.state.vitrine.length === 0 ? ' display-none '  : '')}
                            onClick={() => this.setState({modalQuestion: true})}
                            display='block'
                            preload={this.state.preload}                            
                        >
                            Remover todos Favoritos
                        </Button>
                    </div>

                    {this.showComponent()}
                </div>
            </Fragment>
        )
    }
}

export default FavoritoContent;