import React, { Fragment } from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import Button from '../../layout/button/Button';

import AutenticarService from '../../../service/AutenticarService';
import CarrinhoService from '../../../service/CarrinhoService';

import {moeda} from '../../../helper/Formatar';
import TipoCalculoEnum from '../../../helper/enumerador/TipoCalculoEnum';

class AddCartButton extends AbstractComponent { 
    constructor(props) {
        super(props);  
        
        this.state = {
            btnAdd: 'Adicionar'
        }

        this.lblBtnAdd = this.props.textoButton === undefined 
                            ? (this.props.update === false ? 'Adicionar' : 'Atualizar')
                            : this.props.textoButton;
    
        this.state.btnAdd = this.lblBtnAdd;        
    }    

    add(item) {
        this.setState({
            btnAdd: 'Aguarde...'            
        });             

        CarrinhoService.add(
            item            
        ).then(response => {                             
            AutenticarService.setIdCarrinho(response.data.id);            
                              
            this.setState({ btnAdd: this.lblBtnAdd });                          

            let carrinhoItem = undefined;

            if(item.tipo_calculo.toUpperCase() === TipoCalculoEnum.QUANTIDADE.enumName) 
                carrinhoItem = this.props.carrinho.carrinho_item_quantidade;
            
            this.props.onSuccess(response.data, (carrinhoItem === undefined ? 0 : carrinhoItem.length), item.quantidade);
        }).catch(erro => {                                             
            this.setState({ btnAdd: this.lblBtnAdd });

            this.props.onError(this.trataErro(erro));
        });
    }

    update(item) {
        item.idCarrinhoItem = this.props.idCarrinhoItem;

        this.setState({
            btnAdd: 'Aguarde...'            
        }); 
        
        CarrinhoService.update(
            item            
        ).then(response => {                             
            AutenticarService.setIdCarrinho(response.data.id);            

            let carrinho = this.props.carrinho;  

            carrinho = response.data;                     

            this.setState({ btnAdd: this.lblBtnAdd });            
                        
            this.props.onSuccess(carrinho, undefined, item.quantidade);
        }).catch(erro => {                                             
            this.setState({ btnAdd: this.lblBtnAdd });

            this.props.onError(this.trataErro(erro));
        });
    }

    onSubmit(e) {         
        e.preventDefault();

        let item = {};                        

        item.idCarrinho = AutenticarService.getIdCarrinho();        
        item.documento = AutenticarService.getDocumentoCliente();                
        item.uuidProduto = this.props.item.uuid;  
        item.codigo_interno = this.props.item.codigo_interno;
        item.codbarra = this.props.item.codbarra;
        item.codigo_balanca = this.props.item.codigo_balanca;
        item.tipo_calculo = this.props.item.tipo_calculo;
        item.unidade = this.props.item.unidade;
        item.descricao = this.props.item.descricao;
        item.quantidade = this.props.quantidade;
        item.multiplicador = this.props.item.multiplicador;
        item.valor = this.props.item.venda;
        item.desconto = 0;
        item.controla_estoque = this.props.item.controla_estoque;
        
        if(this.props.item.promocao > 0)
            item.desconto = (this.props.item.venda - this.props.item.promocao);                               

        if(this.props.update) {
            this.update(item);

            return ;
        }
        
        this.add(item);
    }       

    render() {
        return (   
            <Col md={this.props.md}
                 sm={this.props.sm}
                 lg={this.props.lg}
                 xs={this.props.xs}
                 style={this.props.style}
            >                   
                <form onSubmit={(e) => this.onSubmit(e)}>                                                   
                    <Button
                        type="submit"                     
                        disabled={this.state.btnAdd !== this.lblBtnAdd}
                        preload={this.state.btnAdd !== this.lblBtnAdd}                    
                        label={this.props.textoButton === undefined ? moeda(this.props.value) : ''}
                        className="product-action__counter__button"
                        classNameTexto={this.props.textoButton === undefined ? '' : 'btnAdd'}
                    >
                        {this.state.btnAdd} 
                    </Button>           
                </form>

                <div >
                    {/* &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}                
                    {  
                        this.props.item.tipo_calculo.toUpperCase() === TipoCalculoEnum.PESO.enumName
                        ? 
                            <span className="product-peso__label">
                                <b>Preço será confirmado após pesagem</b>                            
                            </span>
                        : 
                            <Fragment>
                                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Fragment>
                    }
                </div>
            </Col>
        )
    }
}

AddCartButton.defaultProps = {    
    quantidade: 1,
    idCarrinhoItem: 0,
    update: false,
    md: 12,
    sm: 12,
    xs: 12,
    lg: 12
}

export default AddCartButton;