import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AbstractComponent from "./component/AbstractComponent";
import NavHeader from "./component/layout/nav/header/NavHeader";
import NavBottom from "./component/layout/nav/bottom/NavBottom";
import Footer from "./component/layout/template/Footer";
import Notificacao from "./component/notificacao/Notificacao";
import NotificacaoConfiguracao from "./component/notificacao/configuracao/Notificacao";
import Login from "./component/login/Login";
import Perfil from "./component/perfil/Perfil";
import Pedido from "./component/pedidos/Pedido";
import Sobre from "./component/sobre/Sobre";
import NaoEncontrado from "./component/naoencontrado/NaoEncontrado";
import RecuperarSenha from "./component/recuperar-senha/RecuperarSenha";
import EsqueciMeuEmail from "./component/recuperar-senha/EsqueciMeuEmail";
import RedefinirSenha from "./component/redefinir-senha/RedefinirSenha";
import Shopping from "./component/shopping/Shopping";
import Offline from "./component/offline/Offline";
import TaxaEntrega from "./component/empresa/frete/TaxaEntrega";
import Favorito from "./component/favorito/Favorito";
import EnderecoLista from "./component/cliente/EnderecoLista";
import Politica from "./component/layout/politica_termos/politica/Politica";
import Cupons from "./component/layout/cupons/Cupons";
import Termos from "./component/layout/politica_termos/termos/Termos";
import CategoriaDesktop from "./component/categoria/CategoriaDesktop";
import ModalAlerta from "./component/layout/modal/ModalAlerta";
import Senha from "./component/senha/Senha";
import PushNotification from "./component/push-notification/PushNotification";
import { getToken } from "./component/push-notification/firebase";

import AutenticarService from "./service/AutenticarService";
import EmpresaService from "./service/EmpresaService";
import ClienteService from "./service/ClienteService";
import BadgeService from "./service/BadgeService";
import EmpresaModalidadePagamentoService from "./service/EmpresaModalidadePagamentoService";
import OpcaoEntregaService from "./service/OpcaoEntregaService";
import ProdutoService from "./service/ProdutoService";
import ClienteAparelhoService from "./service/ClienteAparelhoService";
import ConfiguracaoService from "./service/ConfiguracaoService";

import TipoCalculoEnum from "./helper/enumerador/TipoCalculoEnum";
import ModalidadePagamentoEnum from "./helper/enumerador/ModalidadePagamentoEnum";
import AtivoEnum from "./helper/enumerador/AtivoEnum";

import Config from "./Config";

import { atualizaVitrine } from "./helper/Vitrine";

import "./css/style.css";
import "./css/avance.css";
import "./css/template.css";
import "./css/conectividade.css";

import CampanhaBannerService from "./service/CampanhaBannerService";
import ExcluirConta from "./component/perfil/ExcluirConta";
import { buscaConfiguracao } from "./helper/Validar";
import Checkout from "./component/checkout/Checkout";
import Preload from "./component/layout/preload/Preload";

class App extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      codigosCidadesDisponiveis: [],

      textoAlerta: "",

      erro: "",

      preloadCargaInicial: true,

      preloadEmpresa: false,

      preloadCliente: true,

      preloadBadge: false,

      preloadModalidadePagamento: false,

      preloadProduto: false,

      preloadVitrine: false,

      preloadOpcaoEntrega: false,

      preloadConfiguracao: false,

      vitrinePreload: false,

      escondeLupa: false,

      refreshCart: false,

      notificacaoModal: false,

      pagina: 1,

      titulo: "",

      empresaValida: true,

      menu: [],

      searchKey: "",

      empresa: {
        id: 0,
        alias_app: "",
        perfilSistema: "",
        ambiente: "",
        configuracao: [],
      },

      opcaoEntrega: [],

      cliente: {
        id: 0,
        cpf: "",
        nome: "",
        telefone: "",
        dataNascimento: "",
        tipo_endereco: "",
        cep: "",
        logradouro: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        codigo_municipio: "",
        ponto_de_interesse: "",
        email: "",
        frete: {},
      },

      carrinho: {
        item: {},

        carrinho_item_quantidade: [],

        carrinho_item_peso: [],

        entrega: {},

        totalizador: {
          sub_total: 0,
          desconto: 0,
          taxa_entrega: 0,
          total: 0,
        },
      },
      campanha: [],
      campanhaProdutos: [],
      modalidadePagamento: undefined,

      vitrine: [],

      pagamento: {
        id: 0,
        descricao: "",
        valorTroco: 0,
        modalidade: {
          opcaoPagamento: "",
        },
        parcelamento: 0,
        permite_parcelamento: AtivoEnum.NAO,
      },
      badgeNavBottom: {
        notificacao: 0,
        favorito: 0,
      },
      tipoCalculoFrete: '',    
      usaCheckout: undefined,
      exibirRodape: false,
    };
    this.filtros = [];
  }

  onTitulo(titulo) {
    this.setState({
      titulo: titulo,
      escondeLupa: titulo !== AutenticarService.getNomeEmpresa(),
    });
    return this.state.titulo;
  }

  freteCidadesDisponiveis() {
    OpcaoEntregaService.freteCodigoMunicipio().then((response) => {

      this.setState({codigosCidadesDisponiveis : response.data});
    });
  }

  acessarProdsCampanha(imagemRef) {
    CampanhaBannerService.produtosCampanha(imagemRef).then((response) => {
      if (response.data.length === 0) return;
      let ids = [];
      response.data.forEach((element) => {
        ids.push(element["id_produto"]);
      });
      this.setState({ filtros: { campo: "codigoInterno", valor: ids } });
      this.loadVitrine(false, true);
    });
  }

  preparaConfiguracoes() {    
    var tipoFrete = buscaConfiguracao(this.state.empresa.configuracao, 'Empresa', 'Tipo_de_cálculo_do_frete', 'Cidade');        
    var usaCheckout = buscaConfiguracao(this.state.empresa.configuracao, 'Empresa', 'Usa_checkout', 'false') === 'true';
    var exibirRodape = buscaConfiguracao(this.state.empresa.configuracao, 'Empresa', 'exibir_rodape', 'false') === 'true';
    
    this.setState({
      tipoCalculoFrete: tipoFrete, 
      usaCheckout: usaCheckout,
      exibirRodape: exibirRodape
    });
  }

  getConfiguracao() {
    this.setState({ preloadConfiguracao: true });

    ConfiguracaoService.getAll(AutenticarService.getUuidAparelho())
      .then((response) => {
        if (response.data.length === 0) {
            this.setState({ preloadCargaInicial: false, preloadConfiguracao: false });
            this.preparaConfiguracoes();
            return;
        }

        var empresa = this.state.empresa;

        var grupo = {};

        var chave = {};

        let grupoNome = "";

        response.data.forEach((element) => {
          if (grupoNome === "") {
            grupoNome = element.grupo;
          } else if (grupoNome !== element.grupo) {
            grupo[grupoNome.replaceAll(" ", "_")] = chave;
            grupoNome = element.grupo;
            chave = {};
          }

          chave[element.chave.replaceAll(" ", "_")] = element.valor;
        });

        grupo[grupoNome.replaceAll(" ", "_")] = chave;

        empresa.configuracao = grupo;

        this.setState({ empresa: empresa, preloadConfiguracao: false, preloadCargaInicial: false });
        this.preparaConfiguracoes()
      })
      .catch(() => {        
        this.setState({ preloadConfiguracao: false, preloadCargaInicial: false });
      });
  }

  validaCliente(empresa) {
    this.setState({ preloadCliente: true });

    ClienteService.cliente(AutenticarService.getDocumentoCliente())
      .then((response) => {
        let cliente = this.state.cliente;

        cliente.email = response.data.usuario.email;

        cliente.id = response.data.cliente.id;
        cliente.nome = response.data.cliente.nome;

        if (!cliente.endereco) {
          cliente.destinatario = response.data.cliente.destinatario;
          cliente.cep = response.data.cliente.endereco.cep;
          cliente.logradouro = response.data.cliente.endereco.logradouro;
          cliente.endereco = response.data.cliente.endereco.endereco;
          cliente.numero = response.data.cliente.endereco.numero;
          cliente.complemento = response.data.cliente.endereco.complemento;
          cliente.bairro = response.data.cliente.endereco.bairro;
          cliente.cidade = response.data.cliente.endereco.cidade;
          cliente.uf = response.data.cliente.endereco.uf;
          cliente.tipo_endereco = response.data.cliente.tipo_endereco;
          cliente.codigo_municipio =
            response.data.cliente.endereco.codigo_municipio;
          cliente.ponto_de_interesse = response.data.cliente.ponto_de_interesse;
          cliente.ponto_de_interesse_manual =
            response.data.cliente.ponto_de_interesse_manual;
          cliente.ponto_de_interesse_descricao =
            response.data.cliente.ponto_de_interesse_descricao;
        }

        cliente.cliente_endereco = response.data.cliente.cliente_endereco;
        cliente.foto_perfil =
          Config.urlImgCliente + response.data.cliente.foto_perfil;

        if (
          response.data.cliente.foto_perfil !== undefined &&
          response.data.cliente.foto_perfil.substr(0, 4) === "http"
        )
          cliente.foto_perfil = response.data.cliente.foto_perfil;

        this.setState({
          cliente: cliente,
          refreshCart: true,          
        });

        if (!AutenticarService.getUserName())
          AutenticarService.setUserName(cliente.nome);

        this.opcaoEntrega();
        this.validaProgramaFidelidade(empresa);
      })
      .catch(() => {
        this.opcaoEntrega();
        this.setState({
          refreshCart: true,
          preloadCliente: false,
        });
      });
  }

  validaEmpresa() {
    this.setState({
      textoAlerta: "",
      preloadEmpresa: true,
      preloadCargaInicial: true,
    });

    EmpresaService.validaEmpresa()
      .then((response) => {
        let empresa = this.state.empresa;

        empresa.id = response.data.id;
        empresa.alias_app = response.data.alias_app;
        empresa.perfilSistema = response.data.perfil_sistema;
        empresa.shoppingBag = response.data.shopping_bag;
        empresa.fantasia = response.data.fantasia;
        empresa.codigo_municipio = response.data.endereco.codigo_municipio;
        empresa.ambiente = response.data.ambiente;
        empresa.usa_programa_fidelidade = response.data.usa_programa_fidelidade;
        empresa.nome = response.data.nome;
        empresa.cnpj = response.data.cnpj;

        AutenticarService.setEmpresa(
          empresa.alias_app,
          empresa.perfilSistema,
          empresa.shoppingBag
        );

        this.setState({
          empresa: empresa,
          empresaValida: true,
          refreshCart: false,
          preloadEmpresa: false,
        });

        this.getConfiguracao();
        this.validaCliente(empresa);
        this.buscaBadge();
        this.modalidadePagamento();
        this.loadVitrine(false, true);        
      })
      .catch((erro) => {
        this.setState({
          textoAlerta: this.trataErro(erro),
          preloadEmpresa: false,
          empresaValida: false,
          preloadCargaInicial: false,
        });
      });
  }

  buscaBadge() {
    let badge = this.state.badgeNavBottom;
    const userLogged = this.isLogged();

    this.setState({ preloadBadge: true });

    BadgeService.badge(
      AutenticarService.getIdCarrinho(),
      userLogged ? AutenticarService.getDocumentoCliente() : "",
      AutenticarService.getUuidAparelho()
    )
      .then((response) => {
        badge.notificacao = response.data.notificacao;
        badge.favorito = response.data.favorito;

        this.setState({
          badgeNavBottom: badge,
          preloadBadge: false,
        });
      })
      .catch(() => {
        this.setState({ preloadBadge: false });
      });
  }

  modalidadePagamento() {
    this.setState({ preloadModalidadePagamento: true });

    EmpresaModalidadePagamentoService.listar(this.state.empresa.id)
      .then((response) => {
        this.setState({
          modalidadePagamento: response.data,
          preloadModalidadePagamento: false,
        });
      })
      .catch(() => {
        this.setState({ preloadModalidadePagamento: false });
      });
  }

  opcaoEntrega() {

    this.setState({ preloadOpcaoEntrega: true });

    OpcaoEntregaService.listar(
      this.state.cliente.tipo_endereco,
      this.state.cliente.codigo_municipio,
      this.state.cliente.ponto_de_interesse
    )
      .then((response) => {
        this.setState({
          opcaoEntrega: response.data,
          preloadOpcaoEntrega: false,
        });
      })
      .catch((erro) => {
        this.setState({
          opcaoEntrega: [],
          erro: this.trataErro(erro),
          preloadOpcaoEntrega: false,
        });
      });
      
  }

  validaProgramaFidelidade(empresa) {
    if (empresa.usa_programa_fidelidade) {
        let docCliente = AutenticarService.getDocumentoLocal();
        if (docCliente !== false) {
            ClienteService.getContemCadastroLocal(docCliente).then(response => {
                let cliente = this.state.cliente;
                cliente.tem_cadastro_local = response.data.cadastradoLocal === true;
                this.setState({ cliente: cliente, preloadCliente: false });
            }).catch(() => {
                this.setState({ preloadCliente: false });
            });            
        } else {
            this.setState({ preloadCliente: false });
        }
    } else {
        this.setState({ preloadCliente: false });
    }
  }

  removeFiltro(value) {
    let filtros = this.filtros;
    let index = -1;

    for (var cont = 0; cont < filtros.length; cont++) {
      if (filtros[cont].campo === value) {
        index = cont;
        break;
      }
    }

    if (index > -1) filtros.splice(index, 1);

    return filtros;
  }

  loadVitrine(fetchMoreData, inSearch) {
    if (fetchMoreData)
      this.setState({ vitrinePreload: this.state.vitrine.length > 0 });
    //if (inSearch)
    else this.setState({ preloadProduto: true });

    var input = document.getElementById("buscaProdutoMobile");

    if (input === null || input === undefined)
      input = document.getElementById("buscaProduto");

    let searchKey = "";
    let versaoApp = Config.versao;

    if (input !== undefined) searchKey = input.value;

    var pagina = this.state.pagina;

    if (inSearch) {
      pagina = 1;
      this.setState({ pagina: pagina });
    }

    let filtros = this.removeFiltro("descricao");

    if (searchKey !== "")
      filtros.push({ campo: "descricao", valor: searchKey, versao: versaoApp });
    if (this.state.filtros) {
      filtros.push(this.state.filtros);
    }
    this.filtros = filtros;

    ProdutoService.listar(
      AutenticarService.getDocumentoCliente(),
      AutenticarService.getIdCarrinho(),
      pagina,
      20,
      filtros
    )
      .then((response) => {
        pagina = this.state.pagina + 1;

        this.setState({
          vitrine: inSearch
            ? response.data
            : this.state.vitrine.concat(response.data),
          preloadProduto: false,
          preloadVitrine: false,
          vitrinePreload: false,
          pagina: pagina,
          searchKey: searchKey,
        });
      })
      .catch(() => {
        this.setState({
          preloadProduto: false,
          vitrinePreload: false,
          preloadVitrine: false,
        });
      });
  }

  reloadPage() {
    setInterval(function () {
      document.location.reload(false);
      window.location.reload(false);
    }, 1000 * 60 * 60 * 24);
  }

  onUpdateVitrine(index) {
    let vitrine = this.state.vitrine;

    vitrine[index].favorito = !vitrine[index].favorito;

    this.setState({ vitrine: vitrine });
  }

  componentDidMount() {
    getToken(undefined);
    this.reloadPage();
    this.validaEmpresa();
    this.freteCidadesDisponiveis();
  }

  /*******************************************************************************************************************
   * Procedimento para atualizar o carrinho sempre que o usuario adicionar, alterar ou remover um item               *
   *******************************************************************************************************************
   * Parametros:                                                                                                     *
   *      carrinho: Retorna a lista de itens dentro do carrinho                                                      *
   *                                                                                                                 *
   *      indiceItem: Esse campo pode ter 2 valores diferentes:                                                      *
   *              a) O primeiro valor é realmente o indice do produto na vitrine que foi adicionado no carrinho      *
   *              (Utilizado somente no caso do usuario incluir um item no carrinho)                                 *
   *                                                                                                                 *
   *              b) O segundo valor é um objeto ProdutoEntity, aonde o sistema testa essa variavel para saber se    *
   *              é um objeto. Sendo um objeto, então eu busco na vitrine o produto pelo seu id.                     *
   *                                                                                                                 *
   *     indiceCarrinhoItem: Esse campo indica o indice do produto dentro do carrinho. Servirá para atualizar a      *
   *              as informações do item no carrinho que foi incluido, alterado ou excluido                          *
   *                                                                                                                 *
   *     quantidade: Esse parametro serve para indicar a quantidade incluida ou alterada do item no carrinho.        *
   *              No caso do item ser excludo, então a quantidade virá negativa.                                     *
   *******************************************************************************************************************/
  onUpdateCart(carrinho, indiceItem, indiceCarrinhoItem, quantidade) {
    this.setState({
      vitrine: atualizaVitrine(
        this.state.vitrine,
        carrinho,
        indiceItem,
        indiceCarrinhoItem,
        quantidade
      ),
    });

    if (
      carrinho.entrega !== undefined &&
      carrinho.entrega !== null &&
      carrinho.entrega.id !== 1 &&
      carrinho.entrega.endereco !== undefined &&
      carrinho.entrega.endereco !== null &&
      carrinho.entrega.endereco.cep !== undefined &&
      carrinho.entrega.endereco.cep !== null
    ) {
      let endereco = {};

      endereco.destinatario = carrinho.entrega.destinatario;

      endereco.endereco = {};

      endereco.endereco.cep = carrinho.entrega.endereco.cep;
      endereco.endereco.logradouro = carrinho.entrega.endereco.logradouro;
      endereco.endereco.endereco = carrinho.entrega.endereco.endereco;
      endereco.endereco.numero = carrinho.entrega.endereco.numero;
      endereco.endereco.complemento = carrinho.entrega.endereco.complemento;
      endereco.endereco.bairro = carrinho.entrega.endereco.bairro;
      endereco.endereco.cidade = carrinho.entrega.endereco.cidade;
      endereco.endereco.uf = carrinho.entrega.endereco.uf;
      endereco.endereco.codigo_municipio =
        carrinho.entrega.endereco.codigo_municipio;

      endereco.tipo_endereco = carrinho.entrega.tipo_endereco;
      endereco.ponto_de_interesse = carrinho.entrega.ponto_de_interesse;
      endereco.ponto_de_interesse_manual =
        carrinho.entrega.ponto_de_interesse_manual;
      endereco.ponto_de_interesse_descricao =
        carrinho.entrega.ponto_de_interesse_descricao;

      this.onUpdateOpcaoEntrega(carrinho);

      this.onUpdateEnderecoEntrega(endereco, undefined);
    }

    this.setState({ carrinho: carrinho });
    this.clearPagamento();
  }

  onUpdateEnderecoEntrega(endereco, clienteEndereco) {
    var cliente = this.state.cliente;

    if (clienteEndereco !== undefined && clienteEndereco !== null)
      cliente.cliente_endereco = clienteEndereco;

    if (endereco !== undefined) {
      cliente.destinatario = endereco.destinatario;
      cliente.cep = endereco.endereco.cep;
      cliente.logradouro = endereco.endereco.logradouro;
      cliente.endereco = endereco.endereco.endereco;
      cliente.numero = endereco.endereco.numero;
      cliente.complemento = endereco.endereco.complemento;
      cliente.bairro = endereco.endereco.bairro;
      cliente.cidade = endereco.endereco.cidade;
      cliente.uf = endereco.endereco.uf;
      cliente.codigo_municipio = endereco.endereco.codigo_municipio;

      cliente.tipo_endereco = endereco.tipo_endereco;
      cliente.ponto_de_interesse = endereco.ponto_de_interesse;
      cliente.ponto_de_interesse_manual = endereco.ponto_de_interesse_manual;
      cliente.ponto_de_interesse_descricao =
        endereco.ponto_de_interesse_descricao;
    }

    this.setState({ cliente: cliente });
  }

  onUpdatePagamento(
    id,
    descricao,
    troco,
    opcaoPagamento,
    permite_parcelamento
  ) {
    let pagamento = this.state.pagamento;

    pagamento.id = id;

    if (descricao) {
      pagamento.descricao = opcaoPagamento + " (" + descricao + ")";
    }

    pagamento.valorTroco = troco;

    let modalidade = pagamento.modalidade;

    modalidade = ModalidadePagamentoEnum.get(descricao);

    pagamento.modalidade = modalidade;
    pagamento.permite_parcelamento = permite_parcelamento;

    this.setState({
      pagamento: pagamento,
    });
  }

  getIndiceOpcaoEntrega(carrinho) {
    let opcaoEntrega = this.state.opcaoEntrega;
    let indice = -1;

    opcaoEntrega.map((item, index) => {
      if (item.id === carrinho.entrega.id) {
        indice = index;

        return;
      }
    });

    return indice;
  }

  clearPagamento() {
    let pagamento = this.state.pagamento;

    pagamento.id = 0;
    pagamento.descricao = "";
    pagamento.valorTroco = 0;
    pagamento.parcelamento = 0;

    let modalidade = {};

    modalidade.opcaoPagamento = "";

    pagamento.modalidade = modalidade;

    this.setState({ pagamento: pagamento });
  }

  onUpdateOpcaoEntrega(carrinho) {
    if (this.state.opcaoEntrega.length === 0) return;

    let index = this.getIndiceOpcaoEntrega(carrinho);
    let opcaoEntrega = this.state.opcaoEntrega;

    if (index > -1) {        
        let receberEmCasa = opcaoEntrega[index];

        receberEmCasa.valor_pedido_minimo = carrinho.entrega.valor_pedido_minimo;

        opcaoEntrega.splice(index, 1);

        opcaoEntrega.push(receberEmCasa);
    }

    this.clearPagamento();

    this.setState({ opcaoEntrega: opcaoEntrega });
  }

  onUpdateBadgeNotificacao() {
    var badge = this.state.badgeNavBottom;

    badge.notificacao -= 1;

    if (badge.notificacao < 0) {
      badge.notificacao = 0;
    }

    this.setState({ badgeNavBottom: badge });
  }

  onUpdateBadgeFavorito(tipo, value) {
    var badge = this.state.badgeNavBottom;

    if (tipo === 1) {
      badge.favorito += 1;
    } else {
      badge.favorito -= 1;
    }

    if (value) {
      badge.favorito = 0;
    }

    if (badge.favorito < 0) {
      badge.favorito = 0;
    }

    this.setState({ badgeNavBottom: badge });
  }

  changeQuantidade(item, quantidade) {
    let vitrine = this.state.vitrine;
    let indice = vitrine.indexOf(item);

    if (
      indice > -1 &&
      item.tipo_calculo.toUpperCase() === TipoCalculoEnum.QUANTIDADE.enumName
    ) {
      if (vitrine[indice].carrinho_item_quantidade === undefined)
        vitrine[indice].carrinho_item_quantidade = item;

      vitrine[indice].carrinho_item_quantidade.quantidade = quantidade;
      this.setState({ vitrine: vitrine });
    }
  }

  showComponent() {
    if (!this.state.empresaValida)
      return <NaoEncontrado erro={this.state.textoAlerta} showButton={false} />;

    return (
      <Fragment>
        <PushNotification />
        <NavHeader
          inPreload={
            this.state.preloadEmpresa ||
            this.state.preloadCliente ||
            this.state.preloadBadge ||
            this.state.preloadFormPagto ||
            this.state.preloadProduto ||
            this.state.preloadVitrine ||
            this.state.preloadOpcaoEntrega ||
            this.state.preloadConfiguracao
          }
          preloadCliente={this.state.preloadCliente}
          titulo={this.state.titulo}
          escondeLupa={this.state.escondeLupa}
          cliente={this.state.cliente}
          empresa={this.state.empresa}
          carrinho={this.state.carrinho}
          vitrine={this.state.vitrine}
          badge={this.state.badgeNavBottom}
          filtros={this.filtros}
          route={(item) => this.browserRoute.history.push(item.link)}
          search={() => this.loadVitrine(false, true)}
          onApplyFiltro={(filtros) => {
            this.filtros = filtros;
            this.loadVitrine(false, true);
          }}
        />

        <ModalAlerta
          show={this.state.erro !== ""}
          texto={this.state.erro}
          fechar={() => this.setState({ erro: "" })}
        />

        <main className="main-layout">
          {/* <Message
                    /> */}

          <Preload exibir={this.state.preloadCargaInicial} />

          <Offline />

          <div id="online">
            {this.state.usaCheckout !== undefined &&
                <BrowserRouter ref={(ref) => (this.browserRoute = ref)}>
                <Switch>
                    <Route
                    exact
                    path={Config.urlBase}
                    render={(props) => (
                        <Shopping
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onPressAcessarProdsCampanha={(imagemRef) => {
                            this.acessarProdsCampanha(imagemRef);
                        }}
                        cidadesDisponiveis = {this.state.codigosCidadesDisponiveis}
                        campanhaProdutos={this.state.campanhaProdutos}
                        campanha={this.state.campanha}
                        vitrinePreload={this.state.vitrinePreload}
                        refreshCart={this.state.refreshCart}
                        carrinho={this.state.carrinho}
                        pagamento={this.state.pagamento}
                        cliente={this.state.cliente}
                        empresa={this.state.empresa}
                        modalidadePagamento={this.state.modalidadePagamento}
                        vitrine={this.state.vitrine}
                        opcaoEntrega={this.state.opcaoEntrega}
                        searchKey={this.state.searchKey}
                        tipoCalculoFrete={this.state.tipoCalculoFrete}
                        usaCheckout={this.state.usaCheckout}
                        exibirRodape={this.state.exibirRodape}
                        notificacaoModal={this.state.notificacaoModal}
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        fetchMoreData={() => this.loadVitrine(true, false)}
                        onUpdateCart={(
                            carrinho,
                            indiceItem,
                            indiceCarrinhoItem,
                            quantidade
                        ) =>
                            this.onUpdateCart(
                            carrinho,
                            indiceItem,
                            indiceCarrinhoItem,
                            quantidade
                            )
                        }
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
                            this.onUpdateEnderecoEntrega(endereco, clienteEndereco)
                        }
                        deleteEnderecoEntrega={(id) =>
                            this.deleteEnderecoEntrega(id)
                        }
                        onUpdateVitrine={(index) => this.onUpdateVitrine(index)}
                        onUpdateBadgeFavorito={(tipo, index) => {
                            this.onUpdateVitrine(index);
                            this.onUpdateBadgeFavorito(tipo, false);
                        }}
                        onUpdatePagamento={(
                            id,
                            descricao,
                            troco,
                            opcaoPagamento,
                            permite_parcelamento
                        ) =>
                            this.onUpdatePagamento(
                            id,
                            descricao,
                            troco,
                            opcaoPagamento,
                            permite_parcelamento
                            )
                        }
                        fecharNotificacaoModal={() =>
                            this.setState({ notificacaoModal: false })
                        }
                        changeQuantidade={(item, quantidade) =>
                            this.changeQuantidade(item, quantidade)
                        }
                        route={(item) =>
                          this.browserRoute.history.push(item.link)
                        }                   
                        />
                    )}
                    />
                
                    {
                        this.state.usaCheckout &&
                        <Route
                        exact
                        path={Config.urlBase + "checkout"}
                        render={(props) => (
                            <Checkout
                            {...props}
                            inPreload={
                                this.state.preloadEmpresa ||
                                this.state.preloadCliente ||
                                this.state.preloadBadge ||
                                this.state.preloadFormPagto ||
                                this.state.preloadProduto ||
                                this.state.preloadVitrine ||
                                this.state.preloadOpcaoEntrega ||
                                this.state.preloadConfiguracao
                            }
                            cliente={this.state.cliente}
                            empresa={this.state.empresa}
                            cidadesDisponiveis = {this.state.codigosCidadesDisponiveis}
                            opcaoEntrega = {this.state.opcaoEntrega}
                            carrinho={this.state.carrinho}
                            modalidadePagamento={this.state.modalidadePagamento}
                            tipoCalculoFrete={this.state.tipoCalculoFrete}                            

                            onTitulo={(titulo) => this.onTitulo(titulo)}
                            route={(item) =>
                                this.browserRoute.history.push(item.link)
                            }
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
                                this.onUpdateEnderecoEntrega(endereco, clienteEndereco)
                            }
                            onUpdatePagamento={(
                                id,
                                descricao,
                                troco,
                                opcaoPagamento,
                                permite_parcelamento
                            ) =>
                                this.onUpdatePagamento(
                                id,
                                descricao,
                                troco,
                                opcaoPagamento,
                                permite_parcelamento
                                )
                            }
                            />
                        )}
                        />  
                    }              

                    <Route
                    exact
                    path={Config.urlBase + "notificacao"}
                    render={(props) => (
                        <Notificacao
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        onUpdateNotificacao={() =>
                            this.onUpdateBadgeNotificacao()
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "favorito"}
                    render={(props) => (
                        <Favorito
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        carrinho={this.state.carrinho}
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        onUpdateCart={(
                            carrinho,
                            indiceItem,
                            indiceCarrinhoItem,
                            quantidade
                        ) =>
                            this.onUpdateCart(
                            carrinho,
                            indiceItem,
                            indiceCarrinhoItem,
                            quantidade
                            )
                        }
                        onUpdateBadgeFavorito={(value) =>
                            this.onUpdateBadgeFavorito(0, value)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "taxa-entrega"}
                    render={(props) => (
                        <TaxaEntrega
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        carrinho={this.state.carrinho}
                        cliente={this.state.cliente}
                        empresa={this.state.empresa}
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "login"}
                    render={(props) => (
                        <Login
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        cidadesDisponiveis = {this.state.codigosCidadesDisponiveis}
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        empresa={this.state.empresa}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />
                    <Route
                    exact
                    path={Config.urlBase + "recuperar-senha"}
                    render={(props) => (
                        <RecuperarSenha
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "recuperar-senha/esqueci-meu-email"}
                    render={(props) => (
                        <EsqueciMeuEmail
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "redefinir-senha"}
                    render={(props) => (
                        <RedefinirSenha
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "perfil"}
                    render={(props) => (
                        <Perfil
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        empresa={this.state.empresa}
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />
                                    <Route
                    exact
                    path={Config.urlBase + "excluir"}
                    render={(props) => (
                        <ExcluirConta
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        empresa={this.state.empresa}
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "senha"}
                    render={(props) => (
                        <Senha
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "login/recuperar-senha"}
                    render={(props) => (
                        <RecuperarSenha
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={
                        Config.urlBase + "login/recuperar-senha/esqueci-meu-email"
                    }
                    render={(props) => (
                        <EsqueciMeuEmail
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "configuracao/notificacao"}
                    render={(props) => (
                        <NotificacaoConfiguracao
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "enderecos"}
                    render={(props) => (
                        <EnderecoLista
                            {...props}
                            inPreload={
                                this.state.preloadEmpresa ||
                                this.state.preloadCliente ||
                                this.state.preloadBadge ||
                                this.state.preloadFormPagto ||
                                this.state.preloadProduto ||
                                this.state.preloadVitrine ||
                                this.state.preloadOpcaoEntrega ||
                                this.state.preloadConfiguracao
                            }
                            cidadesDisponiveis = {this.state.codigosCidadesDisponiveis}
                            onTitulo={(titulo) => this.onTitulo(titulo)}
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
                                this.onUpdateEnderecoEntrega(endereco, clienteEndereco)
                            }
                            modalidadePagamento={this.state.modalidadePagamento}
                            opcaoEntrega={this.state.opcaoEntrega}
                            carrinho={this.state.carrinho}
                            escolhaEntrega={false}

                            empresa={this.state.empresa}
                            route={(item) =>
                                this.browserRoute.history.push(item.link)
                            }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "sobre"}
                    render={(props) => (
                        <Sobre
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "pedidos"}
                    render={(props) => (
                        <Pedido
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "politica-de-privacidade"}
                    render={(props) => (
                        <Politica
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        empresa={this.state.empresa}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "cupons"}
                    render={(props) => (
                        <Cupons
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        empresa={this.state.empresa}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "termos-de-uso"}
                    render={(props) => (
                        <Termos
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        empresa={this.state.empresa}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />

                    <Route
                    exact
                    path={Config.urlBase + "filtro/departamento"}
                    render={(props) => (
                        <CategoriaDesktop
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        onTitulo={(titulo) => this.onTitulo(titulo)}
                        categoria="departamento"
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                    )}
                    />


                    <Route
                    path={Config.urlBase + "*"}
                    render={(props) => (
                        <NaoEncontrado
                        {...props}
                        inPreload={
                            this.state.preloadEmpresa ||
                            this.state.preloadCliente ||
                            this.state.preloadBadge ||
                            this.state.preloadFormPagto ||
                            this.state.preloadProduto ||
                            this.state.preloadVitrine ||
                            this.state.preloadOpcaoEntrega ||
                            this.state.preloadConfiguracao
                        }
                        erro={this.state.textoAlerta}
                        onVisible={() => this.onVisible()}
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                        />
                        
                    )}
                    />
                </Switch>
                </BrowserRouter>
            }

            <NavBottom
              inPreload={
                this.state.preloadEmpresa ||
                this.state.preloadCliente ||
                this.state.preloadBadge ||
                this.state.preloadFormPagto ||
                this.state.preloadProduto ||
                this.state.preloadVitrine ||
                this.state.preloadOpcaoEntrega ||
                this.state.preloadConfiguracao
              }
              preloadCliente={this.state.preloadCliente}
              badge={this.state.badgeNavBottom}
              cliente={this.state.cliente}
              filtros={this.filtros}
              empresa={this.state.empresa}
              route={(item) => this.browserRoute.history.push(item.link)}
              onApplyFiltro={(filtros) => {
                this.filtros = filtros;
                this.loadVitrine(false, true);
              }}
            />
          </div>

          <Footer />

          <div className="address-hint-container"></div>
        </main>
      </Fragment>
    );
  }

  render() {    
    return <Fragment>{this.showComponent()}</Fragment>;
  }
}

export default App;
