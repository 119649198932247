import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import ModalEnderecoLista from '../cliente/ModalEnderecoLista';

import TipoEnderecoEnum from '../../helper/enumerador/TipoEnderecoEnum';
import { formataEndereco } from '../../helper/Formatar';

import Config from "../../Config"
import Button from '../layout/button/Button';

class CheckoutEndereco extends AbstractComponent {
    constructor(props) {
        super(props);        

        this.state = {
            showEnderecoModal: false,

            userLogged: true,              
                                
            body: [],

            endereco: false,

            active: -1,

            item: [],
        }

    }    

    showEnderecoButton() {
        if (this.props.opcaoEntrega.length > 1) {
            return ;
        }

        return (
            <div style={{ borderTop: '#dcdcdc solid 1px', padding: '10px', marginTop: '25px' }}>
                <div style={{ margin: '15px 0px 10px 0px' }}>
                    Quer receber seu pedido em outro endereço?
                </div>

                <Button
                    type="button"                        
                    className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"  
                    onClick={() => this.setState({showEnderecoModal: true})}
                >                    
                    USAR OUTRO ENDEREÇO
                </Button>
            </div>
        );
    }

    showEndereco() {
        if (this.props.cliente.tipo_endereco === TipoEnderecoEnum.POI.name) {
            return;
        }
    
        return (
            <Fragment>
                <ModalEnderecoLista
                    cidadesDisponiveis = {this.props.cidadesDisponiveis}
                    empresa={this.props.empresa}
                    carrinho={this.props.carrinho}
                    
                    show={this.state.showEnderecoModal}
                    title={"LISTA DE ENDEREÇOS" }                                    

                    fechar={() => this.setState({showEnderecoModal: false})}
                    onUpdateEnderecoEntrega={(endereco, clienteEndereco) => {
                        this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco);
                        this.setState({showEnderecoModal: false})
                    }}
                    onUpdateCart={(carrinho) => this.props.onUpdateCart(carrinho)}
                />

                <div className="AddressBox-address">
                    {
                        this.props.carrinho.entrega.endereco.cep + " - " +
                        formataEndereco(
                            this.props.carrinho.entrega.endereco.logradouro,
                            this.props.carrinho.entrega.endereco.endereco,
                            false
                        ) +
                        ", " +
                        this.props.carrinho.entrega.endereco.numero}
                </div>
    
                <div className="AddressBox-address">
                    {
                        (   
                            this.props.carrinho.entrega.endereco.complemento !== undefined &&
                            this.props.carrinho.entrega.endereco.complemento !== ""
                                ? this.props.carrinho.entrega.endereco.complemento + " - "
                                : ""
                        ) +
                        this.props.carrinho.entrega.endereco.bairro +
                        " - " +
                        this.props.carrinho.entrega.endereco.cidade +
                        " " +
                        this.props.carrinho.entrega.endereco.uf
                    }
                </div>
    
                <div className="AddressBox-zipcode"></div>
            </Fragment>
        );
    }

    height() {
        return this.props.opcaoEntrega.length > 1
            ? '234px'
            : '285px'
    }

    render() {      
        return (
            <div style={{ height: this.height(), padding: '5px' }} className="checkout-content-painel-childre-endereco">
                <h4 style={{ marginBottom: '3rem'}}>
                    <img src={Config.urlImgBase + "home.svg"} alt="Frete" style={{width: '25'}}/>
                    &nbsp;
                    &nbsp;
                    <strong>Endereço de entrega</strong>
                </h4>

                <div
                    className="col-xs-12 
                                col-sm-12 
                                col-md-12 
                                col-lg-12
                                "
                >
                    <div
                        className="col-md-12                                 
                                                col-sm-12                                                
                                                col-lg-12                                                
                                                col-xs-12"
                    >
                        <div className="AddressBox-title">
                            <b>{this.props.carrinho.entrega.destinatario}</b>
                        </div>

                        {this.showEndereco()} 

                        {this.showEnderecoButton()}                           
                    </div>
                </div>            
            </div>
        )
    }
}

export default CheckoutEndereco;
