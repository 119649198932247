
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class EmpresaFreteService {        
    static async listar(idCarrinho) {          
        return Requisicao.post(Config.urlBaseApi + 'empresa/frete/listar', {            
            idCarrinho: idCarrinho            
        });
    }    

    static async listarPontoDeInteresse(tipoEndereco) {        
        return Requisicao.get(Config.urlBaseApi + 'empresa/frete/ponto-interesse/' + tipoEndereco);
    } 

    static async pontoDeInteresseExists(tipoEndereco, bairro) {        
        return Requisicao.get(Config.urlBaseApi + 'empresa/frete/ponto-interesse-bairro/' + tipoEndereco + '/' + bairro);
    } 
}

export default EmpresaFreteService;