import AtivoEnum from "../../helper/enumerador/AtivoEnum";
import AbstractComponent from "../AbstractComponent";
import Content from "../layout/content/Content";
import CheckoutContent from "./CheckoutContent";
import ModalidadePagamentoEnum from "../../helper/enumerador/ModalidadePagamentoEnum";

import AutenticarService from "../../service/AutenticarService";
import CarrinhoService from "../../service/CarrinhoService";
import CorreiosService from "../../service/CorreiosService";
import TipoCalculoFreteEnum from "../../helper/enumerador/TipoCalculoFreteEnum";
import Preload from "../layout/preload/Preload";

class Checkout extends AbstractComponent {
    constructor(props) {
      super(props);
  
      this.state = {
            preloadCarrinho: true,            

            preloadFrete: false,            

            preloadFreteReload: false,
        
            carrinho: {
                item: {},
        
                carrinho_item_quantidade: [],
        
                carrinho_item_peso: [],
        
                entrega: {
                    frete: {},
                },                
        
                totalizador: {
                  sub_total: 0,
                  desconto: 0,
                  taxa_entrega: 0,
                  total: 0,
                },
            }, 

            pagamento: {
                id: 0,
                descricao: "",
                valorTroco: 0,
                modalidade: {
                  opcaoPagamento: "",
                },
                parcelamento: 0,
                permite_parcelamento: AtivoEnum.NAO,
                entrega: {
                    id: 0,
                    pergunta_forma_pagamento: AtivoEnum.NAO,
                }
            },

            fretes: []
      };
    }    

    onUpdateEnderecoEntrega(endereco, clienteEndereco) {
      let carrinho = this.state.carrinho;
      let entrega = carrinho.entrega;

      entrega.endereco.cep = endereco.endereco.cep;
      entrega.endereco.logradouro = endereco.endereco.logradouro;
      entrega.endereco.endereco = endereco.endereco.endereco;
      entrega.endereco.numero = endereco.endereco.numero;
      entrega.endereco.complemento = endereco.endereco.complemento;
      entrega.endereco.bairro = endereco.endereco.bairro;
      entrega.endereco.cidade = endereco.endereco.cidade;
      entrega.endereco.uf = endereco.endereco.uf;
      entrega.endereco.codigo_municipio = endereco.endereco.codigo_municipio;
      entrega.destinatario = endereco.destinatario;
      entrega.tipo_endereco = endereco.tipo_endereco;
      entrega.codigo_municipio = endereco.endereco.codigo_municipio;
      entrega.ponto_de_interesse = endereco.ponto_de_interesse;
      entrega.ponto_de_interesse_manual = endereco.ponto_de_interesse_manual;
      entrega.ponto_de_interesse_descricao = endereco.ponto_de_interesse_descricao;

      carrinho.entrega = entrega;

      this.setState({ carrinho: carrinho });                  
      this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco);
      this.refreshFrete(carrinho, true);
    }

    onUpdatePagamento(
        id,
        descricao,
        troco,
        opcaoPagamento,
        permite_parcelamento
      ) {
        let pagamento = this.state.pagamento;
    
        pagamento.id = id;
    
        if (descricao) {
          pagamento.descricao = opcaoPagamento + " (" + descricao + ")";
        }
    
        pagamento.valorTroco = troco;
    
        let modalidade = pagamento.modalidade;
    
        modalidade = ModalidadePagamentoEnum.get(descricao);
    
        pagamento.modalidade = modalidade;
        pagamento.permite_parcelamento = permite_parcelamento;
    
        this.setState({
          pagamento: pagamento,
        });
    }   
    
    refreshCart() {
        CarrinhoService.carrinho(
            AutenticarService.getDocumentoCliente(),
            AutenticarService.getIdCarrinho()
        ).then((response) => {
            this.setState({
                preloadCarrinho: false,                
                carrinho: response.data,                
            });
            
            this.refreshFrete(response.data, false)
        }).catch(() => {          
            this.setState({
                preloadCarrinho: false,
                preloadFrete: false,
                preloadFreteReload: false,                
            });
        });
    }    

    refreshFrete(carrinho, preloadFreteReload) {
        if (this.props.tipoCalculoFrete === TipoCalculoFreteEnum.TRANSPORTADORA.name) {
            if (preloadFreteReload) {
                this.setState({ preloadFreteReload: true })
            } else {
                this.setState({ preloadFrete: true });
            }

            CorreiosService.cotacao(
                AutenticarService.getDocumentoCliente(),           
                AutenticarService.getIdCarrinho(),
                carrinho.entrega.endereco.cep
            ).then((response) => {
                this.setState({
                    fretes: response.data,
                    preloadFrete: false,
                    preloadFreteReload: false,
                });
            }).catch((error) => {
                this.setState({ preloadFrete: false, preloadFreteReload: false })
            })
        }
    }

    componentDidMount() {  
        this.onTitulo("Checkout");
        this.refreshCart()
    }

    render() {
        return (
            <div>
                <Preload exibir={this.state.preloadFreteReload} />

                <Content                    
                    preload={this.props.inPreload || this.state.preloadCarrinho || this.state.preloadFrete}
                    showSummary={false}
                    modalErro={this.state.erro}
                    onCloseAlerta={() => this.setState({ erro: '' })}

                    component={(props) => {
                        return <CheckoutContent
                            {...props}
                            route={(item) => this.props.route(item)}                            
                            
                            cliente={this.props.cliente}
                            empresa={this.props.empresa}
                            cidadesDisponiveis = {this.props.cidadesDisponiveis}
                            opcaoEntrega = {this.props.opcaoEntrega}
                            carrinho={this.state.carrinho}
                            pagamento={this.state.pagamento}
                            modalidadePagamento={this.props.modalidadePagamento} 
                            tipoCalculoFrete={this.props.tipoCalculoFrete}
                            fretes={this.state.fretes}

                            showPreload={(value) => this.setState({ preload: value })}
                            showErro={(value) => this.setState({ erro: value })}

                            inPreload={preload => this.setState({ preloadFrete: preload} )}

                            onUpdateCart={cart => this.setState({carrinho: cart})}
                            onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                            onUpdatePagamento={(
                                id,
                                descricao,
                                troco,
                                opcaoPagamento,
                                permite_parcelamento
                            ) =>
                                this.onUpdatePagamento(
                                    id,
                                    descricao,
                                    troco,
                                    opcaoPagamento,
                                    permite_parcelamento
                                )
                            }
                            refreshCart={() => this.refreshCart()}
                        />
                    }}
                />
            </div>
        )
    }
}

export default Checkout
