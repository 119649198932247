import React from 'react';
import AbstractComponent from '../../AbstractComponent';

class Summary extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    show() {
        return this.props.show === undefined
            ? 'display-none' 
            : this.props.show
                ? ''
                : 'display-none'; 
    }
    
    visible() {
        return this.state.visible ? '' : 'display-none';
    }

    handleScroll() { 
        this.setState({ visible: false });

        if (document.documentElement.scrollTop > 70) 
            this.setState({ visible: true });
    }
     
    componentDidMount() {
        window.onscroll = () => this.handleScroll()
    }

    onClick() {        
        var el = document.querySelector('html, body');        

        el.animate({scrollTop : 0}, 800);
    }

    render() {        
        return (
            <div 
                className={"row borderColor backgroundHead " + this.show()}
                style={{
                    borderBottom: 'solid 1px',                                                    
                    height: '25px',
                    zIndex: '1039',
                    position: 'fixed',
                    backgroundColor: '#eee',
                    opacity: 1,
                    width: '100%',
                    bottom: '49px'
                }}
            >                    
                <div 
                    className="col-md-11 
                                col-sm-11
                                col-lg-11
                                col-xs-11"
                    style={{                             
                        marginTop: '3px',
                        marginBottom: '3px',
                    }}
                >                                                              
                    {this.props.texto}
                </div>

                <div 
                    className={"col-md-1 col-sm-1 col-lg-1 col-xs-1 " + this.visible() }
                    style={{
                        padding: '0px',                            
                    }}
                >
                    <a                         
                        onClick={() => this.onClick()}
                    >
                        <i 
                            className="pe-7s-angle-up-circle"
                            style={{
                                fontSize: '25px',
                                color: '#000000'
                            }}
                        />
                    </a>
                </div>                    
            </div> 
        )
    }
}

export default Summary;