import { Grid } from "@material-ui/core";
import AbstractComponent from "../AbstractComponent";
import Button from "../layout/button/Button";
import ModalAlerta from "../layout/modal/ModalAlerta";
import ModalWarning from "../layout/modal/ModalWarning";
import ShoppingSuccess from "../shopping/ShoppingSuccess";
import ModalPagamento from "../shopping/fechamento/ModalPagamento";

import OpcaoPagamentoEnum from "../../helper/enumerador/OpcaoPagamentoEnum";
import TemProdutoPesavelEnum from "../../helper/enumerador/TemProdutoPesavelEnum";
import TipoCalculoFreteEnum from "../../helper/enumerador/TipoCalculoFreteEnum";

import { gerarPedido, validarPagamento } from "../../helper/Pagamento";
import { buscaConfiguracao } from "../../helper/Validar";

class CheckoutPagamento extends AbstractComponent {

    constructor(props) {
        super(props);

        this.state = {
            preload: false,
            success: false,
            modalPagamento: false,
            modalProdPesavel: false,
            modalAlertaFinal: false,           
            idPedido: 0,
            erro: [],
            atualizeForm: false,
            atualizaCarrinho: false,            
            step: 0,
        }

        let produtoPesavel = [];

        produtoPesavel.push("Existem produtos pesáveis no carrinho.");
        produtoPesavel.push(
            "O valor total do pedido pode mudar de acordo com a pesagem e/ou disponibilidade de estoque!"
        );

        this.alertaProdutoPesavel = produtoPesavel;
    }

    validar() {
        this.setState({ erro: [] });

        let msgErro = validarPagamento(
            this.props.carrinho, 
            this.props.pagamento, 
            this.props.tipoCalculoFrete === TipoCalculoFreteEnum.TRANSPORTADORA.name
        );

        this.setState({ erro: msgErro });

        return msgErro.length === 0
    }

    validaForm(e, cartao) {
        if (!this.autenticar()) {
            return ;
        }
    
        if (!this.validar()) {
            return ;
        }        

        if (this.props.carrinho.tem_produto_pesavel === TemProdutoPesavelEnum.SIM) {
            this.setState({ modalProdPesavel: true, textoAlerta: this.alertaProdutoPesavel })
            return ;
        } 
        
        this.setState({ step: 1});
        this.onSubmit(e, cartao, 1);
    }

    showModalPagamento(e, cartao) {
        if (this.props.pagamento.modalidade.opcaoPagamento === OpcaoPagamentoEnum.PAGO_NO_SITE) {
            this.setState({ modalPagamento: true });    
            return;
        }

        this.setState({ step: 2});
        this.onSubmit(e, cartao, 2);
    }

    showMensagemFinal(e, cartao) {
        let textoAlerta = buscaConfiguracao(
            this.props.empresa.configuracao,
            "Pedido",
            "Mensagem_final",
            ""
        );

        let step = 3;

        if (textoAlerta !== '') {
            step = 2;
            this.setState({ modalAlertaFinal: true, textoAlerta: textoAlerta, step: step });
        } else {
            this.setState({ step: step });
            this.onSubmit(e, cartao, step);
        }
    }

    finalizarPedido(cartao) {
        this.setState({
            preload: true,            
        });
    
        gerarPedido(
            this.props.cliente, 
            this.props.pagamento, 
            this.props.carrinho, 
            cartao
        ).then((response) => {
            this.setState({
                preload: false,
                success: true,
                modalPagamento: false,
                idPedido: response.data,
            });
        }).catch((erro) => {
            let msgErro = this.state.erro;
            let atualizeForm = false;
            let atualizaCarrinho = false;
    
            if (erro.response.status === 402) {
                atualizeForm = true;
            }
    
            if (erro.response.status === 403) {
                atualizaCarrinho = true;
            }
    
            msgErro.push("Por favor, corrija os problemas abaixo:");
            msgErro.push(this.trataErro(erro));
    
            this.setState({
                preload: false,
                erro: msgErro,
                atualizeForm: atualizeForm,
                atualizaCarrinho: atualizaCarrinho,
            });
        });
    }

    onSubmit(e, cartao, step) {
        if (e !== undefined) {
            e.preventDefault();
        }

        switch (step) {
            case 0:
                this.validaForm(e, cartao);
                break;
            case 1:
                this.showModalPagamento(e, cartao);
                break;
            case 2:
                this.showMensagemFinal(e, cartao);
                break
            case 3:
                this.finalizarPedido(cartao);
                break;
        }
    }

    onPagamentoOk(cartao) {            
        this.setState({ step: 2 });    
        this.onSubmit(undefined, cartao, 2);
    }

    nextStep(e) {
        var step = this.state.step+1;

        this.setState({ 
            modalProdPesavel: false,
            modalAlertaFinal: false,
            textoAlerta: '',
            modalPagamento: false,
            step: step        
        });

        this.onSubmit(e, undefined, step);
    }

    fecharModalAlerta() {           
        if (this.state.atualizeForm || this.state.atualizaCarrinho) {
            this.props.refreshCart();
        }
    
        this.setState({
            erro: [],
            atualizeForm: false,
            atualizaCarrinho: false,
        });
    }

    voltarHome() {        
        this.homePage2()
    }
    
    fechar() {
        this.homePage2();
    
        setTimeout(function () {
          this.setState({ success: false });
        }, 5000);
    }

    pegaMensagem() {    
        let mensagemFinal = buscaConfiguracao(
            this.props.empresa.configuracao,
            "Pedido",
            "Mensagem_final",
            ""
        );
    
        return mensagemFinal;
    }

    render() {
        return (
            <>
                <ModalAlerta
                    styleText="ml-3"
                    header={"Erro de validação"}
                    show={this.state.erro.length > 0}
                    texto={this.state.erro}
                    fechar={() => this.fecharModalAlerta()}
                />

                <ModalWarning
                    styleText="ml-3"
                    header={"Atenção"}
                    show={this.state.modalProdPesavel || this.state.modalAlertaFinal}
                    texto={this.state.textoAlerta}
                    fechar={(e) => this.nextStep(e)}
                />

                <ShoppingSuccess
                    show={this.state.success}
                    idPedido={this.state.idPedido}
                    empresa={this.props.empresa}
                    fechar={() => this.fechar()}
                    route={(route) => this.props.route(route)}
                />

                <ModalPagamento
                    show={this.state.modalPagamento}
                    carrinho={this.props.carrinho}
                    pagamento={this.props.pagamento}
                    empresa={this.props.empresa}
                    preload={this.state.preload}
                    fechar={() => this.setState({ step: 0, modalPagamento: false })}
                    onPagamentoOk={(cartao) => this.onPagamentoOk(cartao)}
                />

                <div style={{ height: '236px', padding: '5px', alignContent: 'center' }}>
                    <Grid container>
                        <Grid item 
                            xs={12} 
                            style={{ 
                                marginBottom: '1rem', 
                                fontWeight: this.props.fontWeight, 
                                borderTop: this.props.borderTop 
                            }}
                        >
                            <Button 
                                type="button"                        
                                className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
                                preload={this.state.preload && !this.state.modalPagamento}
                                disabled={this.state.preload && !this.state.modalPagamento}
                                onClick={(e) => this.onSubmit(e, undefined, this.state.step)}                
                            >
                                FINALIZAR PEDIDO
                            </Button>
                        </Grid>

                        <Grid item 
                            xs={12} 
                            style={{ 
                                marginBottom: '1rem', 
                                fontWeight: this.props.fontWeight, 
                                borderTop: this.props.borderTop 
                            }}
                        >
                            <button 
                                type="button"
                                className="btn btn--default btn--size-m btn--full-width area-cart-footer__button"
                                style={{
                                    background: '#fff',
                                    color: 'red',
                                    border: '1px solid red',
                                    fontSize: '18px',
                                    lineHeight: '18px',                            
                                }}
                                disabled={this.state.preload && !this.state.modalPagamento}
                                onClick={() => this.voltarHome()}                
                            >
                                CONTINUAR COMPRANDO
                            </button>
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}

export default CheckoutPagamento;
