
import Config from "../Config";
import Requisicao from "./RequisicaoService";

class ProdutoGrupoService {    
    static async getAll(pagina, limite, searchKey) {
        return Requisicao.post(Config.urlBaseApi + 'produto/grupo', {            
            pagina: pagina,
            limite: limite,
            searchKey: searchKey
        });
    }  
    
    static async autoComplete(search) {        
        return Requisicao.get(Config.urlBaseApi + 'produto/grupo/autocomplete/' + search);
    }
}

export default ProdutoGrupoService;