import OpcaoPagamentoEnum from './OpcaoPagamentoEnum';
import { retiraAcentos } from '../Formatar';

var modalidadePagamentoEnum = {
    DINHEIRO: {
        enumName: 'DINHEIRO',        
        name: function() {            
            return "Dinheiro";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    VISA_DEBITO: {
        enumName: 'VISA_DEBITO',        
        name: function() {            
            return "Visa - Débito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    MASTERCARD_DEBITO: {
        enumName: 'MASTERCARD_DEBITO',        
        name: function() {            
            return "Mastercard - Débito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    ELO_DEBITO: {
        enumName: 'ELO_DEBITO',        
        name: function() {            
            return "Elo - Débito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    VISA_CREDITO: {
        enumName: 'VISA_CREDITO',        
        name: function() {            
            return "Visa - Crédito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    MASTERCARD_CREDITO: {
        enumName: 'MASTERCARD_CREDITO',        
        name: function() {            
            return "Mastercard - Crédito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    ELO_CREDITO: {
        enumName: 'ELO_CREDITO',        
        name: function() {            
            return "Elo - Crédito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    DINERS_CREDITO: {
        enumName: 'DINERS_CREDITO',        
        name: function() {            
            return "Diners - Crédito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGAR_NA_ENTREGA        
    },
    CARTAO_DE_CREDITO: {
        enumName: 'CARTAO_DE_CREDITO',        
        name: function() {            
            return "Cartão de Crédito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGO_NO_SITE        
    },
    MERCADO_PAGO: {
        enumName: 'MERCADO_PAGO',        
        name: function() {            
            return "Mercado Pago";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGO_NO_SITE        
    },
    CARTAO_DE_DEBITO: {
        enumName: 'CARTAO_DE_DEBITO',
        name: function() {            
            return "Cartão de Débito";
        },        
        opcaoPagamento: OpcaoPagamentoEnum.PAGO_NO_SITE        
    },
    GETNET: {
      enumName: 'GETNET',        
      name: function() {            
          return "Getnet";
      },        
      opcaoPagamento: OpcaoPagamentoEnum.PAGO_NO_SITE        
    },
    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return modalidadePagamentoEnum.DINHEIRO;

        value = retiraAcentos(value);
        value = value.replaceAll(' - ', ' ');
        value = value.replaceAll(' ', '_');

        var array = Object.values(modalidadePagamentoEnum);
        var modalidadePagamentoFound = {};

        array.forEach(element => {            
            if(element.enumName === value) {
                modalidadePagamentoFound = element;      
                return ;          
            }            
        });   
        
        return modalidadePagamentoFound;
    }
}

export default modalidadePagamentoEnum;
