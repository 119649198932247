import React from "react";
import AbstractComponent from "../AbstractComponent";
import ModalDetail from "../layout/modal/ModalDetail";
import EnderecoCadastro from "./EnderecoCadastro";

import AutenticarService from "../../service/AutenticarService";
import EmpresaFreteService from "../../service/EmpresaFreteService";

import TipoEnderecoEnum from "../../helper/enumerador/TipoEnderecoEnum";

class ModalEndereco extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        id: 0,
        documentoCliente: AutenticarService.getDocumentoCliente(),
        destinatario: "",
        cep: "",
        cepOld: "",
        logradouro: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cidade: "",
        uf: "",
        tipo: "",
        codigo_municipio: "",
        padrao: 0,

        tipo_endereco: "Bairro",
        ponto_de_interesse: "",
        ponto_de_interesse_descricao: "",
        ponto_de_interesse_manual: "Nao",
      },

      erroForm: {
        destinatario: "",
        cep: "",
        logradouro: "",
        endereco: "",
        numero: "",
        bairro: "",
        cidade: "",
        uf: "",
        ponto_de_interesse: "",
        ponto_de_interesse_descricao: "",
      },

      forcaPontoInteresse: false,
    };

    if (props.form !== undefined) {
      this.state.form = props.form;
      this.state.form.cepOld = this.state.form.cep;
    }
  }

  onChange(e) {
    const form = this.state.form;
    form[e.target.name] = e.target.value;

    if (e.target.name === "ponto_de_interesse") {
      form["ponto_de_interesse_manual"] = "Sim";

      if (form["tipo_endereco"] === TipoEnderecoEnum.POI.name)
        form["codigo_municipio"] = this.props.empresa.codigo_municipio;
    }

    this.setState({ form: form });
  }

  onBlurBairro(pontoDeInteresse, forcaPontoInteresse) {
    const form = this.state.form;

    form["ponto_de_interesse"] = pontoDeInteresse;

    this.setState({
      form: form,
      forcaPontoInteresse: forcaPontoInteresse,
    });
  }

  onRadioTipoEnderecoClick(tipo_endereco) {
    const form = this.state.form;

    form.tipo_endereco = tipo_endereco;

    this.setState({ form: form });
  }

  preencheEndereco(endereco) {
    const form = this.state.form;

    form.cepOld = form.cep;
    form.logradouro = endereco["logradouro"];
    form.endereco = endereco["endereco"];
    form.bairro = endereco["bairro"];
    form.cidade = endereco["cidade"];
    form.uf = endereco["uf"];
    form.codigo_municipio = endereco["codigo_municipio"];
    form.numero = "";
    form.complemento = "";
    form.ponto_de_interesse = "";
    form.ponto_de_interesse_manual = "Nao";
    form.ponto_de_interesse_descricao = "";

    this.setState({
      form: form,
    });
  }

  onBlurCep(endereco) {
    this.preencheEndereco(endereco);

    EmpresaFreteService.pontoDeInteresseExists(
      this.state.form.tipo_endereco,
      endereco["bairro"]
    )
      .then(() => {
        const form = this.state.form;

        form.ponto_de_interesse = endereco["bairro"];

        this.setState({ form: form });

        if (this.state.form.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
          this.setState({ forcaPontoInteresse: false });
      })
      .catch(() => {
        this.setState({ forcaPontoInteresse: true });
      });
  }

  render() {
    return (
      <ModalDetail
        show={this.props.show}
        header={this.props.title}
        icon="down"
        fechar={() => this.props.fechar()}
      >
        
        <EnderecoCadastro
          cidadesDisponiveis={this.props.cidadesDisponiveis}
          form={this.state.form}
          empresa={this.props.empresa}
          erroForm={this.state.erroForm}
          forcaPontoInteresse={this.state.forcaPontoInteresse}
          onChange={(e) => this.onChange(e)}
          onRadioTipoEnderecoClick={(tipo_endereco) =>
            this.onRadioTipoEnderecoClick(tipo_endereco)
          }
          onBlurCep={(e) => this.onBlurCep(e)}
          onBlurBairro={(pontoDeInteresse, forcaPontoInteresse) =>
            this.onBlurBairro(pontoDeInteresse, forcaPontoInteresse)
          }
          onErro={(erro) => this.setState({ erroForm: erro })}
          onNew={() => this.props.fechar()}
          onUpdateEnderecoEntrega={(endereco, clienteEndereco) =>
            this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)
          }
        />
      </ModalDetail>
    );
  }
}

export default ModalEndereco;
