import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import CampanhaBannerService from "../../../service/CampanhaBannerService";
import CampanhaTile from "./CampanhaCarouselItem";

function VitrineCarousel(props) {
  const [imagemRef, setImagemRef] = React.useState(
    props.props.campanhaProdutos
  );
  const [carousel, setCarousel] = React.useState([]);
  const [campanhaCarousel, setCampanhaCarousel] = React.useState([]);
  const [imagensMobile, setImagensMobile] = React.useState([]);
  useEffect(() => {
    CampanhaBannerService.banners().then((response) => {
      setCarousel(response.data);
    });
  }, []);

  useEffect(() => {
    setCampanhaCarousel(carousel);
  }, [carousel]);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  useEffect(() => {
    setImagemRef(campanhaCarousel[activeIndex]);
  }, [activeIndex]);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === campanhaCarousel.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? campanhaCarousel.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };


  return (
    <>
      
      {campanhaCarousel.length > 0 && props.props.searchKey == "" && (
        <div className="vitrine_carousel">
          <div id="carousel">
            <Carousel 
            activeIndex={activeIndex}
             next={next} 
             previous={previous}
             interval={3500}
             >
              <CarouselIndicators
                items={campanhaCarousel}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {campanhaCarousel.map((item) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={item.imagem_desktop_Path}
                  >
                    <CampanhaTile
                      item={item}
                      onPressAcessarProdsCampanha={(imagemRef) => {
                        props.onPressAcessarProdsCampanha(imagemRef);
                      }}
                    />

                    <CarouselCaption captionText="" captionHeader="" />
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="fa fa-angle-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="fa fa-angle-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}

export default VitrineCarousel;
