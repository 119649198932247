import Config from '../Config';
import Requisicao from "./RequisicaoService";

class ClienteAparelhoService {
    static async incluir(uuidAparelho, documentoCliente, token) {        
        return Requisicao.post(Config.urlBaseApi + 'aparelho', {            
            uuidAparelho: uuidAparelho,
            documentoCliente: documentoCliente,
            token: token   
        });
    }      

    static async atribuirCliente(documentoCliente, uuidAparelho) {
        return Requisicao.post(Config.urlBaseApi + 'aparelho/atribuir-cliente', {            
            documentoCliente: documentoCliente,
            uuidAparelho: uuidAparelho
        });
    }
}

export default ClienteAparelhoService;