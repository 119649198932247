import ClienteService from '../../service/ClienteService';
import UsuarioService from '../../service/UsuarioService';

var TipoRedeSocialEnum = {
    FACEBOOK : {
        enumName: "FACEBOOK",        

        set: async function (documentoCliente, id, foto) {
            return ClienteService.setIdFacebook(                
                documentoCliente,
                id,
                foto
            );
        },        

        verificarRedeSocial: async function (idRedeSocial) {
            return ClienteService.verificarFacebook(                
                idRedeSocial
            );
        },    
        
        verificarCPFByRedeSocial: async function (documentoCliente, idRedeSocial) {
            return ClienteService.verificarCPFFacebook(                
                documentoCliente,
                idRedeSocial
            );
        },

        logar: async function (documentoCliente, idRedeSocial) {
            return UsuarioService.logarFacebook(                
                documentoCliente,
                idRedeSocial
            )
        }
    },

    GOOGLE : {
        enumName: "GOOGLE",

        set: async function (documentoCliente, id, foto) {
            return ClienteService.setIdGoogle(                
                documentoCliente,
                id,
                foto
            );
        },        

        verificarRedeSocial: async function (idRedeSocial) {
            return ClienteService.verificarGoogle(                
                idRedeSocial
            );
        },    
        
        verificarCPFByRedeSocial: async function (documentoCliente, idRedeSocial) {
            return ClienteService.verificarCPFGoogle(                
                documentoCliente,
                idRedeSocial
            );
        },

        logar: async function (documentoCliente, idRedeSocial) {
            return UsuarioService.logarGoogle(                
                documentoCliente,
                idRedeSocial
            )
        }
    },

    APPLE : {
        enumName: "APPLE",

        set: async function (documentoCliente, id, foto) {
            return ClienteService.setIdApple(                
                documentoCliente,
                id,
                foto
            );
        },        

        verificarRedeSocial: async function (idRedeSocial) {
            return ClienteService.verificarApple(                
                idRedeSocial
            );
        },    
        
        verificarCPFByRedeSocial: async function (documentoCliente, idRedeSocial) {
            return ClienteService.verificarCPFApple(                
                documentoCliente,
                idRedeSocial
            );
        },

        logar: async function (documentoCliente, idRedeSocial) {
            return UsuarioService.logarApple(                
                documentoCliente,
                idRedeSocial
            )
        }
    },

    get(value) {
        value = value.toUpperCase();

        if (value === null)
            return ;

        var array = Object.values(TipoRedeSocialEnum);
        var tipoRedeSocialFound = {};

        array.forEach(element => {
            if (element.enumName === value) {
                tipoRedeSocialFound = element;
                return;
            }
        });

        return tipoRedeSocialFound;
    }
}

export default TipoRedeSocialEnum;