import React, { Fragment } from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

class InputCheckBox extends AbstractComponent {        
    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }

    className() {
        return this.props.className !== undefined ? this.props.className : '';
    }    

    classNameInput() {
        return this.props.classNameInput !== undefined ? this.props.classNameInput : '';
    }
    
    classErro() {
        return this.props.erro !== undefined && this.props.erro !== '' ? 'form-controll-erro' : '';
    }    

    onChange(input) {               
        input.target.value = input.target.value === '0' ? '1' : '0';        

        if (this.props.onChange !== undefined) 
          this.props.onChange(input);        
    }        
    
    componentDidMount() {
        if(!this.props.defaultFoco)
            return ;

        this.foco(this.props.nome);
    }

    show() {
        if(!this.props.visible)
            return ;

        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>
                <div className={this.className() + ' ' + this.classErro()} >                    
                    <input 
                           type="checkbox"
                           name={this.props.nome} 
                           id={this.props.id}                            
                           value={this.props.value}
                           //defaultValue={this.props.defaultValue} 
                           className={this.classNameInput()}
                           disabled={this.props.disabled} 
                           maxLength={this.props.maxlength}         
                           checked={this.props.checked}                  
                           onChange={input => this.onChange(input)}/>
                    
                    &nbsp;
                    &nbsp;

                    <label htmlFor={this.props.nome}>
                        {this.props.children}
                    </label>

                    <span className="help-block">
                        {this.erro()}
                    </span>
                </div>
            </Col>
        )
    }

    render() {
        return (
            <Fragment>
                {this.show()}
            </Fragment>  
        )
    }
}

InputCheckBox.defaultProps = {
    md: 12,    
    defaultFoco: false,
    visible: true
};

export default InputCheckBox;