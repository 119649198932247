import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';

import {formatarDataHora} from '../../helper/Formatar';
import { Fragment } from 'react';

class InputLabelStatus extends AbstractComponent { 
    getDataHora() {
        if(!this.props.datahora) {
            return (
                <Fragment>
                    &nbsp; &nbsp; &nbsp;
                </Fragment>
            );
        }

        return formatarDataHora(this.props.datahora);
    }
     
    render() {
        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}
                 style={{padding: 0}}
                 >
                     
               <label htmlFor={this.props.nome} style={{paddingRight:'15px', paddingLeft:'15px'}} className={this.props.className}>
                    {this.props.label}
                </label> 

                <span>
                    {this.getDataHora()}
                </span>  
              
            </Col>
        )
    }
}

InputLabelStatus.defaultProps = {
    md: 12
};

export default InputLabelStatus;