import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import ModalAlerta from '../modal/ModalAlerta';

import Config from '../../../Config';

import ClienteProdutoFavoritoEnum from '../../../helper/enumerador/ClienteProdutoFavoritoEnum';

class ButtonLike extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            erro: '',

            preload: false
        }
    } 

    favoritarProduto(uuid, favorito) {        
        if(!this.autenticar())
            return false;
            
        this.setState({preload: true});

        ClienteProdutoFavoritoEnum.get(
            favorito === false ? 1 : 0
        ).submit(
            uuid
        ).then(() => {        
            this.setState({preload: false});
            
            this.props.onUpdateBadgeFavorito((favorito === false ? 1 : 0));            
        }).catch(erro => {        
            this.setState({preload: false, erro: this.trataErro(erro) });
        });
    }

    showComponent() {
        if(this.state.preload)
            return (
                <div className="product-action__add-button">
                    <img src={Config.urlImgBase + "preload_botao.gif"} style={{width: '35px', height: '35px', marginTop: '-8px'}} alt="Loading..."/> <br/>
                </div>
            );
            
        if(!this.props.favorito) 
            return (
                <label 
                    onClick={() => this.favoritarProduto(this.props.uuid, this.props.favorito)}                    
                    htmlFor={"heart" + this.props.uuid}
                    style={{
                        color: '#aab8c2',
                        cursor: 'pointer',
                        fontSize: '20px',
                        alignSelf: 'right',
                        float: 'right',
                        transition: 'color 0.2s ease-in-out'
                    }}
                >
                    <i className="fas fa-heart" />
                </label>
            );
            
        return (
            <label 
                onClick={() => this.favoritarProduto(this.props.uuid, this.props.favorito)}                
                htmlFor={"heart" + this.props.uuid}
                style={{
                    color: '#e2264d',                    
                    cursor: 'pointer',
                    fontSize: '20px',
                    alignSelf: 'right',
                    float: 'right',
                    transition: 'color 0.2s ease-in-out',
                    willChange: 'font-size',
                    animation: 'heart 1s cubic-bezier(.17, .89, .32, 1.49)'                    
                }}
            >
                <i className="fas fa-heart" />
            </label>
        );
    }

    render() {
        return (
            <Col 
                md={this.props.md} 
                xs={this.props.xs} 
                sm={this.props.sm} 
                lg={this.props.lg}
                style={this.props.style}
            >
                <ModalAlerta 
                    show={this.state.erro !== ''}                    
                    texto={this.state.erro}

                    fechar={() => this.setState({erro: ''})}
                />

                {this.showComponent()}
            </Col>
        );
    }
}

ButtonLike.defaultButton = {
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12
}

export default ButtonLike;