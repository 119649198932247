import Config from '../Config';
import Requisicao from './RequisicaoService';

class UsuarioService {
    static autenticar(token) {
        localStorage.setItem('x-token', token);
    }

    static async listar() {
        return Requisicao.get(Config.urlBaseApi + 'usuario');
    }

    static async remover(id) {
        return Requisicao.delete(Config.urlBaseApi + 'usuario/' + id);
    }

    static async cadastro(dados) {
        return Requisicao.post(Config.urlBaseApi + 'usuario', dados);
    }

    static async editar(dados, id) {
        return Requisicao.post(Config.urlBaseApi + 'usuario/' + id.toString(), dados);
    }

    static async logarCredentials(email, senha) {
        return Requisicao.post(Config.urlBaseApi + 'logar/credentials', {            
            email: email,
            senha: senha
        });
    }

    static async logarFacebook(documentoCliente, idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'logar/facebook', {            
            documentoCliente: documentoCliente,
            idRedeSocial: idRedeSocial            
        });
    }

    static async logarGoogle(documentoCliente, idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'logar/google', {        
            documentoCliente: documentoCliente,
            idRedeSocial: idRedeSocial            
        });
    }

    static async logarApple(documentoCliente, idRedeSocial) {
        return Requisicao.post(Config.urlBaseApi + 'logar/apple', {        
            documentoCliente: documentoCliente,
            idRedeSocial: idRedeSocial            
        });
    }

    static async logout() {
        return Requisicao.post(Config.urlBaseApi + 'logout', {            
        });
    }

    static async mudarSenha(senha, novaSenha, confirmacaoSenhaNova, documentoCliente) {
        return Requisicao.post(Config.urlBaseApi + 'usuario/mudarSenha', {
            senha: senha,
            novaSenha: novaSenha,
            confirmacaoSenhaNova: confirmacaoSenhaNova,            
            documentoCliente: documentoCliente
        });
    }   
    
    static async redefinirSenha(novaSenha, confirmacaoSenhaNova, idCliente) {
        return Requisicao.post(Config.urlBaseApi + 'usuario/redefinir-senha', {            
            novaSenha: novaSenha,
            confirmacaoSenhaNova: confirmacaoSenhaNova,            
            idCliente: idCliente
        });
    }   

    static async deletarUsuario(idUsuario){
        return Requisicao.post(Config.urlBaseApi + 'usuario/deletar-usuario',{
            idUsuario: idUsuario
        });
    }
}

export default UsuarioService;