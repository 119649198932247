import { Grid } from "@material-ui/core";
import AbstractComponent from "../AbstractComponent";

import {moeda} from '../../helper/Formatar';

class CheckoutResumoDetalhe extends AbstractComponent {
    render() {
        return (
            <>
                { this.props.valor > 0.00 &&           
                    <Grid item 
                        xs={12} 
                        style={{ 
                            marginBottom: '1rem', 
                            fontWeight: this.props.fontWeight, 
                            borderTop: this.props.borderTop 
                        }}
                    >
                        <Grid container style={{ marginTop: this.props.marginTop}}>
                            <Grid item xs={6}>
                                {this.props.descricao}
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                {moeda(this.props.valor)}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </>
        )
    }
}

export default CheckoutResumoDetalhe;
