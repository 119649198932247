import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import ButtonFacebook from '../../layout/button/ButtonFacebook';
import ButtonGoogle from '../../layout/button/ButtonGoogle';
import ButtonApple from '../../layout/button/ButtonApple';

import TipoRedeSocialEnum from '../../../helper/enumerador/TipoRedeSocialEnum';

class LoginByRedeSocial extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {
            social: {                                         
                idRedeSocial: 0,                 
                email: '', 
                nome: '',
                foto_social: '',
                tipo_rede_social: undefined
            }
        }
    }       

    verificarRedeSocial(idRedeSocial, email, userName, foto, tipo) {  
        let social = this.state.social;
        
        this.props.onReadOnly(true);

        social.tipo_rede_social = TipoRedeSocialEnum.get(tipo);

        social.tipo_rede_social.verificarRedeSocial(            
            idRedeSocial
        ).then((response) => {            
            this.setState({                 
                social: social
            });      
            
            this.props.onReadOnly(false);

            this.props.logar(response.data.email, null, response.data.documentoCliente, response.data.idRedeSocial, social.tipo_rede_social);
        }).catch(() => {                                
            social.idRedeSocial = idRedeSocial;             
            social.email = email; 
            social.nome = userName;
            social.foto_social = foto;                 

            this.setState({social: social});
            this.props.onReadOnly(false);
            this.props.onNextStep(social);            
        });
    }

    showComponent() {
        if(!this.props.show)
            return ;

        return (
            <Fragment>
                <div className="row">
                    <div className='col-md-12 text-center'>
                        <h5>                            
                            Entre com seu social login:                            
                        </h5>
                    </div>
                </div>

                <div className="d-flex justify-content-between" style={{ marginTop: '10px', marginBottom: '10px'}}>
                    <div style={{width: '10px'}}>

                    </div>
                    
                    <ButtonFacebook                             
                        inPreload={this.props.inPreload}                                                            
                        autoLoad={false}
                        empresa={this.props.empresa}
                        
                        onSuccess={(e) => this.verificarRedeSocial(
                            e.userID,
                            e.email,
                            e.name,                            
                            e.picture.data.url,                            
                            'facebook'
                        )}

                        onFailure={() => this.props.onFailure()}
                    />                                    
                                
                    <ButtonGoogle                        
                        onSuccess={(e) => this.verificarRedeSocial(
                            e.profileObj.googleId,
                            e.profileObj.email,                            
                            e.profileObj.name,                            
                            e.profileObj.imageUrl,                            
                            'google'
                        )}
                        
                        onFailure={() => this.props.onFailure()}
                    />

                    <ButtonApple                                            
                        onSuccess={(e) => this.verificarRedeSocial(
                            e.idToken,
                            e.email,                            
                            e.name,                            
                            e.imageUrl,                            
                            'apple'
                        )}
                        
                        onFailure={() => this.props.onFailure()}
                    />    
                    
                    <div style={{width: '10px'}}>

                    </div>                
                </div>                
            </Fragment>
        );
    }    

    render() {
        return (
            <Fragment>
                {this.showComponent()}
            </Fragment>
        )
    }
}

export default LoginByRedeSocial;