import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../../AbstractComponent';

class DesktopMenu extends AbstractComponent {  
    constructor(props) {
        super(props);        

        this.isLogged = this.isLogged();
    }  

    menuUserVisible() {
        if (!this.props.showUserMenu)
            return '';
        
        return 'floating-box__wrapper--visible';            
    }    
    
    bagdeVisible() {        
        return this.props.badge === 0 
            ? 'display-none'
            : ''
    }

    onClick() {
        if (!this.isLogged) {
            this.props.onClick(this.login());
            return;
        }

        this.props.onShowUserMenu();
    } 

    render() {
        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}                 
            >                
                <button
                    className="floating-box__button responsive-header__button"
                    style={{width: '100%'}}
                    onClick={() => this.props.buttonClick()}
                >
                    {
                        this.props.menuName &&
                        <div 
                            className="col-md-10
                                       col-sm-10
                                       col-lg-10
                                       col-xs-10
                                       text-center"
                            style={{
                                padding: '0px',
                                fontSize: '14px'
                            }}
                        >
                            { this.props.menuName }
                        </div>
                    }                    

                    {
                        this.props.icon &&                        
                        <i 
                            className={this.props.icon} 
                            style={{
                                fontSize: '30px',
                                lineHeight: '30px',
                                fontWeight: '700'
                            }}
                        >
                            {
                                this.props.badge > 0 &&                                
                                <span className="badge badge-danger badge-counter" style={{marginLeft: '-10px'}}>
                                    { ( this.props.badge <= 9 ? this.props.badge : '9+') }
                                </span>                                
                            }
                        </i>
                    }                  
                </button>

                {
                    this.props.children &&
                    <div className={"floating-box__wrapper floating-box__wrapper--right " + this.menuUserVisible()}> 
                        {this.props.children}
                    </div>
                }
            </Col>            
        )
    }
}

DesktopMenu.defaultProps = {
    icon: '',
    badge: 0,
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12 
}

export default DesktopMenu;