import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';
import Content from '../layout/content/Content';
import ModalTitle from '../layout/modal/ModalTitle';
import CategoriaMobileModalContent from './CategoriaMobileModalContent';

import FiltroEnum from '../../helper/enumerador/FiltroEnum';

class CategoriaMobileModal extends AbstractComponent { 
    constructor(props) {
        super(props);

        this.state = {            
            preload: false,
            
            erro: '',

            items: [],

            body: [],            

            preloadFetch: false,

            pagina: 1
        }        
    }

    autoComplete(e) {       
        const search = e.target.value;
        
        if(search === '')
            return ;                                

        FiltroEnum.get(
            this.props.header
        ).autoComplete(                        
            search
        ).then(resposta => {                 
            this.setState({                    
                items: resposta.data
            });            
        }).catch(erro => {
            this.props.onErro(this.trataErro(erro));
        });        
    }   

    onCallback(item) {
        if(this.props.onCallback !== undefined)
            this.props.onCallback(item);
    }

    loadCategoria(fetchMoreData, inSearch) {
        if (fetchMoreData)
            this.setState({ preloadFetch: this.state.body.length > 0 });

        if (inSearch) 
            this.setState({preload: true});

        let pagina = this.state.pagina;

        if (inSearch) {
            pagina = 1;
            this.setState({ pagina: 1 });
        }                       
        
        var input = document.getElementById('inputSearch');        

        let searchKey = '';

        if ((input !== null)&&(input !== undefined))
            searchKey = input.value;        
            
        FiltroEnum.get(
            this.props.header
        ).getAll(                        
            pagina,
            20,
            searchKey
        ).then(resposta => {     
            pagina = (this.state.pagina + 1);            

            this.setState({     
                preload: false,                           
                preloadFetch: false,
                pagina: pagina,                
                body: inSearch ? resposta.data : this.state.body.concat(resposta.data)
            });
        }).catch(() => {
            this.setState({ 
                preload: false,                               
                preloadFetch: false                
            });
        });
    }

    componentDidMount() {          
        this.loadCategoria(false, true);
    }  

    render() {
        return (
            <Modal id="categoria"
                   show={this.props.show}
                   className={this.props.className}
                   style={{ zIndex: 9999, overflow: "auto" }}
                   //onHide={() => this.props.fechar()}
            >
                <ModalTitle
                    icon="left"
                    exibeLupa={true}
                    header={this.props.header}
                    //fechar={() => this.props.fechar()}
                    placeholder={"Qual " + this.props.header.toLowerCase() + " está procurando?"}

                    items={this.state.items} 
                    search={() => this.loadCategoria(false, true)}
                          
                    autoComplete={(e) => this.autoComplete(e)}
                    onItemClick={() => this.loadCategoria(false, true)}                    
                >

                </ModalTitle>

                <Modal.Body>
                    <Content
                        Style={{ width: "34%" }}
                        preload={this.state.preload}
                        showSummary={false}
                        modalErro={this.state.erro}
                        onCloseAlerta={() => this.setState({ erro: '' })}

                        component={(props) => {
                            return <CategoriaMobileModalContent
                                {...props}  
                                
                                header={this.props.header}
                                filtros={this.props.filtros}

                                preloadFetch={this.state.preloadFetch}
                                body={this.state.body}

                                onCallback={(item) => this.onCallback(item)}
                                onApply={(items) => this.props.onApply(items)}                                
                                onErro={(erro) => this.setState({erro: erro})}
                                fetchMoreData={() => this.loadCategoria(true, false)}
                            />                                                                            
                        }}
                    />                    
                </Modal.Body>
            </Modal>            
        )
    }
}

CategoriaMobileModal.defaultProps = {
    header: 'Alerta'    
}

export default CategoriaMobileModal;