import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

class PreloadContent extends AbstractComponent {
    visible() {
        return this.props.exibir === false 
            ? 'display-none'
            : ''
    }   

    className() {
        if(this.props.tipo === 'content')
            return "col-md-12 col-sm-10 col-lg-9 col-xs-12";
        
        return "col-md-12 col-sm-12 col-lg-12 col-xs-12";
    }

    render() {
        return (
            <div className={"vitrine preload " + this.visible()}>
                <div className={this.className() + " text-center"}>
                    <img src={Config.urlImgBase + "preload_botao.gif"} alt="Loading..." width='35px' height='35px'/> 
                </div>                
            </div>
        )
    }
}

PreloadContent.defaultProps = {    
    tipo: ''
}

export default PreloadContent;