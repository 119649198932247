import React, { Fragment } from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';
import ButtonLike from '../layout/button/ButtonLike';
// import ButtonShare from '../layout/button/ButtonShare';

import './ButtonsProdutos.css';

class ButtonsProduto extends AbstractComponent {      
    showComponent() {
        if(!this.props.show)
            return ;
            
        return (
            <Col 
                md={this.props.md}
                sm={this.props.sm}
                lg={this.props.lg}
                xs={this.props.xs}
                style={{
                    padding: '0px', 
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center'
                }}
            >  
                <div className="col-md-9
                                col-sm-9
                                col-lg-9
                                col-xs-9">

                </div>                                           
                
                {/* <ButtonShare
                    md={2} 
                    xs={2} 
                    sm={2} 
                    lg={2}
                    style={{padding: '0px', textAlign: 'right'}}
                    
                    uuid={this.props.uuid}
                />     */}

                <ButtonLike
                    md={3} 
                    xs={3} 
                    sm={3} 
                    lg={3}
                    style={{padding: '0px', textAlign: 'right'}}

                    uuid={this.props.uuid}
                    favorito={this.props.favorito}

                    onUpdateBadgeFavorito={(tipo) => this.props.onUpdateBadgeFavorito(tipo)}
                    route={(item) => this.props.route(item)}
                />             
            </Col> 
        );
    }

    render() {
        return (
            <Fragment>                                                            
                {this.showComponent()}           
            </Fragment >
        )
    }
}

ButtonsProduto.defaultProps = {
    md: 12, 
    xs: 12, 
    sm: 12, 
    lg: 12
}

export default ButtonsProduto;