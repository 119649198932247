import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Form from '../layout/form/Form';
import Button from '../layout/button/Button';
import Config from '../../Config';
import InputPassword from '../layout/input/InputPassword';
import Image from '../layout/image/Image';

import ClienteService from '../../service/ClienteService';
import UsuarioService from '../../service/UsuarioService';

import { getParam } from '../../helper/Url';

class RedefinirSenhaContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: false,

            form: {                
                hash: '',             
                novaSenha: '',
                confirmacaoNovaSenha: '',
                idCliente: 0
            },

            erroForm: {                
                novaSenha: '',
                confirmacaoNovaSenha: ''
            },            

            sucesso: '',

            btnSalvarDisabled: true,
        }
    }

    onChange(e) {
        const form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({ form: form, btnSalvarDisabled: false });
    }

    limpaVariavelErro() {
        let erro = this.state.erroForm;

        erro.novaSenha = '';
        erro.confirmacaoNovaSenha = '';                    

        this.setState({ erroForm: erro });
    } 

    valida() {
        this.limpaVariavelErro();

        const form = this.state.form;
        const erroForm = this.state.erroForm;
        var exibeErro = false;
    
        if(!form.idCliente) {
            this.props.onErro('Chave inválida. Por favor, faça uma nova solicitação de redefinição de senha.')
            return false;
        }

        if(!form.novaSenha) {            
            erroForm.novaSenha = "Informe o campo Nova Senha";
            exibeErro = true;            
        }
        
        if(form.novaSenha.length < 6) {
            erroForm.novaSenha = "O campo Nova Senha deve ter no mínimo 6 caracteres";
            exibeErro = true;            
        }

        if(!form.confirmacaoNovaSenha) {            
            erroForm.confirmacaoNovaSenha = "Informe o campo Confirmação de Senha";
            exibeErro = true;            
        }

        if((form.novaSenha) && (form.confirmacaoNovaSenha)) {
            if (form.novaSenha !== form.confirmacaoNovaSenha) {
                erroForm.confirmacaoNovaSenha = "O campo Confirmação de Senha deve ser igual ao campo Nova Senha";
                exibeErro = true;            
            }   
        }
        
        if(exibeErro) {
            this.setState({erroForm: erroForm});

            return false;
        }

        return true;
    }

    gravar() {        
        if(!this.valida())
            return ;

        this.setState({ preload: true });

        UsuarioService.redefinirSenha(                                    
            this.state.form.novaSenha,
            this.state.form.confirmacaoNovaSenha,            
            this.state.form.idCliente
        ).then(resposta => {
            this.props.onSucesso('Senha salva com sucesso!!!');

            this.setState({ preload: false, btnSalvarDisabled: true });            
        }).catch(error => {
            this.setState({ preload: false });

            this.props.onErro(this.trataErro(error))
        });
    }

    componentDidMount() {
        const myHash = getParam('hash');        
        
        this.props.showPreload(true);

        ClienteService.getByHash(            
            myHash
        ).then(resposta => {
            var form = this.state.form;

            form.idCliente = resposta.data.idCliente;            

            this.props.showPreload(false);

            this.setState({
                form: form,
                hash: myHash
            });            
        }).catch(erro => {
            this.props.showPreload(false);
            this.props.onErro(this.trataErro(erro));            
        });        
    }    

    render() {
        return (
            <Form id="form" onSubmit={(e) => this.gravar(e)} className="form">
                <div className="logo text-center" >
                    <div className="row justify-content-center align-self-center">
                        <Image
                            md={12}
                            xs={12}
                            sm={12}
                            lg={12}                        
                            semImagem={Config.urlImgBase + "logo/logo.png"}
                            semImagemBorderRadius='0px'
                            width='8em'
                            height='7em'
                            heightImage='6em'                        
                            alt="Logo"
                        />
                    </div>
                </div>  

                <div className="row">
                    <div className='col-md-12 text-center'>
                        <h1 className="h1">
                            <strong>
                                CRIE UMA NOVA SENHA                                
                            </strong>
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className='col-md-1 text-center'>
                    </div>

                    <div className='col-md-10 text-center'>
                        <h4>
                            Essa será sua NOVA senha
                            <br/>
                            de acesso ao sistema
                        </h4>
                    </div>

                    <div className='col-md-1 text-center'>
                    </div>
                </div>

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        inputRef={e => this.novaSenha = e}
                        id="novaSenha"
                        nome="novaSenha"
                        value={this.state.form.novaSenha}
                        defaultValue={this.state.form.novaSenha}
                        defaultFoco={true}
                        erro={this.state.erroForm.novaSenha}
                        onChange={e => this.onChange(e)}>
                        NOVA SENHA
                    </InputPassword>
                </div>

                <div className="row">
                    <InputPassword
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        nome="confirmacaoNovaSenha"
                        value={this.state.form.confirmacaoNovaSenha}
                        defaultValue={this.state.form.confirmacaoNovaSenha}
                        erro={this.state.erroForm.confirmacaoNovaSenha}
                        onChange={e => this.onChange(e)}>
                        CONFIRMAÇÃO DE SENHA
                    </InputPassword>
                </div>                

                <div className="row">
                    <div className="col-md-12
                                col-sm-12
                                col-lg-12
                                col-xs-12">
                        <Button
                            type="submit"
                            background={1}
                            color={true}
                            justifyContent='center'
                            disabled={this.state.preload || this.state.btnSalvarDisabled}
                            preload={this.state.preload}
                            className="product-action__counter__button">
                            SALVAR
                        </Button>
                    </div>
                </div>
            </Form>
        )
    }
}

export default RedefinirSenhaContent;