import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractMenu from '../AbstractMenu';
import PerfilDesktopButton from './DesktopPerfilMenu';
import DesktopMenu from './DesktopMenu';

import ShoppingBagEnum from '../../../../helper/enumerador/ShoppingBagEnum';
import AutenticarService from '../../../../service/AutenticarService';

import {route} from '../../../../helper/Route';

class MenuTop extends AbstractMenu {
    constructor(props) {
        super(props);

        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    }

    render() {
        return(
            <Col md={this.props.md}
                 xs={this.props.xs}
                 sm={this.props.sm}
                 lg={this.props.lg}
            >                
                <PerfilDesktopButton
                    md={3}
                    xs={3}
                    sm={3}
                    lg={3}
                    cliente={this.props.cliente}
                    preloadCliente={this.props.preloadCliente}
                    showUserMenu={this.state.showUserMenu}
                    onShowUserMenu={() => this.onShowUserMenu()}

                    onClick={(item) => this.onClick(item)}
                />

                <DesktopMenu
                    md={3}
                    xs={3}
                    sm={3}
                    lg={3}
                    badge={this.props.badge.notificacao}
                    icon="pe-7s-bell"
                    buttonClick={() => this.onClick(route('', 'Notificacao', 'notificacao'))}
                />

                <DesktopMenu
                    md={3}
                    xs={3}
                    sm={3}
                    lg={3}
                    badge={this.props.badge.favorito}
                    icon="pe-7s-like"
                    buttonClick={() => this.onClick(route('', 'Favorito', 'favorito'))}
                /> 
{/* 
                <DesktopMenu
                    md={4}
                    xs={4}
                    sm={4}
                    lg={4}
                    badge={this.props.carrinho.carrinho_item_quantidade.length + this.props.carrinho.carrinho_item_peso.length}
                    icon={this.shoppingBagType.icon()}
                    buttonClick={() => this.onClick(this.homePage())}
                />                  */}
            </Col>
        )
    }
}

MenuTop.defaultProps = {
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12
}

export default MenuTop;
