import DepartamentoService from '../../service/ProdutoDepartamentoService';
import MarcaService from '../../service/ProdutoMarcaService';
import GrupoService from '../../service/ProdutoGrupoService';

var FiltroEnum = {
    DEPARTAMENTO: {
        enumName: 'DEPARTAMENTO',        
        getAll: async function(pagina, limite, searchKey) {            
            return DepartamentoService.getAll(pagina, limite, searchKey);
        },
        autoComplete(search) {
            return DepartamentoService.autoComplete(search);
        }
    },
    MARCA: {
        enumName: 'MARCA',        
        getAll: async function(pagina, limite, searchKey) {
            return MarcaService.getAll(pagina, limite, searchKey);
        },
        autoComplete(search) {
            return MarcaService.autoComplete(search);
        }
    },
    GRUPO: {
        enumName: 'GRUPO',        
        getAll: async function(pagina, limite, searchKey) {
            return GrupoService.getAll(pagina, limite, searchKey);
        },
        autoComplete(search) {
            return GrupoService.autoComplete(search);
        }
    },
    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return FiltroEnum.DEPARTAMENTO;

        var array = Object.values(FiltroEnum);
        var filtroEnumFound = {};

        array.forEach(element => {            
            if(element.enumName === value.toUpperCase()) {
                filtroEnumFound = element;      
                return ;          
            }            
        });   
        
        return filtroEnumFound;
    }
}

export default FiltroEnum;