import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Form from '../layout/form/Form';
import Config from '../../Config';

import './Sobre.css';

class SobreContent extends  AbstractComponent {        
    render () {
        return (            
            <Form id="form" className="form">                                                                        
                <div 
                    className="logo text-center" 
                    style={{                                    
                        height: '10em',
                        borderBottom: 'solid 1px #4A6A95'
                    }}
                >   
                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12">
                        <img 
                            className="fotoPerfil"
                            src={Config.urlImgBase + "logo/avance.png"}
                            alt="Foto Empresa Parceira"    
                            style={{width: '122px', marginBottom: '10px'}}                                                                       
                        />                                                  
                    </div>
                                    
                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-versao">
                        VERSAO {Config.versao}
                    </div>

                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-atualizado">
                        Atualizado em {Config.atualizado}
                    </div>  
                </div>                                              
                
                <div style={{marginTop: '10px'}} />
                
                <div className="row">
                    <div className="col-md-12 text-justify text-font-default">
                        O aplicativo foi desenvolvido em parceria com a empresa Avance Sistemas.
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-justify text-font-default">
                        O estabelecimento reserva o direito de limitar quantidade máxima de produtos por compra por cliente e não faz vendas por atacado.
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-justify text-font-default">
                        Ofertas válidas enquanto durar o estoque. É proibido a venda e entrega de bebidas alcoólicas a menores de 18 (dezoito) anos - art.81, || do Estatuto.
                    </div>
                </div>

                <div className="row" style={{marginBottom: '15px'}}/>

                <div className="row" style={{marginBottom: '15px'}}>
                    
                </div>                        
            </Form>                
        )
    }
}

export default SobreContent;