import React from 'react';
import AbstractComponent from '../../AbstractComponent';

class Image extends  AbstractComponent {
    onLoad() {
        if(this.props.onLoad !== undefined)
            this.props.onLoad();
    }

    show() {                                
        if((this.props.imagem === undefined) || (this.props.imagem === '') || (this.props.imagem === null) || (this.props.imagem.indexOf('undefined') > -1) || (this.props.imagem.indexOf('null') > -1))
            return (
                <img 
                    id={this.props.id}
                    className={this.props.className}
                    src={this.props.semImagem}
                    alt={this.props.alt}
                    style={{                         
                        height: this.props.heightImage, 
                        width: this.props.width,
                        borderRadius: this.props.semImagemBorderRadius
                    }} 
                    onLoad={() => this.onLoad()}
                />
            );        
        
        return (
            <img 
                id={this.props.id}
                className={this.props.className}
                src={this.props.imagem}
                alt={this.props.alt}
                style={{ 
                    width: this.props.width, 
                    height: this.props.heightImage, 
                    borderRadius: this.props.borderRadius                    
                }} 
                onLoad={() => this.onLoad()}
            />
        );
    }

    render() {
        return (            
            <div 
                className={"col-md-" + this.props.md + " col-xs-" + this.props.xs + " col-sm-" + this.props.sm + " col-lg-" + this.props.lg + " " + this.props.classNameDiv}
                style={{                                
                    paddingRight: '0px',
                    paddingLeft: '0px',
                    height: this.props.height,
                    marginTop: this.props.marginTop,
                }}
            >                                                   
                { this.show() }                           
            </div>
        );
    }
}

Image.defaultProps = {
    alt: '',
    srcOk: '',
    srcAlt: '',    
    height: '6em',
    heightImage: '6em',
    width: '6em',    
    borderRadius: '50px',
    semImagemBorderRadius: '50px',
    className: '',
    alignText: 'center',
    classNameDiv: "text-center"
}

export default Image;