import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';

import {roundNum} from '../../../helper/Matematica';
import {strZero, moeda} from '../../../helper/Formatar';

class InputLabelMoeda extends AbstractComponent { 
    className() {
        return this.props.className === undefined   
            ? ''
            : this.props.className;
    }   

    showMoeda() {
        if(!this.props.sobrescrito) {
            return (
                <Fragment>                    
                    <span className="price-tag-fraction" style={this.props.styleValor}>
                        {moeda(this.props.value)}
                    </span>   
                </Fragment>
            )            
        }

        const valor = this.props.value;
        const parteInt = parseInt(valor, 10);
        const parteDecimal = Math.round(roundNum((valor - parteInt), -2) * 100);        

        return (
            <Fragment>
                <meta content={this.props.value}/>

                <span className="price-tag-symbol">{this.props.symbol}</span>                
                <span className="price-tag-fraction">
                    {parteInt}<sup>,{strZero(parteDecimal,2)}</sup>
                </span>                
            </Fragment>            
        );        
    }    

    render() {
        return (
            <div 
                className={this.className()}
                style={this.props.style}
            >
                {this.props.children} {this.showMoeda()}                
            </div>
        )
    }
}

InputLabelMoeda.defaultProps = {
    symbol: 'R$',
    sobrescrito: true
};

export default InputLabelMoeda;