import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

class InputData extends AbstractComponent {        
    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }

    className() {
        return this.props.className !== undefined ? this.props.className : '';
    }    

    classNameInput() {
        return this.props.classNameInput !== undefined ? this.props.classNameInput : '';
    }
    
    classErro() {
        return this.props.erro !== undefined && this.props.erro !== '' ? 'form-controll-erro' : '';
    }    

    onChange(input) {                   
        var inputStr = input.target.value;
        
        inputStr = inputStr.replace(/\D/g,"");        
        inputStr = inputStr.replace(/(\d{2})(\d)/,"$1/$2");
        inputStr = inputStr.replace(/(\d{2})(\d)/,"$1/$2");
        inputStr = inputStr.replace(/(\d{2})(\d{2})$/,"$1$2");    

        input.target.value = inputStr;

        if (this.props.onChange !== undefined) {
          this.props.onChange(input);
        }        
    }    

    onKeyPress(input) {        
        if (this.props.onKeyPress !== undefined) {
          this.props.onKeyPress(input);
        }
    }   

    onBlur(input) {        
        if (this.props.onBlur !== undefined) {
          this.props.onBlur(input);
        }
    }

    
    render() {
        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>
                <div className={this.className() + ' ' + this.classErro()} >
                    <label htmlFor={this.props.nome}>
                        {this.props.children}
                    </label>

                    <input 
                           autoComplete="off"                           
                           type="tel"
                           name={this.props.nome}                            
                           id={this.props.id}     
                           
                           value={this.props.value} 
                           //defaultValue={this.props.defaultValue} 
                           
                           className={"form-control " + this.classNameInput()}
                           disabled={this.props.disabled} 
                           maxLength={10}                           

                           onChange={input => this.onChange(input)}                            
                           onKeyPress={input => this.onKeyPress(input)}
                           onBlur={e => this.onBlur(e)} />

                    <span className="help-block">
                        {this.erro()}
                    </span>
                </div>
            </Col>
        )
    }
}

InputData.defaultProps = {
    md: 12,    
};

export default InputData;