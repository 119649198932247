var TipoEnderecoEnum = {
    BAIRRO: {
        enumName: 'BAIRRO',        
        name: 'Bairro',
        descricao: 'Bairro'        
    },

    POI: {
        enumName: 'POI',
        name: 'PoI',
        descricao: 'Ponto de Interesse'
    },

    get(value) {
        if(value === null)
            return TipoEnderecoEnum.BAIRRO;

        var array = Object.values(TipoEnderecoEnum);
        var tipoEnderecoFound = {};

        array.forEach(element => {
            if(element.enumName === value.toUpperCase()) {
                tipoEnderecoFound = element;
                return ;
            }
        });

        return tipoEnderecoFound;
    },

    getAll() {
        var array = Object.values(TipoEnderecoEnum);        
        let retorno = [];

        array.forEach(element => {
            if((element.name !== 'get')&&(element.name !== 'getAll'))
                retorno.push({
                    valor: element.name, 
                    texto: element.descricao, 
                    checked: element.name === 'Bairro',
                    disabled: false
                })    
        });

        return retorno;
    }
}

export default TipoEnderecoEnum;