import React, { Fragment } from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';

import TipoEnderecoEnum from '../../../helper/enumerador/TipoEnderecoEnum';

import {formataEndereco} from '../../../helper/Formatar';
class InputEndereco extends AbstractComponent { 
    showPoI() {
        if(this.props.cliente.tipo_endereco === TipoEnderecoEnum.BAIRRO.name)
            return ;

        return (
            <Fragment>
                <div 
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                        WebkitLineClamp: "1"
                    }}
                >
                    <label>
                        Ponto de Interesse:
                    </label>
                    
                    &nbsp;

                    { this.props.cliente.ponto_de_interesse + ' - ' + this.props.cliente.ponto_de_interesse_descricao }
                </div>
            </Fragment>
        )
    } 

    showEndereco() {
        if(this.props.cliente.tipo_endereco === TipoEnderecoEnum.POI.name)
            return ;

        return (
            <Fragment>
                <div 
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                        WebkitLineClamp: "1"
                    }}
                >
                    {formataEndereco(this.props.cliente.logradouro, this.props.cliente.endereco) + ', '}
                </div>
                
                <span className="delivery-input__address-number">
                    {this.props.cliente.numero}
                </span>
            </Fragment>
        )
    }  

    render() {
        return(            
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>
                <div className={this.props.inPreload || this.props.cliente.id === 0 ? 'display-none' : ''}>
                    <span className="delivery-input-wrapper__title">
                        Entregar em
                    </span>
                    
                    <button className="delivery-input" style={{cursor: 'default'}}>
                        <span className="delivery-input__icon-map">
                            <span className="icon-search icon-search--home">                            
                                <i className="icon-home-font pe-7s-home" />
                            </span>
                        </span>
                        
                        <div className="delivery-input__item">
                            <span className="delivery-input__address">
                                { this.showEndereco() }

                                { this.showPoI() }                                
                            </span>
                            
                            {/* <span className="delivery-input__icon-arrow">
                                <span className="icon-search icon-search--arrow-down">
                                    <i className="icon-search-seta-font pe-7s-angle-down" />
                                </span>
                            </span> */}
                        </div>
                    </button>
                </div>
            </Col>
        )
    }
}

export default InputEndereco;