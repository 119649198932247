var AlignEnum = {
    LEFT: {
        enumName: 'LEFT',        
        className: 'text-left'        
    },
    RIGHT: {
        enumName: 'RIGHT',        
        className: 'text-right'        
    },
    CENTER: {
        enumName: 'CENTER',        
        className: 'text-center'        
    }
}

export default AlignEnum;