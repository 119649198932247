import React from 'react';
import AbstractComponent from '../AbstractComponent';
import Config from '../../Config';

class FavoritoEmpty extends AbstractComponent {    
    render() {
        return (
            <div className="col-md-12
                            col-sm-12
                            col-lg-12
                            col-xs-12">
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-6">
                        <div className="text-center mt-4">                            
                            <img
                                className="mb-4 img-error"
                                alt="Produto não encontrado"
                                src={Config.urlImgBase + "addFavoritos.png"}
                            />                            

                            <h5 className="lead">
                                <b>
                                    <span className="product-not-found">
                                        Ops! &nbsp;
                                    </span>
                                    
                                    nenhum favorito encontrado &nbsp;

                                    <br></br>

                                    <span className="product-not-found">
                                        <a  
                                            style={{color: "unset", textDecoration: "unset"}}
                                            onClick={() => this.homePage2()} 
                                        >
                                            Acesse a home 
                                        </a>
                                    </span>                                                                                                            
                                </b>
                            </h5>
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}

export default FavoritoEmpty;