import React from 'react';
import AbstractComponent from '../AbstractComponent';
import ContentMini from '../layout/content/ContentMini';
import PerfilContent from './PerfilContent';

class Perfil extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: false,
            erro: '',
            sucesso: ''
        }
    }

    componentDidMount() {
        this.props.onTitulo("Dados Pessoais");

        if(!this.isLogged()) 
            return this.login2();
    }

    render() {
        return (
            <ContentMini                
                preload={this.state.preload}                
                modalErro={this.state.erro}
                modalSucesso={this.state.sucesso}
                
                onCloseAlerta={() => this.setState({ erro: '' })}
                onCloseSucesso={() => this.setState({ sucesso: '' })}

                component={(props) => {
                    return <PerfilContent
                        {...props}
                        
                        preload={this.state.preload || this.props.inPreload}
                        empresa={this.props.empresa}

                        showPreload={(value) => this.setState({ preload: value })}
                        showErro={(value) => this.setState({ erro: value })}
                        showSucesso={(value) => this.setState({ sucesso: value })}

                        route={(item) => this.props.route(item)}
                    />
                }}
            />
        )
    }
}

export default Perfil;