import React from 'react';
import {Modal} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import ModalTitle from '../../layout/modal/ModalTitle';
import Button from '../button/Button';
class ModalQuestion extends AbstractComponent {
    constructor(props) {
        super(props);
    
        this.state = {            
            texto: ''
        };
    }

    exibir(texto) {
        this.setState({            
            texto: texto
        });
    }
    
    fechar() {
        if(this.props.fechar !== undefined) 
            this.props.fechar()
    }

    btnSimClick() {
        if(this.props.btnSimClick !== undefined) {
            this.props.btnSimClick();
        }
    }

    btnNaoClick() {                
        if(this.props.btnNaoClick !== undefined) {
            this.props.btnNaoClick();
        }
    }    

    texto() {        
        const texto = (this.props.texto === undefined) ? this.state.texto : this.props.texto;
        return (texto === undefined || texto === '' || texto ==  null) ? this.P_ERRO_PADRAO() : texto;                      
    }          

    styleHeader() {                
        return (this.props.styleHeader === undefined) ? "bg-white" : this.props.styleHeader;
    }

    styleIcon() {
        return (this.props.styleIcon === undefined) ? "" : this.props.styleIcon;
    }

    styleText() {
        return (this.props.styleText === undefined) ? "text-dark" : this.props.styleText;        
    }

    styleButtonSim() {       
        return (this.props.styleButtonSim === undefined) ? "btn-primary" : this.props.styleButtonSim;                
    }

    styleButtonNao() {
        return (this.props.styleButtonNao === undefined) ? "btn-danger" : this.props.styleButtonNao;        
    }

    buttonSimTxt() {
        return (this.props.buttonSimTxt === undefined) ? 'Sim' : this.props.buttonSimTxt;
    }

    buttonNaoTxt() {
        return (this.props.buttonNaoTxt === undefined) ? 'Não' : this.props.buttonNaoTxt;
    }

    render() {
        return (
            <Modal show={this.props.show} 
                   className={this.props.className}
                   style={{zIndex: 9999}}
                   onHide={() => this.fechar()}>

                {
                    this.props.header !== "" &&
                    <ModalTitle
                        icon="none"
                        className={this.styleHeader()}
                        classNameIcon={this.styleIcon()}
                        escondeLupa={false}
                        header={this.props.header}
                        fechar={() => this.fechar()}                                                                                                                    
                    />                                
                }                                

                <Modal.Body className={"text-center " + this.styleText()} 
                    style={{marginBottom: '15px'}}>
                    <div className={"text-center " + this.styleText()} 
                        style={{marginBottom: '15px'}}>
                        {this.texto()}
                    </div>                    
                                    

                    <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6 
                                        col-sm-6
                                        col-lg-6
                                        col-xs-6">
                            <Button                                    
                                    className={"text-center btn " + this.styleButtonSim()}                                      
                                    type="button" 
                                    background={3}
                                    color={false}  
                                    width={'60px'}
                                    height={'45px'}
                                    onClick={() => {this.btnSimClick()}}>
                                {this.buttonSimTxt()}
                            </Button>
                        </div>

                        <div className="col-md-6 
                                        col-sm-6
                                        col-lg-6
                                        col-xs-6">
                            <Button className={"text-center btn " + this.styleButtonNao()}  
                                    type="button" 
                                    width={'60px'}
                                    height={'45px'}
                                    background={3}                                    
                                    onClick={() => {this.btnNaoClick()}}>
                                {this.buttonNaoTxt()}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

ModalQuestion.defaultProps = {
    header: "PERGUNTA"
}

export default ModalQuestion;