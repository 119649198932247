import React from 'react';
import ReactGoogleLogin from 'react-google-login';
import AbstractComponent from '../../AbstractComponent';
import Config from '../../../Config';

class ButtonGoogle extends AbstractComponent {    
    constructor(props) {
        super(props);

        this.state = {
            logar: false
        }
    }    

    onSuccess(e) {        
        this.props.onSuccess(e);
        this.setState({logar: false});
    }

    onFailure() {
        this.setState({logar: false});
        this.props.onFailure();
    }

    showComponent() {
        if((!this.props.autoLoad)&&(!this.state.logar))
            return ;            

        return (
            <div style={{display: 'none'}}>
                <ReactGoogleLogin                    
                    className="icon-google"
                    clientId={Config.apiLoginGoogle}
                    style={{ dispaly: "none" }}                    
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={false}
                    signIn={this.state.logar || this.props.autoLoad}
                    autoLoad={this.state.logar || this.props.autoLoad}
                    theme='light'
                    buttonText=""
                    
                    onSuccess={(e) => this.onSuccess(e)}
                    onFailure={(e) => this.onFailure(e)}
                />
            </div>
        );
    }

    render() {
        return (
            <>
                <button 
                    type="button" 
                    onClick={() => this.setState({logar: true})}
                    className="icon-google"                    
                >
                    <img 
                        style={{
                            height: "22px", 
                            marginTop: '-20px',
                            marginLeft: '-4px'
                        }}
                        src={Config.urlImgBase + "logo/google.png"}
                        alt="google" />
                </button>
                
                { this.showComponent() }
            </>
        )
    }
}

ButtonGoogle.defaultProps = {    
    autoLoad: false
};

export default ButtonGoogle;