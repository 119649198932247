import React from 'react';
import AbstractComponent from '../AbstractComponent';
import ContentMini from '../layout/content/ContentMini';
import NotificacaoContent from './NotificacaoContent';
class Notificacao extends AbstractComponent {
    constructor(props) {
        super(props);        

        this.state = {  
            preload: false,
            erro: ''            
        }        
    }    

    componentDidMount() {     
        this.onTitulo("Notificação");                                                
    }    

    render() {
        return (
            <ContentMini       
                classNameContent="semborda"
                preload={this.state.preload}                
                modalErro={this.state.erro}
                modalSucesso={this.state.sucesso}
                
                onCloseAlerta={() => this.setState({ erro: '' })}
                onCloseSucesso={() => this.setState({ sucesso: '' })}

                component={(props) => {
                    return <NotificacaoContent
                        {...props}

                        perfilSistema={this.props.perfilSistema}   

                        route={(item) => this.props.route(item)}                        
                        showPreload={(value) => this.setState({preload: value})}

                        onErro={(erro) => this.setState({erro: erro})}
                        onUpdateNotificacao={() => this.props.onUpdateNotificacao()}
                    />
                }}
            />                    
        )
    }
}

export default Notificacao;