import Config from "../Config";
import Requisicao from "./RequisicaoService";

class BadgeService {        
    static async badge(idCarrinho, cpf, uuidAparelho) {        
        return Requisicao.post(Config.urlBaseApi + 'badge', {            
            idCarrinho: idCarrinho,
            cpf: cpf,
            uuidAparelho: uuidAparelho
        });
    }   
}

export default BadgeService;