import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Preload from '../preload/PreloadContent';

class InputToggle extends AbstractComponent {        
    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }

    className() {
        return this.props.className !== undefined ? this.props.className : '';
    }        
    
    classErro() {
        return this.props.erro !== undefined && this.props.erro !== '' ? 'form-controll-erro' : '';
    }    

    onChange(input) {               
        if(input.target.value === '')
            input.target.value = '0';
            
        input.target.value = parseInt(input.target.value, 10) === 0 ? '1' : '0';        

        if (this.props.onChange !== undefined) 
          this.props.onChange(input);        
    }        
    
    componentDidMount() {
        if(!this.props.defaultFoco)
            return ;

        this.foco(this.props.nome);
    }

    show() {
        if(!this.props.visible)
            return ;

        return (
            <div 
                className={"col-md-" + this.props.md + " col-xs-" + this.props.xs + " col-sm-" + this.props.sm + " col-lg-" + this.props.lg + " " + this.props.className}
                style={this.props.style}
            >
                 <div 
                    className={"col-md-8 col-sm-8 col-lg-8 col-xs-8 text-" + this.props.align}
                    style={{                        
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        height: '40px'
                    }}
                >
                    <span style={{lineHeight: '39px'}}>
                        {this.props.children}
                    </span>
                </div>                                            

                <div 
                    className="col-md-4                                 
                               col-sm-4                                                
                               col-lg-4                                                
                               col-xs-4
                               text-right"
                    style={{        
                        paddingLeft: '0px',                
                        paddingRight: '0px',
                        height: '40px'
                    }}
                >                                                                
                    <label className="toggle">                        
                        {
                            this.props.preload &&
                            <Preload
                                exibir={true}
                                style={{padding: '0px'}}
                            />
                        }

                        {
                            !this.props.preload &&
                            <>    
                                <input 
                                    type="checkbox" 
                                    id={this.props.id}
                                    name={this.props.nome}
                                    value={this.props.value}                             
                                    checked={this.props.checked}                        
                                    onChange={(e) => this.onChange(e)}
                                />
                                <span className="check" style={{marginTop: '10px'}} />
                                <span className="texto" />
                            </>
                        }
                    </label>                                                                            
                </div>

                <span className="help-block">
                    {this.erro()}
                </span>                
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                {this.show()}
            </Fragment>  
        )
    }
}

InputToggle.defaultProps = {
    md: 12,        
    visible: true,
    className: 'border-bottom-padrao',
    align: 'left',
};

export default InputToggle;