import React from 'react';
import ButtonFacebook from '../../component/layout/button/ButtonFacebook';
import ButtonGoogle from '../../component/layout/button/ButtonGoogle';
import ButtonApple from '../../component/layout/button/ButtonApple';

import ClienteService from '../../service/ClienteService';
import TipoRedeSocialEnum from './TipoRedeSocialEnum';

async function verificarRedeSocial(props, documentoCliente, idRedeSocial, foto, tipo) {    
    props.setState({preload: true});

    TipoRedeSocialEnum.get(tipo).set(
        documentoCliente, 
        idRedeSocial, 
        foto
    ).then(() => {                
        props.setState({ 
            preload: false, 
            showComponent: false 
        });

        props.showSucesso(idRedeSocial, foto, tipo)
    }).catch(error => {        
        props.setState({preload: false});
        props.props.showErro(error);
    })
}

var PerfilMenuEnum = {
    FACEBOOK: {
        enumName: 'FACEBOOK',

        component: function (props, cpf) {            
            return (
                <div id="facebook" style={{ display: "none" }} >
                    <ButtonFacebook                                                     
                        autoLoad={true}
                        empresa={props.props.empresa}
                        
                        onSuccess={(e) => verificarRedeSocial(
                            props, 
                            cpf,
                            e.userID,                            
                            e.picture.data.url,                            
                            'facebook'
                        )}
                    /> 
                </div>
            );
        },

        desvincular: async function (documentoCliente) {            
            return TipoRedeSocialEnum.get('FACEBOOK').set(documentoCliente, '', '');
        }
    },

    GOOGLE: {
        enumName: 'GOOGLE',        

        component: function (props, cpf) {
            return (
                <div id="google" style={{ display: "none" }} >
                    <ButtonGoogle                                               
                        autoLoad={true}
                        
                        onSuccess={(e) => verificarRedeSocial(
                            props,
                            cpf,
                            e.profileObj.googleId,
                            e.profileObj.imageUrl,                            
                            'google'
                        )}                                                
                    />
                </div>
            );
        },

        desvincular: async function (documentoCliente) {
            return TipoRedeSocialEnum.get('GOOGLE').set(documentoCliente, '', '');
        }
    },

    APPLE: {
        enumName: 'APPLE',        

        component: function (props, cpf) {
            return (
                <div id="apple" style={{ display: "none" }} >
                    <ButtonApple                                              
                        autoLoad={true}
                        
                        onSuccess={(e) => verificarRedeSocial(
                            props,
                            cpf,
                            e.idToken,
                            e.imageUrl,                            
                            'apple'                            
                        )}                                                
                    />
                </div>
            );
        },

        desvincular: async function (documentoCliente) {
            return TipoRedeSocialEnum.get('APPLE').set(documentoCliente, '', '');
        }
    },

    FOTO: {
        enumName: 'FOTO',

        desvincular: async function (documentoCliente) {
            return ClienteService.removerFoto(                
                documentoCliente
            )
        }
    },

    get(value) {        
        if (value === null || value === undefined || value === "undefined")
            return ;

        value = value.toUpperCase();

        var array = Object.values(PerfilMenuEnum);
        var perfilMenuEnumFound = {};

        array.forEach(element => {            
            if(element.enumName === value) {
                perfilMenuEnumFound = element;      
                return ;          
            }            
        });   
        
        return perfilMenuEnumFound;
    }
}

export default PerfilMenuEnum;