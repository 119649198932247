import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import ModalNotification from './ModalNotification';
import NotificacaoEmpty from './NotificacaoEmpty';

import AtivoEnum from '../../helper/enumerador/AtivoEnum';

import { formatarData } from '../../helper/Formatar';

class Lista extends AbstractComponent {  
    constructor(props) {
        super(props);

        this.state = {
            item: {
                title: ''
            },

            index: -1
        }
    }    

    showComponent() {        
        if(this.props.body.length === 0) {
            return (
                <NotificacaoEmpty />
            );
        }

        return (
            <Fragment>
                {
                    this.props.body.map((item, i) => {
                        return (
                            <div 
                                style={{
                                    height: '58px',
                                    padding: '10px 0px',
                                    borderBottom: '1px solid #E7E6E7'
                                }}

                                key={i}
                            >
                                <a onClick={() => this.setState({item: item, index: i})}>
                                    <div 
                                        className={"col-md-1 col-sm-1 col-lg-1 col-xs-1 text-center " + (item.visualizado === AtivoEnum.NAO ? '' : 'display-none')}
                                        style={{
                                            padding: '9px',                                        
                                            height: '100%',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            display: 'flex'
                                        }}
                                    >                                             
                                        <i className="fas fa-circle" style={{color: 'green'}}/>                                        
                                    </div>

                                    <div 
                                        className={"col-md-1 col-sm-1 col-lg-1 col-xs-1 text-center " + (item.visualizado === AtivoEnum.NAO ? 'display-none' : '')}
                                        style={{
                                            padding: '9px',                                        
                                            height: '100%',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            display: 'flex'
                                        }}
                                    >                                             
                                        
                                    </div>

                                    <div 
                                        className="col-md-10
                                                col-sm-10
                                                col-lg-10
                                                col-xs-10"
                                        style={{
                                            padding: '0px'                                        
                                        }}
                                    >     
                                        <div 
                                            className="col-md-12
                                                    col-sm-12
                                                    col-lg-12
                                                    col-xs-12"
                                            style={{
                                                paddingLeft: '10px'
                                            }}

                                        >
                                            <strong>
                                                {item.title}
                                            </strong>
                                        </div>

                                        <div 
                                            className="col-md-12
                                                    col-sm-12
                                                    col-lg-12
                                                    col-xs-12"
                                            style={{
                                                fontSize: '10px',
                                                paddingLeft: '10px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            {formatarData(item.data)}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })
                }
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.index > -1 &&
                    <ModalNotification
                        show={true}
                        item={this.state.item}                        

                        afterLoad={() => this.props.afterLoad(this.state.index)}
                        onErro={(erro) => this.props.onErro(erro)}
                        fechar={() => this.setState({index: -1})}
                    />
                }                

                {this.showComponent()}                
            </Fragment>
        )
    }
}

export default Lista;