import AutenticarService from "../../service/AutenticarService";
import CarrinhoService from "../../service/CarrinhoService";
import AbstractComponent from "../AbstractComponent";
import ModalAlerta from "../layout/modal/ModalAlerta";
import ModalDetail from "../layout/modal/ModalDetail";
import Preload from "../layout/preload/Preload";
import EnderecoLista from "./EnderecoLista";

class ModalEnderecoLista extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: false,
            modalErro: ''
        }
    }

    onUpdateEnderecoEntrega(endereco, clienteEndereco) {
        if (endereco === undefined) {
          return 
        }
        
        let form = {};

        form.idCarrinho = AutenticarService.getIdCarrinho();
        form.destinatario = endereco.destinatario;
        form.cep = endereco.endereco.cep;
        form.logradouro = endereco.endereco.logradouro;
        form.endereco = endereco.endereco.endereco;
        form.numero = endereco.endereco.numero;
        form.complemento = endereco.endereco.complemento;
        form.bairro = endereco.endereco.bairro;
        form.cidade = endereco.endereco.cidade;
        form.uf = endereco.endereco.uf;

        form.tipo_endereco = endereco.tipo_endereco;
        form.codigo_municipio = endereco.endereco.codigo_municipio;
        form.ponto_de_interesse = endereco.ponto_de_interesse;
        form.ponto_de_interesse_manual = endereco.ponto_de_interesse_manual;
        form.ponto_de_interesse_descricao = endereco.ponto_de_interesse_descricao;

        form.entregaId = this.props.carrinho.entrega.id; 
        form.entregaDescricao = this.props.carrinho.entrega.descricao;
        form.entregaPerguntaFormaPagamento = this.props.carrinho.entrega.pergunta_forma_pagamento;
        form.entregaTemTaxaEntrega = this.props.carrinho.entrega.tem_taxa_entrega;

        this.setState({ preload: true })
        CarrinhoService.gravarOpcaoEntrega(form)
          .then((response) => {
            this.setState({ preload: false })          
            this.props.onUpdateEnderecoEntrega(endereco, clienteEndereco)
            this.props.onUpdateCart(response.data);
          })
          .catch((erro) => {
            this.setState({ preload: false, modalErro: erro })            
          });      
    }

    render() {
        return (
            <>
                <Preload exibir={this.state.preload} />

                <ModalAlerta
                  show={this.state.modalErro !== ""}
                  texto={this.state.modalErro}
                  fechar={() => this.setState({ modalErro: '' })}
                />
                
                <ModalDetail
                    show={this.props.show}
                    header={this.props.title}
                    icon="down"
                    fechar={() => this.props.fechar()}
                >                 
                    <EnderecoLista                    
                        cidadesDisponiveis = {this.props.cidadesDisponiveis}
                        empresa={this.props.empresa}
                        escolhaEntrega={true}                    

                        onTitulo={(titulo) => this.onTitulo(titulo)} 
                        onUpdateEnderecoEntrega={(endereco, clienteEndereco) => this.onUpdateEnderecoEntrega(endereco, clienteEndereco)}
                        
                        route={(item) =>
                            this.browserRoute.history.push(item.link)
                        }
                    />
                </ModalDetail>
            </>
        );
    }
}

export default ModalEnderecoLista;
