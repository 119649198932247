import React from "react";
import AbstractComponent from "../AbstractComponent";
import Form from "../layout/form/Form";
import Button from "../layout/button/Button";
import DadosPessoais from "../cliente/content/DadosPessoaisContent";
import PerfilMenu from "./PerfilMenu";
import Config from "../../Config";

import AutenticarService from "../../service/AutenticarService";
import ClienteService from "../../service/ClienteService";

import TipoRedeSocialEnum from "../../helper/enumerador/TipoRedeSocialEnum";

import { formatarData } from "../../helper/Formatar";
import Swal from "sweetalert2";
import UsuarioService from "../../service/UsuarioService";
// import withReactContent from 'sweetalert2-react-content';
class PerfilContent extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      preload: false,

      onLoad: true,

      form: {
        id: 0,
        cpf: "",
        nome: "",
        telefone: "",
        dataNascimento: "",
        foto_perfil: "",
        id_facebook: "",
        foto_facebook: "",
        id_google: "",
        foto_google: "",
        id_apple: "",
        foto_apple: "",
        foto: "",
      },

      erroForm: {
        id: 0,
        cpf: "",
        nome: "",
        telefone: "",
        dataNascimento: "",
      },

      btnSalvarDisabled: true,
    };
  }


  excluirConta(idUsuario) {
    Swal.fire({
      title: "Tem certeza que deseja excluir sua conta?",
      text: "Todos os seus dados serão deletados permanentemente!",
      showCancelButton: true,
      icon: "error",
      confirmButtonColor: "#000000",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Deletar!",
      cancelButtonText: "Cancelar",
      width: "80%",
      customClass: "swal-wide",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        UsuarioService.deletarUsuario(idUsuario)
          .then((response) => {
            Swal.fire({
              title: "Conta deletada com sucesso!",
              icon: "success",
              timer: 2500,
              showConfirmButton: false,
              width: "80%",
              customClass: "swal-wide",
            });
            this.logout();
          })
          .catch(() => {
            Swal.fire({
              title: "Ocorreu um erro durante a deleção da conta!",
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
              width: "80%",
              customClass: "swal-wide",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        window.location.href = Config.urlBase;
      }
    });
  }
  logout() {
    UsuarioService.logout()
      .then(() => {
        AutenticarService.logout();

        this.setState({ preload: false });

        this.homePage2();
      })
      .catch(() => {
        AutenticarService.logout();

        this.setState({ preload: false });

        this.homePage2();
      });
  }

  componentDidMount() {

    ClienteService.getByCpf(AutenticarService.getDocumentoCliente())
      .then((response) => {
        let form = this.state.form;

        form.id = response.data.id;
        form.cpf = response.data.cpf;
        form.nome = response.data.nome;
        form.dataNascimento = formatarData(response.data.data_nascimento);
        form.telefone = response.data.telefone;
        form.foto_perfil = response.data.foto_perfil;
        form.id_facebook = response.data.id_facebook;
        form.foto_facebook = response.data.foto_facebook;
        form.id_google = response.data.id_google;
        form.foto_google = response.data.foto_google;
        form.id_apple = response.data.id_apple;
        form.foto_apple = response.data.foto_apple;
        this.excluirConta(this.state.form.id);
      })
      .catch((erro) => {
        this.setState({ preload: false, onLoad: false });
      });
  }

  render() {
    return (
      <>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "25%",
              width: "50%",
              textAlign: "center",
            }}
          >
            <img
              src={Config.urlImgBase + "preload_botao.gif"}
              style={{ width: "35px", height: "35px", marginTop: "-8px" }}
              alt="Loading..."
            />{" "}
            <br />
          </div>
        </div>
      </>
    );
  }
}

export default PerfilContent;
