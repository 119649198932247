import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';
import AutenticarService from '../../../service/AutenticarService';

class CarrinhoEmpty extends AbstractComponent {   
    constructor(props) {
        super(props);          
        
        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    } 

    visible() {
        return this.props.visible === false 
            ? 'display-none'
            : ''
    }

    render() {
        return(            
            <div className={this.visible()} style={{padding: '80px 0'}}>
                <div className="area-cart__empty">
                    <div className="animated-svg">
                        <span className="area-card-empty-icon">
                            <i className={"area-card-empty-font " + this.shoppingBagType.icon()} />
                        </span>
                    </div>
                    
                    <span className="area-cart__empty--large">
                        {this.shoppingBagType.shoppingBagEmpty()}
                    </span>
                    
                    <span className="area-cart__empty--small">
                        Adicione itens
                    </span>
                </div>
            </div>
        )
    }
}

export default CarrinhoEmpty;