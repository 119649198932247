import React from 'react';
import {Col} from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import AbstractComponent from '../../AbstractComponent';

class InputMoeda extends AbstractComponent {        
    erro() {
        if(Array.isArray(this.props.erro)) {
            return(
                <ul>
                    {this.props.erro.map( (item , i) => {
                        return (
                            <li key={i}>{item}</li>
                        )
                    })}
                </ul>
            )
        }

        return this.props.erro;
    }    
    
    classErro() {
        return this.props.erro !== undefined && this.props.erro !== '' ? 'form-controll-erro' : '';
    }                
    
    render() {
        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>                
                <div className={this.classErro()} >   
                    <div className="row">
                        <div 
                            className="col-md-2 
                                       col-sm-2
                                       col-lg-2
                                       col-xs-2"
                            style={{
                                fontSize: '22px',
                                lineHeight: '34px',
                                fontWeight: 700,
                                color: '#a6a5a5'
                            }}
                        >
                            {this.props.symbol}
                        </div>
                        
                        <div className="col-md-9 
                                        col-sm-9
                                        col-lg-9
                                        col-xs-9">
                            <CurrencyInput
                                className="form-control"
                                style={{
                                    fontSize: '22px',
                                    lineHeight: '25px',
                                    fontWeight: 500                                  
                                }}
                                id={this.props.id}
                                name={this.props.name}
                                value={this.props.value}
                                decimalSeparator=","
                                thousandSeparator=""
                                onChangeEvent={(e, maskedValue, floatValue) => this.props.onChangeEvent(e, maskedValue, floatValue)}
                            />  
                        </div>
                    </div>                    

                    <span className="help-block">
                        {this.erro()}
                    </span>
                </div>
            </Col>
        )
    }
}

InputMoeda.defaultProps = {
    md: 12,
    className: ''
};

export default InputMoeda;