import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import {Modal} from 'react-bootstrap';
import ModalTitle from '../../layout/modal/ModalTitle';
import Notificacao from './NotificacaoContent';
import PreloadContent from '../../layout/preload/PreloadContent';

import AutenticarService from '../../../service/AutenticarService';

class ConfiguracaoNotificacaoModal extends AbstractComponent {   
    constructor(props) {
        super(props);

        this.state = {
            preload: false,

            erro: ''
        }
    } 
    
    fechar() {
        AutenticarService.setNotificacaoOk(1);

        if(this.props.fechar !== undefined) {
            this.props.fechar();
        }
    }

    styleText() {
        return (this.props.styleText === undefined) ? "text-dark" : this.props.styleText;        
    }
    
    render() {
        return (
            <Modal show={this.props.show} 
                   className={this.props.className}
                   style={{zIndex: 9999}}
                   onHide={() => this.fechar()}>
                <ModalTitle
                    icon="down"
                    header="Notificação"
                    fechar={() => this.fechar()}
                />
                
                <Modal.Body 
                    className={this.styleText()} 
                    style={{
                        marginBottom: '15px'
                    }}
                >
                    <PreloadContent
                        exibir={this.state.preload}
                    />
            
                    <Notificacao
                        preload={this.state.preload}
                        
                        showPreload={(preload) => this.setState({preload: preload})}
                        onErro={(erro) => this.setState({erro: erro})}
                        onCloseModal={() => this.fechar()}
                    />                    
                </Modal.Body>
            </Modal>            
        )
    }
}

export default ConfiguracaoNotificacaoModal;