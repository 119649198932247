import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from "../../layout/input/Input";
import InputData from '../../layout/input/InputData';
import InputCelPhone from '../../layout/input/InputCelPhone';

class DadosPessoaisContent extends AbstractComponent {            
    render() {
        return (
            <div>

                <div className="row">
                    <Input
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        id="nome"
                        inputRef={e => this.nome = e}
                        nome="nome"
                        value={this.props.form.nome}
                        defaultValue={this.props.form.nome}
                        defaultFoco={true}
                        erro={this.props.erroForm.nome}
                        onChange={e => this.props.onChange(e)}>
                        Nome
                        </Input>
                </div>

                <div className="row">
                    <Input
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        nome="cpf"
                        value={this.props.form.cpf}
                        defaultValue={this.props.form.cpf}
                        erro={this.props.erroForm.cpf}
                        disabled={true}>
                        CPF
                        </Input>
                </div>

                <div className="row">
                    <InputData
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        nome="dataNascimento"
                        value={this.props.form.dataNascimento}
                        defaultValue={this.props.form.dataNascimento}
                        erro={this.props.erroForm.dataNascimento}
                        onChange={e => this.props.onChange(e)}
                        onErro={e => this.props.onErroData(e)}>
                        Data Nascimento
                        </InputData>
                </div>

                <div className="row">
                    <InputCelPhone
                        md={12}
                        xs={12}
                        sm={12}
                        lg={12}
                        id="telefone"
                        nome="telefone"
                        value={this.props.form.telefone}
                        defaultValue={this.props.form.telefone}
                        erro={this.props.erroForm.telefone}
                        onChange={e => this.props.onChange(e)}>
                        Telefone
                        </InputCelPhone>
                </div>
            </div>
        )
    }
}

export default DadosPessoaisContent;