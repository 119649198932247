import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Input from './Input';
import './InputPassword.css';

class InputPassword extends AbstractComponent {    
    constructor(props) {
        super(props);        

        this.state = {            
            typeInput: 'password'
        }
    }    

    onChange(input) {        
        if (this.props.onChange !== undefined) {
          this.props.onChange(input);
        }
    }

    onKeyPress(input) {        
        if (this.props.onKeyPress !== undefined) {
          this.props.onKeyPress(input);
        }
    }     

    onClickIcon(e) {   
        let icone = this.state.typeInput;

        if(icone === 'password') 
            icone = 'text';                
        else
            icone = 'password';

        this.setState({typeInput: icone});       
    }

    render() {
        return ( 
            <Fragment>
                <Input
                    md={this.props.md}
                    xs={this.props.xs} 
                    sm={this.props.sm}
                    lg={this.props.lg}
                    inputRef={this.props.inputRef}
                    classNameInput={this.props.classNameInput}
                    maxlength={this.props.maxlength}
                    type={this.state.typeInput}
                    id={this.props.id}
                    nome={this.props.nome}                        
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    defaultFoco={this.props.defaultFoco}
                    erro={this.props.erro}
                    disabled={this.props.disabled}

                    onChange={e => this.onChange(e)}
                    onKeyPress={e => this.onKeyPress(e)}
                >
                    {this.props.children}

                    <div className="right-inner-addon">
                        <i className="glyphicon glyphicon-eye-open background-transparent" onClick={() => this.onClickIcon()} />
                    </div>                    
                </Input>                    
            </Fragment>                         
        )
    }
}

export default InputPassword;