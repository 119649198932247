import AutenticarService from "../service/AutenticarService";
import PedidoService from "../service/PedidoService";

export function validarPagamento(carrinho, pagamento, validaOpcaoFrete) {
    let msgErro = [];
    let erro = false;    

    if (
        carrinho.entrega.valor_pedido_minimo >
        carrinho.total
    ) {
        erro = true;
        msgErro.push(
            "O valor do seu pedido deve ser maior que " +
                moeda(carrinho.entrega.valor_pedido_minimo) +
            "."
        );
    }

    if (parseInt(carrinho.entrega.id, 10) === 0) {
        erro = true;
        msgErro.push("Informe uma opção de entrega");
    }
 
    if (validaOpcaoFrete && carrinho.entrega.id === 2 && carrinho.entrega.frete.servico === "") {
        erro = true;
        msgErro.push("Informe uma opção de frete");
    }

    if (
        carrinho.entrega.pergunta_forma_pagamento === "Sim" &&
        pagamento.id === 0
    ) {
        erro = true;
        msgErro.push("Informe a forma de pagamento");
    }

    if (erro) {
        msgErro.splice(0, 0, "Por favor, corrija os problemas abaixo: ");        
    }

    return msgErro;
}

export const gerarPedido = async (cliente, pagamento, carrinho, cartao) => {
    let form = {};
    
    form.idCarrinho = AutenticarService.getIdCarrinho();
    form.documentoCliente = AutenticarService.getDocumentoCliente();
    form.destinatario = cliente.destinatario;
    form.tipo_endereco = cliente.tipo_endereco;
    form.cep = cliente.cep;
    form.logradouro = cliente.logradouro;
    form.endereco = cliente.endereco;
    form.numero = cliente.numero;
    form.complemento = cliente.complemento;
    form.bairro = cliente.bairro;
    form.cidade = cliente.cidade;
    form.uf = cliente.uf;
    form.codigo_municipio = cliente.codigo_municipio;
    form.ponto_de_interesse = cliente.ponto_de_interesse;
    form.idPagamento = pagamento.id;
    form.totalCarrinho = carrinho.totalizador.total;
    form.valorTroco = pagamento.valorTroco;
    form.cartao = cartao;

    return PedidoService.gerar(form)
}
