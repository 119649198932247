import React from 'react';
import AbstractComponent from '../../../AbstractComponent';
import NavContentDesktop from './NavContentDesktop';
import NavContentMobile from '../bottom/NavContentMobile';

class NavHeader extends AbstractComponent {      
    render() {
        return(
            <header className="responsive-header responsive-header--static">
                <NavContentDesktop                    
                    inPreload={this.props.inPreload}
                    titulo={this.props.titulo}
                    
                    cliente={this.props.cliente}
                    carrinho={this.props.carrinho}
                    vitrine={this.props.vitrine}
                    badge={this.props.badge}
                    filtros={this.props.filtros}
                    preloadCliente={this.props.preloadCliente}
                    
                    route={(item) => this.props.route(item)}
                    search={() => this.props.search()}
                    onApplyFiltro={(filtros) => this.props.onApplyFiltro(filtros)}
                />

                <NavContentMobile                    
                    inPreload={this.props.inPreload}
                    titulo={this.props.titulo}
                    escondeLupa={this.props.escondeLupa}
                    
                    cliente={this.props.cliente}

                    route={(item) => this.props.route(item)}
                    search={() => this.props.search()}
                />
            </header>
        )
    }
}

export default NavHeader;
