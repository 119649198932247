import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import Config from '../../Config';

class ButtonFrete extends AbstractComponent {
    showPreload() {
        if(!this.props.preload)
            return ;

        return (                            
            <div className="col-md-12                                 
                            col-sm-12                                                
                            col-lg-12                                                
                            col-xs-12
                            text-center">
                <img src={Config.urlImgBase + "/preload_botao.gif"} style={{width: '35px', height: '35px', marginTop: '-8px'}} alt="Loading..."/> <br/>
            </div>            
        );
    }    

    showButton() {
        if(this.props.preload)
            return ;

        return (              
            <Fragment>
                
                <div className="col-md-3                                 
                                col-sm-3                                                
                                col-lg-3                                                
                                col-xs-3">
                    <span>
                        {
                            this.props.imagem &&                        
                            <img 
                                id={this.props.id}                    
                                src={Config.urlImgBase + this.props.imagem}                                
                            />
                        }   
                    
                        { this.props.texto }
                    </span>
                </div>

                <div className="col-md-5                                 
                                col-sm-5                                                
                                col-lg-5                                                
                                col-xs-5
                                text-center">
                    <span>
                        <strong>{this.props.valor}</strong>
                    </span>
                </div>

                <div className="col-md-4                                 
                                col-sm-4                                                
                                col-lg-4                                                
                                col-xs-4
                                text-right">
                    <span className={"opcao-entrega-info__action " + this.props.classNameEscolher}>
                        {this.props.textoSelecionar}
                    </span>
                </div>
            </Fragment>
        );
    }

    render() {
        return (            
            <button 
                type={this.props.type}
                className={"button-escolher " + this.props.className}
                onClick={() => this.props.onClick()}                     
            >              
                { this.showButton() }                

                { this.showPreload() }
            </button>            
        );
    }
}

ButtonFrete.defaultProps = {
    type: "button",
    className: '',
    showButton: true,
    showPreload: false,
    classNameEscolher: ''
}

export default ButtonFrete;
