import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Preload from '../preload/Preload';
import axios from 'axios';
import Input from './Input';
import {formataLogradouro, preparaEndereco, soNum} from '../../../helper/Formatar';

class InputCep extends AbstractComponent {    
    constructor(props) {
        super(props);

        this.state = {
            preload: false
        }
    }          

    onChange(input) {         
        if (this.props.onChange !== undefined) {
          this.props.onChange(input);
        }
    }  

    onBlur(input) {        
        if (this.props.onBlur === undefined) {
            return ;
        }

        const cep = input.target.value;

        if (this.props.valueOld === cep) {
            return ;
        }
                    
        if((cep === '')||(cep.length < 8)) {
            return ;
        }
                        
        this.setState({preload: true});

        axios.get('https:///viacep.com.br/ws/' + cep + '/json/').then(response => {                                                                
            let endereco = [];                                                

            endereco['cep'] = cep;                
            endereco['logradouro'] = formataLogradouro(this.props.logradouros, response.data.logradouro); 
            endereco['endereco'] = preparaEndereco(endereco['logradouro'], response.data.logradouro); 
            endereco['bairro'] = response.data.bairro;
            endereco['cidade'] = response.data.localidade;
            endereco['uf'] = response.data.uf;  
            endereco['codigo_municipio'] = response.data.ibge;                                

            this.props.onBlur(endereco);

            this.setState({preload: false});  
            
            if(this.props.proxFoco !== undefined)
                this.props.proxFoco();                    
        }).catch(() => {
            this.setState({preload: false});                

            if(this.props.onErro !== undefined)
                this.props.onErro("Erro ao buscar cep ou cep não encontrado. Certifique que o cep digitado é válido!");
        }) ;                  
    }       

    onSelect(input) {
        input.target.value = soNum(input.target.value);        
    }

    render() {
        return (
            <Fragment>
                <Preload exibir={this.state.preload} />

                <Input
                    md={this.props.md}
                    xs={this.props.xs} 
                    sm={this.props.sm}
                    lg={this.props.lg}

                    inputRef={this.props.inputRef}
                    classNameInput={this.props.classNameInput}
                    maxlength={this.props.maxlength}
                    type="tel"
                    id={this.props.id}
                    nome={this.props.nome}                    
                    value={this.props.value}
                    defaultValue={this.props.defaultValue}
                    defaultFoco={this.props.defaultFoco}
                    erro={this.props.erro}
                    disabled={this.props.disabled}
                    
                    onChange={e => this.onChange(e)}
                    onBlur={e => this.onBlur(e)}
                    onSelect={e => this.onSelect(e)}
                >
                    {this.props.children}
                </Input>                  
            </Fragment>
        )
    }
}

InputCep.defaultProps = {
    valueOld: ''
}

export default InputCep;