import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import {Col} from 'react-bootstrap';
import InputAutoComplete from './InputAutoComplete';

class InputSearch extends AbstractComponent {            
    style() {
        if(!this.props.arrowLeft)
            return ;

        return {marginRight: '26px'}
    }

    render() {
        return(     
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}  
                 className="responsive-header__search"
                 style={this.props.colStyle}
            >                
                <div className="search-input__field-wrapper" style={this.props.style}>                                                
                    <InputAutoComplete                        
                        className={this.props.className} 
                        name={this.props.name} 
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        items={this.props.items} 
                        style={this.style()}                       

                        autoComplete={(e) => this.props.autoComplete(e)}
                        onChange={(e) => this.props.onChange(e)}
                        onClearItems={() => this.props.onClearItems()}
                        onItemClick={(value) => this.props.onItemClick(value)}
                        search={() => this.props.search()}
                    />

                    {
                        this.props.arrowLeft &&
                        <div onClick={() => this.props.onCloseButtonClick()}>
                            <span className="icon-search icon-search--search search-input__icon-search" onClick={() => this.props.onCloseButtonClick()}>
                                <i className="icon-search-font pe-7s-angle-left" onClick={() => this.props.onCloseButtonClick()}/>
                            </span>    
                        </div>
                    }
                </div>
            </Col>                             
        )
    }
}

InputSearch.defaultProps = {
    md: 12,
    xs: 12,
    sm: 12,
    lg: 12,
    arrowLeft: false,
    colStyle: {}
}

export default InputSearch;