import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import HamburgerMenu from 'react-hamburger-menu';
import AbstractComponent from '../../AbstractComponent';

class Hamburger extends AbstractComponent {   
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    onClick(item, i) {
        this.setState({open: false});
        this.props.onClick(item, i);
    }

    showMenu() {
        if(!this.state.open) 
            return ;
                        
        return (
            <Fragment>                
                <div 
                    id="menu-hamburguer-dados-pessoais"
                    className="menu-hamburguer-dados-pessoais visibled-menu-hamburguer-dados-pessoais"
                    style={{right: '0'}}
                >                    
                    <ul className="list-group">
                        {
                            this.props.menu.map((item, i) => {
                                return (
                                    <li 
                                        key={i}
                                        className={"list-group-item ul-menu-dados-pessoais " + (!item.visible ? 'display-none' : '')}
                                        style={{width: '170px', textAlign: 'left'}}
                                        onClick={() => this.onClick(item, i)} 
                                    >
                                        { item.descricao }
                                    </li>
                                )
                            })
                        }                                                
                    </ul>                    
                </div>
            </Fragment>
        );
    }

    render() {
        return (
            <Col 
                className={"col-md-" + this.props.md + " col-xs-" + this.props.xs + " col-sm-" + this.props.sm + " col-lg-" + this.props.lg + " text-center"}
                style={{
                    height: '100px',
                    paddingTop: '10px',
                    cursor: 'pointer'
                }}
            >                

                <HamburgerMenu
                    isOpen={this.state.open}                    
                    width={this.props.width}
                    height={this.props.height}                    
                    strokeWidth={1}
                    rotate={0}
                    color='black'
                    borderRadius={0}
                    animationDuration={0.5}

                    menuClicked={() => this.setState({open: !this.state.open})}
                />

                { this.showMenu() }
            </Col>
        )
    }
}

Hamburger.defaultProps = {
    width: 8,
    height: 15,
    strokeWidth: 1
}

export default Hamburger;