import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent'; 

import './FiltrosSelecionados.css';

class FiltrosSelecionados extends AbstractComponent {
    render() {
        return (
            <Col
                md={this.props.md} 
                xs={this.props.xs} 
                sm={this.props.sm} 
                lg={this.props.lg}                
                style={{
                    height: '82px',
                    border: '1px solid #f6f5f5',
                    backgroundColor: '#eee',
                    marginBottom: '10px',
                    padding: '0px 5px 15px 5px',
                    overflowY: 'auto',                      
                    zIndex: 1023,
                    width: '94vw'             
                }}
            >     
                <div 
                    className="col-md-12
                               col-sm-12
                               col-lg-12
                               col-xs-12"
                    style={{padding: '3px'}}
                >
                    <div 
                        className="col-md-7
                                   col-sm-7
                                   col-lg-7
                                   col-xs-7"
                        style={{padding: '0px'}}
                    >
                        <label htmlFor={this.props.nome} style={{marginBottom: '10px'}}>
                            Filtros selecionados 

                            { this.props.filtro.length > 0 ? ' (' + this.props.filtro.length + ')' : '' }
                        </label>
                    </div>

                    <div 
                        className="col-md-5
                                   col-sm-5
                                   col-lg-5
                                   col-xs-5
                                   text-right"
                        style={{padding: '0px'}}
                    >
                        <a onClick={() => this.props.clearFiltro()}>
                            Limpar filtros
                        </a>
                    </div>
                </div>
                
                <div 
                    className="col-md-12
                               col-sm-12
                               col-lg-12
                               col-xs-12"
                    style={{padding: '0px'}}
                >
                    <ul className="select2-selection__rendered">
                        {
                            this.props.filtro.map((item, i) => {
                                return (
                                    <li 
                                        key={i}
                                        className="select2-selection__choice"
                                        onClick={() => this.props.onRemove(item)}
                                    >                                        
                                        <a onClick={() => this.props.onRemove(item)}>
                                            <span className="select2-selection__choice__remove" role="presentation" onClick={() => this.props.onRemove(item)}>
                                                ×
                                            </span>

                                            &nbsp;

                                            {item}
                                        </a>
                                    </li>
                                )
                            })
                        } 
                    </ul>
                </div>               
            </Col>
        );
    }
}

FiltrosSelecionados.defaultProps = {
    md: 12,  
    xs: 12, 
    sm: 12, 
    lg: 12
};

export default FiltrosSelecionados;