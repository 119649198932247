import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Button from '../button/Button';
import ModalAlerta from '../modal/ModalAlerta';
import ModalSucesso from '../modal/ModalSucesso';

import AutenticarService from '../../../service/AutenticarService';
import ClienteProdutoAviseMeService from '../../../service/ClienteProdutoAviseMeService';

class ButtonTellMe extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            erro: '',

            sucesso: '',

            preload: false
        }
    }

    onClickAviseMe() {
        if (!this.autenticar())
            return false;

        this.setState({ preload: true });

        ClienteProdutoAviseMeService.avisar(            
            AutenticarService.getDocumentoCliente(),
            this.props.item.uuid,
            'Chegou!!',
            'O produto [' + this.props.item.descricao + '] acaba de chegar. Aproveite!'
        ).then(() => {
            this.setState({
                sucesso: "Item adicionado com sucesso na lista de avisos!",
                preload: false
            });
        }).catch(erro => {
            this.setState({
                erro: this.trataErro(erro),
                preload: false
            });
        });
    }

    showComponent() {
        if (!this.props.show)
            return;

        return (
            <Button
                type="button"
                className="button-down-vitrine product-action__counter__button text-center"
                onClick={() => this.onClickAviseMe()}
                display={'block'}
                preload={this.state.preload}
            >
                <i className="fa fa-exclamation-triangle" />
                &nbsp; Avise-me quanto chegar
            </Button>
        )
    }

    render() {
        return (
            <Fragment>
                <ModalAlerta
                    show={this.state.erro !== ''}
                    texto={this.state.erro}
                    fechar={() => this.setState({ erro: '' })}
                />

                <ModalSucesso
                    show={this.state.sucesso !== ''}
                    texto={this.state.sucesso}
                    fechar={() => this.setState({ sucesso: '' })}
                />

                {this.showComponent()}
            </Fragment>
        );
    }
}

export default ButtonTellMe;