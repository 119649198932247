import React, { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AbstractComponent from '../../AbstractComponent';
import VitrineEmpty from './VitrineEmpty';
import Button from '../../layout/button/Button';
import ButtonsProduto from '../ButtonsProdutos';
import config from '../../../Config';

import AutenticarService from '../../../service/AutenticarService';

import VitrineEnum from '../../../helper/enumerador/VitrineEnum';
import AtivoEnum from '../../../helper/enumerador/AtivoEnum';

import ShoppingBagEnum from '../../../helper/enumerador/ShoppingBagEnum';
import { changeQuantidade, calculaTotal } from '../../../helper/InputSpin';
import ButtonTellMe from '../../layout/button/ButtonTellMe';

class VitrineProdutoContent extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            erroQtd: '',

            alerta: ''
        }

        this.vitrineType = this.props.telaFavorito ? VitrineEnum.FAVORITO : VitrineEnum.GRADE;        
        this.shoppingBagType = ShoppingBagEnum.get(AutenticarService.getShoppingBag());
    }

    onChangeQuantidade(input, item) {
        let quantidade = changeQuantidade(input.target.value);

        this.setState({
            quantidade: quantidade
        });

        calculaTotal(this.getValor(item), quantidade);
    }

    onClickQuantidade(value, item) {
        const quantidade = parseInt(value, 10);

        if(quantidade === 0)
            return ;

        this.props.changeQuantidade(item, quantidade)
    }

    next() {
        if (this.props.fetchMoreData === undefined)
            return;

        this.props.fetchMoreData();
    }

    showVitrine() {
        if(this.props.inPreload)
            return ;

        if(this.props.vitrine.length === 0)
            return (
                <VitrineEmpty
                    searchKey={this.props.searchKey}                    
                />
            );

        return (
            <Fragment>
                <InfiniteScroll
                    dataLength={this.props.vitrine.length}
                    next={() => this.next()}
                    hasMore={true}
                    style={{overflow: 'none'}}
                >
                    {this.props.vitrine.map((item, i) => {                        
                        return (
                            <div className={"vitrine-container-" + this.vitrineType.enumName + " " + this.vitrineType.css()} key={i}>                                                                                                                                    
                                <a onClick={() => this.props.onClick(item, i)}>
                                    { this.vitrineType.content(item) }

                                    {
                                        (((item.carrinho_item_quantidade === undefined)&&((item.estoque > 0)||(item.controla_estoque === AtivoEnum.NAO)))||(((item.estoque <= 0)&&(item.controla_estoque === AtivoEnum.SIM))||(item.ativo === AtivoEnum.NAO))) &&
                                        <div style={{height: '50px'}} />
                                    }

                                    {
                                        ((item.carrinho_item_quantidade !== undefined)&&((item.estoque > 0)||(item.controla_estoque === AtivoEnum.NAO))) &&
                                        <Button
                                            type="button"
                                            className="product-action__counter__button text-center"
                                            onClick={() => this.props.onClick(item, i)}
                                            display={'block'}
                                        >
                                            Alterar
                                        </Button>
                                    }                                    
                                </a>

                                <ButtonTellMe
                                    item={item}
                                    show={(((item.estoque <= 0)||(item.ativo === AtivoEnum.NAO))&&(item.controla_estoque === AtivoEnum.SIM))}

                                    route={(item) => this.props.route(item)}
                                />

                                <ButtonsProduto
                                    show={(((item.estoque > 0)||(item.controla_estoque === AtivoEnum.NAO))&&(item.ativo === AtivoEnum.SIM))}
                                    uuid={item.uuid}
                                    favorito={item.favorito}                                    

                                    onUpdateBadgeFavorito={(tipo) => this.props.onUpdateBadgeFavorito(tipo, i)}

                                    route={(item) => this.props.route(item)}
                                />                                
                            </div>
                        )
                    })}
                </InfiniteScroll>

                {
                    this.props.vitrinePreload &&
                    <div className="col-md-12
                                    col-sm-12
                                    col-lg-12
                                    col-xs-12
                                    text-center" >
                        <img src={config.urlImgBase + "preload_botao.gif"} alt="Loading..." width='35px' height='35px'/>
                    </div>
                }
            </Fragment>
        );
    }

    render() {
        return (
            <div className="vitrine">
                { this.showVitrine() }
            </div>
        )
    }
}

VitrineProdutoContent.defaultProps = {
    vitrine: [],
    telaFavorito: false
}

export default VitrineProdutoContent;
