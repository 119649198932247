import React, { Fragment } from 'react';
import AbstractComponent from '../AbstractComponent';
import CredentialsContent from './content/CredentialsContent';

class Credentials extends AbstractComponent {
    showComponent() {
        if(!this.props.show)
            return ;
            
        return(
            <CredentialsContent
                form={this.props.form}
                
                onTitulo={(title) => this.props.onTitulo(title)} 
                onLoginOk={(userName, token, documentoCliente, tipoLogin) => this.props.onLoginOk(userName, token, documentoCliente, tipoLogin)} 
                onRegisterOk={() => this.props.onRegisterOk()}
                onError={(error) => this.props.onError(error)}
            />
        );
    }

    render() {
        return (
            <Fragment>
                { this.showComponent() }
            </Fragment>
        );
    }
}

export default Credentials;