import React from 'react';
import { Modal } from 'react-bootstrap';
import AbstractComponent from '../AbstractComponent';

class ModalCadastro extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            title: ''            
        };
    }        

    cadastro() {
        if (this.props.cadastro === true) {                        
            return this.props.component({
                cpf: this.props.cpf,                                                
                empresa: this.props.empresa,

                onLoginOk: (userName, token, documentoCliente, tipoLogin) => this.onLoginOk(userName, token, documentoCliente, tipoLogin),
                logar: (email, senha, documentoCliente, idRedeSocial, tipo_rede_social) => this.props.logar(email, senha, documentoCliente, idRedeSocial, tipo_rede_social),
                onTitulo: (title) => this.setState({ title: title })
            });
        }
    }

    onLoginOk(userName, token, documentoCliente, tipoLogin) {
        this.props.fechar();
        this.props.onLoginOk(userName, token, documentoCliente, tipoLogin);        
    }

    render() {
        return (
            <div>
                <Modal show={this.props.show}
                    onHide={() => this.props.fechar()}
                    style={{ zIndex: 9999 }}>
                    <Modal.Header
                        onHide={() => this.props.fechar()}
                        closeButton>
                        <Modal.Title>{this.state.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.cadastro()}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

export default ModalCadastro;