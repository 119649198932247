import Config from "../Config";
import Requisicao from "./RequisicaoService";

class NotificacaoService {
    static async index(cpf, uuidAparelho) {          
        return Requisicao.post(Config.urlBaseApi + 'notificacao', {            
            cpf: cpf,
            uuidAparelho: uuidAparelho
        });
    }
    
    static async setVisualizado(idNotificacao) {
        return Requisicao.patch(Config.urlBaseApi + 'notificacao', {            
            idNotificacao: idNotificacao
        });
    }
}

export default NotificacaoService;