import Config from "../Config";
import Requisicao from "./RequisicaoService";

class CorreiosService {
    static async cotacao(documento, idCarrinho, cepDestino) {
        return Requisicao.get(Config.urlBaseApi + `correios/cotacao/${documento}/${idCarrinho}/${cepDestino}`)
    }    
}

export default CorreiosService;
