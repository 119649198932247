import React from 'react';
import {Col} from 'react-bootstrap';
import AbstractComponent from '../../AbstractComponent';
import Image from './Image';

class ImageChoosen extends  AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preview: false,
            image: ''
        }
    }

    onLoad() {
        if(!this.props.canLoad)
            return ;

        this.props.onLoad();
    }

    upload() {
        if(!this.props.canLoad)
            return ;

        document.getElementById(this.props.imageName).click();
    }    

    carregarArquivo() {
        const reader = new FileReader();
        const arquivo = document.getElementById(this.props.imageName);

        reader.onload = function (e) {
            document.getElementById("preview").src = '';
            document.getElementById("preview").src = e.target.result;
        };

        reader.readAsDataURL(arquivo.files[0]);
        this.setState({ preview: true });        
    }    

    render () {
        return (
            <Col md={this.props.md} 
                 xs={this.props.xs} 
                 sm={this.props.sm} 
                 lg={this.props.lg}>              
                <div className="col-md-12" style={{ textAlign: "center", marginBottom: '20px' }}>
                    <input type="file" name={this.props.imageName} id={this.props.imageName} className="hide" onChange={e => this.carregarArquivo(e)} />

                    {
                        !this.state.preview &&
                        <a onClick={e => this.upload(e)} style={{cursor: (this.props.canLoad ? 'pointer' : 'unset')}}>
                            <Image
                                md={12}
                                xs={12}
                                sm={12}
                                lg={12}   
                                semImagem={this.props.semImagem}                     
                                imagem={this.props.imagem}
                                width={this.props.width}
                                heightImage={this.props.heightImage}
                                height={this.props.height}
                                borderRadius={this.props.borderRadius}    
                                semImagemBorderRadius={this.props.borderRadius}    
                                alt={this.props.alt}
                                src={this.props.imagem}
                            /> 
                        </a>
                    }

                    {
                        this.state.preview &&
                        <a onClick={e => this.upload(e)} style={{cursor: (this.props.canLoad ? 'pointer' : 'unset')}}>
                            <Image
                                md={12}
                                xs={12}
                                sm={12}
                                lg={12}   
                                id="preview"
                                className={"img-preview"}                                
                                width={this.props.width}
                                heightImage={this.props.heightImage}
                                height={this.props.height}
                                borderRadius={this.props.borderRadius}
                                semImagemBorderRadius={this.props.borderRadius} 
                                semImagem={this.props.semImagem} 
                                imagem={this.props.imagem}                                
                                alt={this.props.alt}
                                src={''}
                                onLoad={() => this.onLoad()}
                            />                             
                        </a>
                    }
                </div>
            </Col>
        )
    }
}

ImageChoosen.defaultProps = { 
    width: '6em',
    heightImage: '6em',  
    height: '6em',
    borderRadius: '',
    imageName: 'imagem'
}

export default ImageChoosen;