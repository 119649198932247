import React from 'react';
import AbstractComponent from '../AbstractComponent';

import PedidoContent from './PedidoContent';
import Content from '../layout/content/Content';

import './Pedidos.css';

class Pedido extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            preload: false,
        }
    }

    componentDidMount() {
        this.onTitulo("Pedidos");

        if(!this.isLogged())
            return this.login2();
    }

    render() {
        return (
            <div>
                <Content                    
                    preload={this.state.preload}
                    showSummary={false}
                    modalErro={this.state.erro}
                    onCloseAlerta={() => this.setState({ erro: '' })}

                    component={(props) => {
                        return <PedidoContent
                            {...props}
                            route={(item) => this.props.route(item)}
                            

                            showPreload={(value) => this.setState({ preload: value })}
                            showErro={(value) => this.setState({ erro: value })}
                        />
                    }}
                />
            </div>
        )
    }
}

export default Pedido;