import {useState} from 'react';
import ModalAlerta from '../layout/modal/ModalAlerta';
import Toast from '../layout/toast/Toast';
import { onMessageListener } from './firebase';

import { trataErro } from '../../helper/Messageria';

function PushNotification() {
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});    
    const [error, setError] = useState('');          

    onMessageListener().then(payload => {
        setShow(true);
        setNotification({title: payload.notification.title, body: payload.notification.body})        
    }).catch(error => {
        trataErro(error, setError);
    });

    return (    
        <> 
            <ModalAlerta
                show={error !== ''}
                texto={error}                
                
                fechar={() => setError('')}
            />
            
            <Toast 
                onClose={() => setShow(false)} 
                show={show} 
                timeOut={5}                                 
                notification={notification}                
            />            
        </>
    );
}

export default PushNotification;
