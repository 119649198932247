import React, { Fragment } from 'react';
import AbstractComponent from '../../AbstractComponent';
import Content from '../../layout/content/Content';
import TaxaEntregaContent  from './TaxaEntregaContent';

class TaxaEntrega extends AbstractComponent {
    constructor(props) {
        super(props);        

        this.state = {  
            preload: false, 
            
            erro: ''
        }        
    }                   

    componentDidMount() {             
        if(!this.autenticar())
            return this.homePage();
            
        this.onTitulo("Taxa de Entrega");                                                
    } 

    render() {
        return (
            <Fragment>
                <Content 
                    preload={this.state.preload}                
                    show={this.props.show}                
                    modalErro={this.state.erro}
                    showSummary={false}        
                    
                    onModalErroClick={() => this.setState({ erro: '' })}

                    component={(props) => { 
                        return (
                            <TaxaEntregaContent 
                                {...props}                                                                                                                                                              
                                
                                empresa={this.props.empresa}
                                
                                route={(item) => this.props.route(item)}                                  
                                showPreload={(value) => this.setState({preload: value})} 
                                showErro={(value) => this.setState({ erro: value })}                     
                            />
                        )
                    }}
                />
            </Fragment> 
        )
    }
}

export default TaxaEntrega;