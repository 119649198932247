import React, { Fragment } from 'react';
import AbstractComponent from '../../../../AbstractComponent';
import Config from '../../../../../Config';

class VitrineLista extends AbstractComponent {
    constructor(props) {
        super(props);

        this.state = {
            imagem: ''
        }

        if (this.props.item.galeria_imagem.length > 0)
            this.state.imagem = this.props.item.galeria_imagem[0].imagem64;
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.refreshImg === undefined) || (prevProps.refreshImg === 0) || (this.state.imagem === ''))
            return;

        this.state.imagem = this.props.item.galeria_imagem[0].imagem64;
    }

    getImage() {
        if ((this.state.imagem === undefined) || (this.state.imagem === null) || (this.state.imagem === ''))
            return (
                <Fragment>
                    <img
                        alt="120x120"
                        src={Config.urlImgBase + "produto-sem-imagem.jpg"}
                        style={{ width: '200px', height: 'auto' }} />
                </Fragment>
            );

        return (
            <Fragment>

                <img
                    alt="120x120"                    
                    src={Config.urlImgBase + this.props.item.galeria_imagem[0].imagem_path}
                    data-holder-rendered="true"
                    style={{ width: '200px', height: 'auto' }} />
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                <div
                    className="media-left media-middle"
                    style={{
                        padding: '5px'
                    }}
                >
                    {this.getImage()}
                </div>

                <div
                    className="media-body"
                    style={{
                        height: '143px'
                    }}>
                    <div className="media-heading-LISTA">
                        {this.props.item.descricao}
                    </div>

                    {this.props.showValor(this.props.item)}
                </div>
            </Fragment>
        )
    }
}

export default VitrineLista;