import React, { useEffect, useState } from "react";
function Imagem(props) {
  let item = props.item;
  return (
    <>
      {window.innerWidth < 770 ? (
        <img
          src={item.imagem_mobile_Path}
          alt={item.imagem_mobile_Path}
          className="caroselimage"
        />
      ) : (
        <div>
          <img
            src={item.imagem_desktop_Path}
            alt={item.imagem_desktop_Path}
            className="caroselimage"
          />
        </div>
      )}
    </>
  );
}
function CampanhaTile(props) {
  let item = props.item;

  return (
    <>

      {item.tipo_acao == 0 && <Imagem item={item} />}
      {item.tipo_acao == 1 && (
        <a href={item.acao_url} target="_blank" rel="noopener noreferrer">
          <Imagem item={item} />
        </a>
      )}
      {item.tipo_acao == 2 && (
          
        <a  onClick={() =>props.onPressAcessarProdsCampanha(item.uuid)} target="_blank" rel="noopener noreferrer">
          <Imagem item={item} />
        </a>
      )}
    </>
  );
}
export default CampanhaTile;
